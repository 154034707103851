import { convertCase } from '../../_utils/utils'
import clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'

export class NotificationDeliveryModel {
    public static build(props: NotificationDeliveryModel | any): NotificationDeliveryModel {
        const data = props instanceof NotificationDeliveryModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationDeliveryModel()
        model.setType(scData.type ?? model.getType())
        model.setWindow(scData.window ?? model.getWindow())
        model.setTimeZone(scData.timezone ?? scData.time_zone)
        model.setSendDate(scData.send_date_utc)

        return model
    }

    private type: NotificationDeliveryType = NotificationDeliveryType.IMMEDIATE
    private window: NotificationDeliveryWindow = NotificationDeliveryWindow.STANDARD
    private sendDate: string | undefined
    private timezone: string | undefined

    public clone(): NotificationDeliveryModel {
        return NotificationDeliveryModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            type: this.getType(),
            window: this.getWindow(),
            send_date_utc: this.getSendDate(),
            timezone: this.getTimeZone(),
        })
    }

    public getType(): NotificationDeliveryType | string {
        return this.type
    }
    public setType(type: NotificationDeliveryType | string): void {
        this.type = type as NotificationDeliveryType
    }

    public getWindow(): NotificationDeliveryWindow | string {
        return this.window
    }
    public setWindow(window: NotificationDeliveryWindow | string): void {
        this.window = window as NotificationDeliveryWindow
    }

    public getSendDate(): string | undefined {
        return this.sendDate
    }
    public setSendDate(sendDate: string | undefined): void {
        this.sendDate = sendDate
    }

    public getTimeZone(): string | undefined {
        return this.timezone
    }
    public setTimeZone(tz: string | undefined): void {
        this.timezone = tz
    }

    public get isUsingImmediateDelivery(): boolean {
        return this.type === NotificationDeliveryType.IMMEDIATE
    }
    public get isUsingScheduledDelivery(): boolean {
        return this.type === NotificationDeliveryType.SCHEDULED
    }
    public get isUsingFixedStrategy(): boolean {
        return this.window === NotificationDeliveryWindow.STANDARD
    }
    public get isUsingStzStrategy(): boolean {
        return this.window === NotificationDeliveryWindow.TIMEZONE
    }
    public get isUsingInformedStrategy(): boolean {
        return this.window === NotificationDeliveryWindow.INFORMED
    }
    public get isUsingHoldOutStrategy(): boolean {
        return this.window === NotificationDeliveryWindow.HOLD_OUT
    }
}
