export enum NodeType {
    UNSPECIFIED = 'node_type_unspecified',
    GLOBAL_TRIGGER = 'global_trigger',
    TRIGGER = 'trigger',
    DELAY = 'delay',
    ACTION = 'action',
    // @todo NS-1663 > - migrated types to just "EXIT"
    EXIT = 'campaign_exit',
}

export enum DelayType {
    UNSPECIFIED = 'delay_type_unspecified',
    RELATIVE = 'relative',
}

export enum ActionType {
    UNSPECIFIED = 'action_type_unspecified',
    SEND_NOTIFICATION = 'send_notification',
    SEND_CART_NOTIFICATION = 'send_cart_notification',
}

export enum GlobalTriggerType {
    UNSPECIFIED = 'global_trigger_type_unspecified',
    EVENT = 'event',
}

export enum TriggerType {
    UNSPECIFIED = 'trigger_type_unspecified',
    ABANDONED_CART = 'abandoned_cart',
    ABANDONED_BROWSE = 'abandoned_view',
    ADD_TO_CART = 'add_to_cart',
    NOTIFICATION_CLICK = 'notification_click',
    SUBSCRIBED = 'subscribed',
    RECURRING = 'recurring',
}

export enum ExitBehavior {
    UNSPECIFIED = 'exit_behavior_unspecified',
    BLEED = 'BLEED',
    STOP = 'STOP',
}

export enum ExitStatus {
    UNSPECIFIED = 'exit_status_unspecified',
    COMPLETED = 'completed',
    REMOVED = 'removed',
}

export enum JourneyBuilderMode {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    READONLY = 'READONLY',
}

export enum JourneyType {
    UNSPECIFIED = 'journey_type_unspecified',
    TRIGGER = 'trigger',
    RECURRING = 'recurring',
}
