import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { RelativeDelayPicker } from '../../../components/campaign-builder/editors/delay.editor'
import { DelayParams, DelayStep, JourneyNode, NodeEditorRef } from '../types/journey-nodes'

type DelayEditorProps = {
    node: JourneyNode<DelayStep>
}

export const DelayEditor = forwardRef<NodeEditorRef, DelayEditorProps>(({ node }, ref) => {
    const [params, setParams] = useState(node.data.data.configuration.params)

    const handleDelayChange = useCallback(
        (changes: DelayParams) => {
            setParams(changes)
        },
        [node],
    )

    useImperativeHandle(ref, () => ({
        onSubmit: (): JourneyNode<DelayStep> => {
            node.data.data = {
                ...node.data.data,
                configuration: {
                    ...node.data.data.configuration,
                    params,
                },
            }

            return node
        },
    }))

    return (
        <>
            <Well mode="ghost" title="Schedule" hideFooter={true}>
                <RelativeDelayPicker disabled={false} value={params} onChange={handleDelayChange} />
            </Well>
        </>
    )
})
