import React, { MouseEventHandler, useCallback } from 'react'
import { applyEdgeChanges, applyNodeChanges, useReactFlow } from '@xyflow/react'
import { getJourneyNodeIcon } from './helpers'
import { getRemoveNodeMutations } from '../../helpers'
import { CloseCircleFilled } from '@ant-design/icons'
import { getClassNames } from '../../../../_utils/classnames'
import { NodeType } from '../../enums'
import { useJourneyContext } from '../../context'
import { preventAll } from '../../../../_utils/utils'
import { JourneyNode, JourneyStep } from '../../types/journey-nodes'
import { JourneyAction } from '../../types/journey-context'

export const JourneyNodeIcon = ({ id, node }: { id: string; node: JourneyStep }) => {
    const [_state, dispatch] = useJourneyContext()
    const { getNode, getNodes, getEdges, setEdges, setNodes } = useReactFlow<JourneyNode>()
    const icon = getJourneyNodeIcon(node)

    const handleRemoveNode: MouseEventHandler<HTMLSpanElement> = useCallback(
        (ev) => {
            preventAll(ev)

            const nodeToRemove = getNode(id)!
            const [nodeChanges, edgeChanges] = getRemoveNodeMutations(nodeToRemove, getNodes(), getEdges())
            dispatch({ action: JourneyAction.ON_NODE_REMOVE, data: nodeToRemove })

            setEdges((eds) => applyEdgeChanges(edgeChanges, eds))
            setNodes((nodes) => applyNodeChanges(nodeChanges, nodes))
        },
        [id],
    )

    return (
        <div className="icon">
            {node.type !== NodeType.TRIGGER && node.type !== NodeType.EXIT && (
                <CloseCircleFilled className="journey-node-removal-button" onClick={handleRemoveNode} />
            )}
            <span
                className={getClassNames(null, 'journey-node-icon', {
                    'no-action': [NodeType.TRIGGER, NodeType.EXIT].includes(node.type),
                })}
            >
                {icon}
            </span>
        </div>
    )
}
