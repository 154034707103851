import { useReducer } from 'react'
import {
    JourneyAction,
    JourneyChangesPack,
    JourneyDispatchActionPack,
    JourneyNodeAddPack,
    JourneyNodeRemovePack,
    JourneyNodeUpdatePack,
    JourneyProps,
    JourneyReducerElements,
    JourneySetPack,
    JourneyState,
} from './types/journey-context'
import { JourneyStep } from './types/journey-nodes'

export const useJourneyReducer = (props: JourneyProps): JourneyReducerElements => {
    const [context, dispatch] = useReducer(handleJourneyActionRequest, getJourneyStateInitializer(props))
    return [context, dispatch]
}

function getJourneyStateInitializer(props: JourneyProps): JourneyState {
    return {
        ...props,
        nodes: props.nodes ?? [],
        edges: props.edges ?? [],
        callbacks: props.callbacks ?? {},
    }
}

function handleJourneyActionRequest(state: JourneyState, pack: JourneyDispatchActionPack): JourneyState {
    let currentState = { ...state }

    switch (pack.action) {
        case JourneyAction.SET_JOURNEY:
            return handleJourneySetRequest(currentState, pack)
        case JourneyAction.ON_NODE_ADD:
            return handleJourneyNodeAddRequest(currentState, pack)
        case JourneyAction.ON_NODE_UPDATE:
            return handleJourneyNodeUpdateRequest(currentState, pack)
        case JourneyAction.ON_NODE_REMOVE:
            return handleJourneyNodeRemoveRequest(currentState, pack)
        case JourneyAction.ON_JOURNEY_CHANGES:
            return handleJourneyChangeRequest(currentState, pack)
        default:
            return currentState
    }
}

function handleJourneySetRequest(state: JourneyState, pack: JourneySetPack): JourneyState {
    return {
        ...state,
        journey: pack.data,
    }
}

function handleJourneyNodeAddRequest(state: JourneyState, pack: JourneyNodeAddPack) {
    state.callbacks.onNodeAdd?.(pack.data)

    return state
}

function handleJourneyNodeUpdateRequest<T extends JourneyStep>(state: JourneyState, pack: JourneyNodeUpdatePack<T>) {
    const { node, updates } = pack.data

    state.callbacks.onNodeUpdate(node, updates)

    return state
}

function handleJourneyNodeRemoveRequest(state: JourneyState, pack: JourneyNodeRemovePack) {
    state.callbacks.handleNodeRemove(pack.data)

    return state
}

function handleJourneyChangeRequest(state: JourneyState, pack: JourneyChangesPack) {
    if (pack.data.nodes) {
        state.nodes = pack.data.nodes
    }

    if (pack.data.edges) {
        state.edges = pack.data.edges
    }

    return state
}
