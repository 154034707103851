import clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { generateShortID } from '../../components/campaign-builder/helpers/uid'
import { NotificationAudienceModel } from './notification-audience.model'
import { NotificationContentModel } from './notification-content.model'
import { NotificationDeliveryModel } from './notification-delivery.model'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'

export class NotificationVariantModel {
    public static build(props: NotificationVariantModel | any): NotificationVariantModel {
        const data = props instanceof NotificationVariantModel ? props.serialize() : clone(props)

        const model = new NotificationVariantModel()
        model.id = data.id ?? model.getId()
        model.setChannels(data.channels ?? model.channels)
        model.setAudience(data.audience)
        model.setContent(data.content)
        model.setDelivery(data.delivery)
        model.setWeight(data.weight ?? model.getWeight())
        model.setDisplayMeta(data.display_meta)

        return model
    }

    private id: string = generateShortID()
    // A default selection should be present, WEB is default implementation for Platform
    private channels: DeliveryChannel[]
    private audience: NotificationAudienceModel
    private content: NotificationContentModel
    private delivery: NotificationDeliveryModel
    private weight: number = 100
    private displayMeta: any = {}

    public clone(persistId: boolean = true, addingVariant: boolean = false): NotificationVariantModel {
        if (addingVariant && this.getDelivery().isUsingHoldOutStrategy) {
            this.getDelivery().setWindow(NotificationDeliveryWindow.STANDARD)
        }

        const model = NotificationVariantModel.build(this)
        if (!persistId) {
            model.id = generateShortID()
        }

        return model
    }

    public serialize(): any {
        return stripUndefined({
            id: this.getId(),
            channels: this.getChannels(),
            audience: this.getAudience().serialize(),
            content: this.getContent().serialize(),
            delivery: this.getDelivery().serialize(),
            weight: this.getWeight(),
            display_meta: this.getDisplayMeta(),
        })
    }

    public getId(): string {
        return this.id
    }

    public getChannels(): DeliveryChannel[] {
        return this.channels
    }
    public setChannels(channels: DeliveryChannel[]): void {
        this.channels = channels
    }

    public getAudience(): NotificationAudienceModel {
        return this.audience
    }
    public setAudience(audience: NotificationAudienceModel | undefined): void {
        this.audience = NotificationAudienceModel.build(audience ?? {})
    }

    public getContent(): NotificationContentModel {
        return this.content
    }
    public setContent(content: NotificationContentModel | undefined): void {
        this.content = NotificationContentModel.build(content ?? {})
    }

    public getDelivery(): NotificationDeliveryModel {
        return this.delivery
    }
    public setDelivery(delivery: NotificationDeliveryModel | undefined): void {
        this.delivery = NotificationDeliveryModel.build(delivery ?? {})
    }

    public getWeight(): number {
        return this.weight
    }
    public setWeight(weight: number | undefined): void {
        this.weight = weight ?? 0
    }

    public getDisplayMeta(): any {
        return this.displayMeta ?? {}
    }
    public setDisplayMeta(meta: any) {
        this.displayMeta = meta ?? {}
    }
}
