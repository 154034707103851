import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'

interface INotificationBuilderMain {
    className?: string
}

const NotificationBuilderMain: React.FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<INotificationBuilderMain>>
> = (props) => {
    const { className, children } = props

    return <div className={getClassNames('notification-builder-main', className)}>{children}</div>
}

export default NotificationBuilderMain
