import * as React from 'react'
import { Route as ReactRoute } from 'react-router-dom'
import { AppState } from '../../stores/app'
import { Switch, Route } from '../../components/router'
import { ResetPassword, ConfirmUser } from './'
import './pre-auth.scss'
import { GAHelper } from '../../components/ga-helper/ga-helper'
import { useService } from '@pushly/aqe/lib/hooks'
import { PlatformUserContext } from '../../providers/user-provider/user-provider'
import { Auth0Redirect } from '../../components/auth0-redirect/auth-redirect'

interface IProps {}

interface IState {}

export const PreAuth: React.FC<React.PropsWithChildren<IProps>> = () => {
    const appState = useService(AppState)
    const userContext = React.useContext(PlatformUserContext)

    return (
        <div className="module pre-auth">
            {!userContext.authenticated && <ReactRoute path="/" component={() => GAHelper(appState)} />}

            <Switch>
                {/*
                        Auth0 Flow uses Tenant/Application configured login route for
                        invitation, password resets and any other auth0 redirected flows. Then it is
                        expected to receive specific params during redirect to /authorize (on their side).
                    */}
                <ReactRoute path="/login" exact={true} component={Auth0Redirect} />
                <Route preAuth={true} path="/confirm(-invite)?" exact={true} component={ConfirmUser} />
                <Route preAuth={true} path="/reset-password" exact={true} component={ResetPassword} />
                <Route
                    preAuth={true}
                    path="/authorize"
                    /* authorize is a provider placeholder - no render */
                    component={() => null}
                />

                {!userContext.authenticated && <ReactRoute path="/" component={Auth0Redirect} />}
            </Switch>
        </div>
    )
}
