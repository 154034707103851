import { DomainDto } from '../../../dtos/domain'
import { JourneyBuilderMode } from '../enums'
import * as React from 'react'
import { Dispatch } from 'react'
import { Journey } from './journey-api-response'
import { JourneyEdge, JourneyNode, JourneyStep } from './journey-nodes'

// region JourneyContext

export type JourneyProps = {
    domain: DomainDto
    journey: Journey
    mode: JourneyBuilderMode
    callbacks: JourneyStateCallbacks
    nodes: JourneyNode[]
    edges: JourneyEdge[]
}

type JourneyStateCallbacks = {
    onNodeAdd: <T extends JourneyStep>(node: JourneyNode<T>) => void
    handleNodeRemove: <T extends JourneyStep>(node: JourneyNode<T>) => void
    onNodeClick: <T extends JourneyStep>(ev: React.MouseEvent<HTMLDivElement, MouseEvent>, node: JourneyNode<T>) => void
    onNodeUpdate: <T extends JourneyStep>(node: JourneyNode<T>, updates: JourneyNode<T>) => JourneyNode[]
    emitChanges?: (journey: JourneyState) => void
}

export type JourneyState = {
    domain: DomainDto
    journey?: Journey
    mode: JourneyBuilderMode
    callbacks: JourneyStateCallbacks
    nodes: JourneyNode[]
    edges: JourneyEdge[]
}

export type ActionDispatcher = (action: JourneyDispatchActionPack) => void
export type JourneyReducerElements = [context: JourneyState, dispatch: Dispatch<JourneyDispatchActionPack>]

export enum JourneyAction {
    SET_JOURNEY = 'setJourney',
    SET_ORIGINAL_STEPS = 'setOriginalSteps',
    UPDATE_ORIGINAL_STEPS = 'updateOriginalSteps',
    ON_NODE_ADD = 'onNodeAdd',
    ON_NODE_UPDATE = 'onNodeUpdate',
    ON_NODE_REMOVE = 'onNodeRemove',
    ON_JOURNEY_CHANGES = 'onJourneyChanges',
}

export type JourneySetPack = {
    action: JourneyAction.SET_JOURNEY
    data: Journey
}

export type JourneyStepsSetPack = {
    action: JourneyAction.SET_ORIGINAL_STEPS
    data: JourneyStep[]
}

export type JourneyStepsUpdatePack = {
    action: JourneyAction.UPDATE_ORIGINAL_STEPS
    data: JourneyStep[]
}

export type JourneyNodeAddPack = {
    action: JourneyAction.ON_NODE_ADD
    data: JourneyNode
}

export type JourneyNodeUpdatePack<T extends JourneyStep = JourneyStep> = {
    action: JourneyAction.ON_NODE_UPDATE
    data: { node: JourneyNode<T>; updates: JourneyNode<T> }
}

export type JourneyNodeRemovePack = {
    action: JourneyAction.ON_NODE_REMOVE
    data: JourneyNode
}

export type JourneyChangesPack = {
    action: JourneyAction.ON_JOURNEY_CHANGES
    data: { nodes?: JourneyNode[]; edges?: JourneyEdge[] }
}

export type JourneyDispatchActionPack =
    | JourneySetPack
    | JourneyNodeAddPack
    | JourneyNodeRemovePack
    | JourneyNodeUpdatePack
    | JourneyChangesPack
    | JourneyStepsSetPack
    | JourneyStepsUpdatePack

// endregion

// end region
