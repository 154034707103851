import * as React from 'react'
import { CardElement, injectStripe, Elements, StripeProvider, ReactStripeElements } from 'react-stripe-elements'
import { STRIPE_API_KEY } from '../../constants'
import StripeProviderProps = ReactStripeElements.StripeProviderProps

interface IStripeElementsProps {
    ref?: any
    stripe?: any
}

interface IStripeElementsState {}

class StripeElements extends React.Component<IStripeElementsProps, IStripeElementsState> {
    public render(): React.ReactNode {
        return <CardElement />
    }
}

const WrappedStripeElements = injectStripe(StripeElements)

interface IStripeFormProps {}

interface IStripeFormState {
    stripe?: any
}

// @todo v18_Upgrade - hacky fix for old versions missing children prop - should migrate.
const CompatStripeProvider = (props: React.PropsWithChildren<StripeProviderProps>) => {
    return <StripeProvider {...props} />
}

const CompatElements = (props: React.PropsWithChildren<stripe.elements.ElementsCreateOptions>) => {
    return <Elements {...props} />
}

export class StripeForm extends React.Component<IStripeFormProps, IStripeFormState> {
    public state: IStripeFormState = {
        stripe: null,
    }

    protected stripeElements: any

    public componentDidMount() {
        if (window.Stripe) {
            this.setState({
                stripe: window.Stripe(STRIPE_API_KEY),
            })
        } else {
            const stripeEl = document.querySelector('#stripe-js')

            if (!!stripeEl) {
                stripeEl.addEventListener('load', () => {
                    // Create Stripe instance once Stripe.js loads
                    this.setState({
                        stripe: window.Stripe(STRIPE_API_KEY),
                    })
                })
            }
        }
    }

    public render() {
        return (
            <CompatStripeProvider stripe={this.state.stripe}>
                <CompatElements>
                    <WrappedStripeElements ref={(el) => (this.stripeElements = el)} />
                </CompatElements>
            </CompatStripeProvider>
        )
    }

    public get stripeProvider(): any {
        return this.stripeElements.state.stripe
    }
}
