import { useService } from '@pushly/aqe/lib/hooks'
import React from 'react'
import { AppService } from '../../services'
import { AppState } from '../../stores/app'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { PageHeader } from '@pushly/aqe/lib/components'
import { asCaslSubject, CurrentUserCan } from '../../stores/app-ability'
import { AbilityAction } from '../../enums/ability-action.enum'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { CampaignTableWell } from '../campaigns/campaign-table-well/campaign-table-well'

type JourneyProps = {}

export const Journeys = (props: JourneyProps) => {
    const appService = useService(AppService)
    const appState = useService(AppState)
    const pathDomainId = getPathEntityId('domain')

    // @todo NS-1663 - Add Journey subject entity / rename
    let permissionEntity: SubjectEntity = SubjectEntity.CAMPAIGN
    let permissionConstraints: any = {}
    permissionConstraints.domainId = pathDomainId

    const newJourneyPath = '/journeys/new'
    return (
        <div className="journeys-root-page">
            <PageHeader
                title="Journeys"
                onTitleSet={appService.customizeTabTitle}
                action={
                    <CurrentUserCan
                        do={AbilityAction.CREATE}
                        on={asCaslSubject(permissionEntity, permissionConstraints)}
                    >
                        <AsyncButton
                            className="new-journey-button"
                            type="primary"
                            onClick={() => appService.routeWithin('domain', newJourneyPath)}
                            altHref={newJourneyPath}
                        >
                            <span>Create Journey</span>
                        </AsyncButton>
                    </CurrentUserCan>
                }
            />
            <CampaignTableWell />
        </div>
    )
}
