import { createContext, useContext } from 'react'
import { ActionDispatcher, JourneyReducerElements, JourneyState } from './types/journey-context'

type JourneyContext = { state: JourneyState; dispatch: ActionDispatcher }
export const JourneyContext = createContext<JourneyContext>(null!)

export const useJourneyContext = (): JourneyReducerElements => {
    const { state, dispatch } = useContext(JourneyContext)
    return [state, dispatch]
}
