import * as React from 'react'

declare const mapboxgl: any

import { MAPBOX_ACCESS_TOKEN } from './mapbox.constants'

interface IMapbox {
    lng: number
    lat: number
    zoom: number
    minWidth: number
    width?: number
    minHeight: number
    height?: number
    controls?: any[]
    listeners?: Record<string, (any) => void>
    showNavigationControls?: boolean
}

export class Mapbox extends React.Component<IMapbox> {
    private map: any
    private mapContainer: HTMLDivElement | null

    constructor(props) {
        super(props)
    }

    public render() {
        return (
            <div
                ref={(el) => (this.mapContainer = el)}
                style={{
                    minWidth: this.props.minWidth,
                    width: this.props.width,
                    minHeight: this.props.minHeight,
                    height: this.props.height,
                    position: 'relative',
                }}
            ></div>
        )
    }

    public componentDidUpdate(prevProps: Readonly<IMapbox>, prevState: Readonly<{}>, snapshot?: any): void {
        this.map.resize()
    }

    public componentDidMount() {
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/pushly/ck5gyftn80dmj1imxucglqhm3',
            center: [this.props.lng, this.props.lat],
            zoom: [this.props.zoom],
            accessToken: MAPBOX_ACCESS_TOKEN,
        })

        if (this.props.controls) {
            this.props.controls.forEach((control) => {
                this.map.addControl(control)
            })
        }

        // tslint:disable-next-line:forin
        for (const key in this.props.listeners) {
            this.map.on(key, this.props.listeners[key])
        }

        if (this.props.showNavigationControls) {
            this.map.addControl(new mapboxgl.NavigationControl())
        }
    }
}
