import * as React from 'react'
import { SavedReportsListContext } from './saved-reports-list-context'
import { Input } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'

interface ISRLHeaderProps {
    title?: string
    hideSearch?: boolean
    hideStatus?: boolean
    hideSource?: boolean
    filterSize: SizeType
}

interface ISRLState {}

export const SavedReportsListHeader: React.FC<React.PropsWithChildren<ISRLHeaderProps>> = ({ ...props }) => {
    const defaultClassName = 'sw-v2-saved-reports-list'
    let liveTypeTimeout
    const context = React.useContext(SavedReportsListContext)

    const { onSearchClick, onFilterChange, filters } = context

    const hideAllFilters = props.hideSearch && props.hideStatus && props.hideSource

    return (
        <div className={buildClassName('header')}>
            <div className={buildClassName(`title ${!props.title ? 'hidden' : ''}`)}>
                <span>{props.title}</span>
            </div>

            <div className={buildClassName(`filters ${!hideAllFilters ? '' : 'hidden'}`)}>
                {!props.hideSearch && (
                    <Input.Search
                        className={buildClassName('search')}
                        size={props.filterSize}
                        placeholder="Search by Name or ID"
                        enterButton={true}
                        onSearch={onSearchClick}
                        onChange={(ev) => {
                            clearTimeout(liveTypeTimeout)

                            const value = ev.target.value
                            liveTypeTimeout = setTimeout(() => {
                                onFilterChange('search', value)
                            }, 320)
                        }}
                    />
                )}
            </div>
        </div>
    )

    function buildClassName(className: string): string {
        return `${defaultClassName}-${className}`
    }
}
