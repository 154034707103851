import * as React from 'react'
import * as deepEqual from 'react-fast-compare'
import { Button, Drawer, executeCleanDrawerRender, Well } from '@pushly/aqe/lib/components'
import { EditOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Form } from '@ant-design/compatible'
import { Input, Select, Skeleton } from 'antd'
import moment from 'moment-timezone'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Container } from 'typescript-ioc/es5'
import { Link } from 'react-router-dom'
import * as randomstring from 'randomstring'
import { DomainDto } from '../../dtos/domain'
import { AppState } from '../../stores/app'
import { AppService, DomainService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import { isDeliveryChannelEnabled, prepareWhitelistDomains } from '../../_utils/domain'
import { validateFields } from '../../_utils/antd'
import { escapeRegExp, simpleFormErrorNotification } from '../../_utils/utils'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT, FEAT_SDK_EVENT_ONLY_DOMAINS } from '../../constants'
import { getSupportedTimezones } from '../../_utils/moment'
import StackedTagSelect from '../stacked-tag-select/stacked-tag-select'
import colorConvert from 'color-convert'
import ColorPickerPopover from '../color-picker-popover/color-picker-popover'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject, CurrentUserCan } from '../../stores/app-ability'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { FormLayoutRow } from '../form-layout-row/form-layout-row'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IDomainDetailsEditor extends FormComponentProps {
    domain: DomainDto
    visible: boolean
    onClose: (e: any) => void
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IDDEState {
    prefix: string
    color: any
    textColor: string
}

export const DomainDetailsEditor = Form.create<IDomainDetailsEditor>()(
    class extends React.Component<IDomainDetailsEditor, IDDEState> {
        public state: IDDEState = {
            prefix: this.domain?.displayMeta?.prefix,
            color: this.domain?.displayMeta?.overlay_config?.color ?? '#ffbf00',
            textColor:
                colorConvert.hex.hsl(this.domain?.displayMeta?.overlay_config?.color)?.[2] > 70
                    ? 'black'
                    : 'white' ?? 'white',
        }

        protected appState: AppState
        protected appService: AppService
        protected domainService: DomainService

        public constructor(props: IDomainDetailsEditor) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.domainService = Container.get(DomainService)

            executeCleanDrawerRender(this)
        }

        public render() {
            const currentValues = this.getCurrentValues()

            const timezones = getSupportedTimezones()
            const suggestedTimezone = moment.tz.guess()

            let displayName = this.domain.displayName
            const prefix = this.domain?.displayMeta?.prefix

            if (this.domain?.prodDomainId !== null && prefix) {
                const rgx = new RegExp(`^\\(${escapeRegExp(prefix)}\\)\\s`, 'i')
                displayName = displayName.replace(rgx, '')
            }

            return (
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('ddf-details-editor')}
                    title="Update Details"
                    placement="right"
                    closable={true}
                    onSubmit={this.handleSubmit}
                    disableSubmit={Object.keys(currentValues).length === 0}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}>
                        {this.appUserIsInternalDomainEditor && (
                            <Well title="Domain Information" mode="ghost" hideFooter={true}>
                                <Form.Item label="Display Name" className={getClassNames('ddf-details-editor-name')}>
                                    {this.domain.prodDomainId ? (
                                        <Input.Group compact={true} size="small">
                                            {this.props.form.getFieldDecorator('prefix', {
                                                initialValue: prefix ?? 'TEST',
                                            })(
                                                <Select
                                                    onSelect={(value: string) => {
                                                        this.setState({ prefix: value })
                                                        this.props.form.setFieldsValue({ prefix: value })
                                                    }}
                                                >
                                                    <Select.Option value="TEST">Test</Select.Option>
                                                    <Select.Option value="DEV">Dev</Select.Option>
                                                    <Select.Option value="QA">QA</Select.Option>
                                                </Select>,
                                            )}
                                            <Input value={displayName} disabled={true} />
                                        </Input.Group>
                                    ) : (
                                        this.props.form.getFieldDecorator('displayName', {
                                            initialValue: this.domain.displayName,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Display name is required.',
                                                },
                                            ],
                                        })(<Input size="small" />)
                                    )}
                                </Form.Item>

                                {this.domain.prodDomainId && (
                                    <Form.Item
                                        label="Icon Overlay Color"
                                        className={getClassNames('ddf-details-editor-overlay')}
                                    >
                                        <div className="generate-test-confirm-group">
                                            <div className="color-selections">
                                                {this.props.form.getFieldDecorator('overlayColor', {
                                                    initialValue: this.domain.displayMeta?.overlay_config?.color,
                                                })(
                                                    <ColorPickerPopover
                                                        defaultColor={this.state.color}
                                                        color={this.state.color}
                                                        onChange={(hex) => {
                                                            const textColor =
                                                                colorConvert.hex.hsl(hex as string)[2] > 70
                                                                    ? 'black'
                                                                    : 'white'
                                                            this.setState({ color: hex, textColor })
                                                        }}
                                                    />,
                                                )}
                                                <svg
                                                    className="overlay"
                                                    id="overlay"
                                                    viewBox="0 0 400 400"
                                                    width="64"
                                                    height="64"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <polygon
                                                        points="0 0 400 0 400 400 0 400"
                                                        strokeWidth="30"
                                                        stroke={this.state.color}
                                                        fill="none"
                                                    />
                                                    <polygon
                                                        points="0 0 300 0 0 300"
                                                        strokeWidth="5"
                                                        stroke={this.state.color}
                                                        fill={this.state.color}
                                                    />
                                                    <text
                                                        fill={this.state.textColor}
                                                        fontSize="80"
                                                        fontWeight="900"
                                                        textAnchor="middle"
                                                        x="0"
                                                        y="200"
                                                        transform="rotate(-45)"
                                                    >
                                                        {this.state.prefix}
                                                    </text>
                                                </svg>
                                                {this.props.domain?.defaultIconUrl ? (
                                                    <img
                                                        className="overlay-icon"
                                                        src={this.props.domain?.defaultIconUrl}
                                                    />
                                                ) : (
                                                    <span className="icon-missing">No Icon</span>
                                                )}
                                            </div>
                                        </div>
                                    </Form.Item>
                                )}
                            </Well>
                        )}

                        <Well title="Platform Configuration" mode="ghost" hideFooter={true}>
                            {!this.domain.timezone && (
                                <Form.Item label="Time Zone" className={getClassNames('ddf-details-editor-timezone')}>
                                    {this.props.form.getFieldDecorator('timezone', {
                                        initialValue: suggestedTimezone,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Time Zone must be selected',
                                            },
                                        ],
                                    })(
                                        <Select size="small" showSearch={true} placeholder="Select a time zone">
                                            {timezones.map((tz: string) => (
                                                <Select.Option key={tz} value={tz}>
                                                    {tz}
                                                </Select.Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>
                            )}

                            <Form.Item
                                label="SDK Allowed Domains"
                                className={getClassNames('ddf-details-editor-whitelist')}
                            >
                                {this.props.form.getFieldDecorator('whitelistDomains', {
                                    initialValue: this.domain.whitelistDomains || [],
                                })(
                                    <StackedTagSelect<string[]>
                                        size="small"
                                        mode="tags"
                                        className="domains-allowed"
                                        placeholder="www.pushly.com, subdomain.pushly.com"
                                        getPopupContainer={this.appService.getAppContainer}
                                        tokenSeparators={[',', ';', ' ', '\n']}
                                        showOptions={false}
                                        notFoundContent=""
                                    />,
                                )}
                                <div className={getClassNames('form-layout-row-sub')}>
                                    <InfoCircleOutlined />
                                    <span>
                                        Enter each domain (including development/testing domains) that will request
                                        notification permissions or will run in event only mode.
                                    </span>
                                </div>
                                <div className={getClassNames('form-layout-row-sub')}>
                                    <WarningOutlined />
                                    <span>
                                        <b>Note:</b> Wildcard <b>*</b> entries are not supported by Apple's APNs and
                                        will result in an invalid Safari integration.
                                    </span>
                                </div>
                            </Form.Item>

                            {this.domainHasEventOnlyDomainsFlag && (
                                <Form.Item
                                    label="SDK Event Only Domains"
                                    className={getClassNames('ddf-details-editor-sdk-ev-only-domains')}
                                >
                                    {this.props.form.getFieldDecorator('sdkEventOnlyDomains', {
                                        initialValue: this.domain.sdkEventOnlyDomains,
                                    })(
                                        <StackedTagSelect<string[]>
                                            size="small"
                                            mode="tags"
                                            className="domains-allowed"
                                            placeholder="www.pushly.com, subdomain.pushly.com"
                                            getPopupContainer={this.appService.getAppContainer}
                                            tokenSeparators={[',', ';', ' ', '\n']}
                                            showOptions={false}
                                            notFoundContent=""
                                        />,
                                    )}
                                    <div className={getClassNames('form-layout-row-sub')}>
                                        <InfoCircleOutlined />
                                        <span>
                                            Enter each domain (including development/testing domains) that should{' '}
                                            <b>not request permissions</b> but still be able to send subscriber events.
                                        </span>
                                    </div>
                                    <div className={getClassNames('form-layout-row-sub')}>
                                        <WarningOutlined />
                                        <span>
                                            <b>Note:</b> All domains must also be included in the SDK Allowed Domains.
                                            Wildcard <b>*</b> entries are not supported for event only domains.
                                        </span>
                                    </div>
                                </Form.Item>
                            )}
                        </Well>
                    </Form>
                </Drawer>
            )
        }

        protected get domain(): DomainDto {
            return this.props.domain
        }

        protected get domainHasEventOnlyDomainsFlag(): boolean {
            const eventOnlyFeatFlag = this.appState.flags.findActive(FEAT_SDK_EVENT_ONLY_DOMAINS)?.getKey()
            return !!eventOnlyFeatFlag && (this.props.domain.flags.includes(eventOnlyFeatFlag) ?? false)
        }

        protected get appUserIsInternalDomainEditor() {
            return this.appState.abilityStore.acs.isInternalEditorUserScopeForDomain(this.domain.id)
        }

        protected getCurrentValues(): Partial<DomainDto> {
            const formValues = this.props.form.getFieldsValue()
            const values: Partial<DomainDto> = {}

            // display_meta update, should contain a prefix regardless of update
            let displayMeta = { ...(this.domain.displayMeta ?? {}) }

            if (
                formValues.overlayColor !== this.domain.displayMeta?.overlay_config?.color &&
                formValues.overlayColor !== undefined
            ) {
                displayMeta = {
                    ...displayMeta,
                    overlay_config: {
                        color: formValues.overlayColor,
                    },
                }
            }

            if (!!formValues.prefix) {
                displayMeta = {
                    ...displayMeta,
                    prefix: formValues.prefix,
                }
            }

            if (!deepEqual(displayMeta, this.domain.displayMeta ?? {})) {
                values.displayMeta = displayMeta
            }

            if (this.appUserIsInternalDomainEditor) {
                if (formValues.displayName !== this.domain.displayName) {
                    values.displayName = formValues.displayName
                }
            }

            if (!deepEqual(formValues.whitelistDomains, this.domain.whitelistDomains || [])) {
                values.whitelistDomains = formValues.whitelistDomains
            }

            if (this.domainHasEventOnlyDomainsFlag) {
                if (!deepEqual(formValues.sdkEventOnlyDomains, this.domain.sdkEventOnlyDomains)) {
                    values.sdkEventOnlyDomains = formValues.sdkEventOnlyDomains
                }
            }

            return values
        }

        protected handleSubmit = async () => {
            try {
                await validateFields(this.props.form)
                const values = this.getCurrentValues()

                if (values.whitelistDomains) {
                    values.whitelistDomains = prepareWhitelistDomains(values.whitelistDomains)
                }
                if (values.sdkEventOnlyDomains) {
                    values.sdkEventOnlyDomains = prepareWhitelistDomains(values.sdkEventOnlyDomains)
                }

                const { ok, data } = await this.domainService.updateDomainById(
                    this.domain.id,
                    values,
                    `patch-domain-${this.domain.id}`,
                )

                if (ok) {
                    this.props.onDomainUpdated(data)
                    this.props.onClose(null)
                }
            } catch (err) {
                simpleFormErrorNotification(err)
            }
        }
    },
)

interface IAccountDetailsWell {
    loading?: boolean
    domain: DomainDto
    title?: string
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IDWState {
    showDrawer: boolean
    drawerKey?: string
}

export class DomainDetailsWell extends React.Component<IAccountDetailsWell, IDWState> {
    public state: IDWState = {
        showDrawer: false,
    }

    protected appState: AppState

    public constructor(props: IAccountDetailsWell) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        return (
            <Well
                className="nested"
                title="Details"
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            {this.appUserIsInternalDomainEditor && this.renderOrgWell()}
                            {this.renderInformationWell()}
                            {this.renderConfigurationWell()}

                            <DomainDetailsEditor
                                key={this.state.drawerKey}
                                visible={this.state.showDrawer}
                                domain={this.domain}
                                onClose={this.handleDrawerClose}
                                onDomainUpdated={this.props.onDomainUpdated}
                            />
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected get appUserIsInternalDomainEditor() {
        return this.appState.abilityStore.acs.isInternalEditorUserScopeForDomain(this.domain.id)
    }

    protected get isWebEnabledForDomain() {
        return isDeliveryChannelEnabled(this.domain, DeliveryChannel.WEB)
    }

    protected renderOrgWell() {
        return (
            <Well title="Organization" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Org Name</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        <Link to={`/organizations/${this.appState.currentDomain?.accountId}`}>
                            {this.appState.currentDomain?.accountName}
                        </Link>
                    </div>
                </div>
            </Well>
        )
    }

    protected renderInformationWell() {
        return (
            <Well title="Domain Information" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Display Name</div>
                    <div className={getClassNames('form-layout-row-value')}>{this.domain.displayName}</div>
                </div>

                {this.appUserIsInternalDomainEditor && (
                    <div className={getClassNames('form-layout-row')}>
                        <div className={getClassNames('form-layout-row-label')}>Integration Type</div>
                        <div className={getClassNames('form-layout-row-value')}>Native</div>
                    </div>
                )}

                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Domain URL</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        <a href={`https://${this.domain.name}`} target="_blank">
                            {this.domain.name}
                        </a>
                    </div>
                </div>

                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>SDK Key</div>
                    <div className={getClassNames('form-layout-row-value')}>{this.domain.domainKey ?? ''}</div>
                </div>

                {this.appUserIsInternalDomainEditor && (
                    <>
                        <div className={getClassNames('form-layout-row')}>
                            <div className={getClassNames('form-layout-row-label')}>Created</div>
                            <div className={getClassNames('form-layout-row-value')}>
                                {moment(this.domain.createdAt).format('LLL')}
                                <CurrentUserCan
                                    do={AbilityAction.READ}
                                    on={asCaslSubject(SubjectEntity.USER, { id: this.domain.createdBy })}
                                >
                                    <span className="lowlight"> by </span>
                                    {this.domain.createdByUsername}
                                </CurrentUserCan>
                            </div>
                        </div>

                        <div className={getClassNames('form-layout-row')}>
                            <div className={getClassNames('form-layout-row-label')}>Last Updated</div>
                            <div className={getClassNames('form-layout-row-value')}>
                                {moment(this.domain.updatedAt).format('LLL')}
                                <CurrentUserCan
                                    do={AbilityAction.READ}
                                    on={asCaslSubject(SubjectEntity.USER, { id: this.domain.updatedBy })}
                                >
                                    <span className="lowlight"> by </span>
                                    {this.domain.updatedByUsername}
                                </CurrentUserCan>
                            </div>
                        </div>
                    </>
                )}
            </Well>
        )
    }

    protected renderConfigurationWell() {
        return (
            <Well title="Platform Configuration" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Time Zone</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        {!this.domain.timezone ? (
                            'None selected'
                        ) : (
                            <span>
                                {this.domain.timezone} | {moment().tz(this.domain.timezone).format('z | Z')}
                            </span>
                        )}
                    </div>
                    {this.appUserIsInternalDomainEditor && (
                        <div className={getClassNames('form-layout-row-sub')}>
                            <InfoCircleOutlined />
                            <span>To update your domain’s time zone please contact your account manager.</span>
                        </div>
                    )}
                </div>

                {this.isWebEnabledForDomain && (
                    <FormLayoutRow
                        label="SDK Allowed Domains"
                        value={this.domain.whitelistDomains?.join(', ') || '*'}
                        info={
                            <span>
                                Wildcard <b>*</b> entries are not supported by Apple's APNs and will result in an
                                invalid Safari integration.
                            </span>
                        }
                        infoIcon={<WarningOutlined />}
                    />
                )}

                {this.domainHasEventOnlyDomainsFlag && this.isWebEnabledForDomain && (
                    <FormLayoutRow
                        label="SDK Event Only Domains"
                        value={this.domain.sdkEventOnlyDomains.join(', ') || 'N/A'}
                        info={
                            <span>
                                All domains must also be included in the SDK Allowed Domains List. Wildcard <b>*</b>{' '}
                                entries are not supported for event only domains.
                            </span>
                        }
                        infoIcon={<WarningOutlined />}
                    />
                )}
            </Well>
        )
    }

    protected get domain(): DomainDto {
        return this.props.domain
    }

    protected get domainHasEventOnlyDomainsFlag(): boolean {
        const eventOnlyFeatFlag = this.appState.flags.findActive(FEAT_SDK_EVENT_ONLY_DOMAINS)?.getKey()
        return !!eventOnlyFeatFlag && (this.props.domain.flags.includes(eventOnlyFeatFlag) ?? false)
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }
}
