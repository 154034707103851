import { Edge, MarkerType } from '@xyflow/react'
import { JourneyEdge } from './types/journey-nodes'

export const EDGE_ID_PREFIX = 'eid-'
export const DEFAULT_CUSTOM_EDGE_CONFIG: Partial<Edge<JourneyEdge>> = {
    type: 'custom',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 8,
        color: '#75808a',
    },
}
