import { getRandId } from '@pushly/models/lib/utils/get-rand-id'
import { order } from '../_utils/object'

const InitialStateMappings = new WeakMap<UIEditTrackable, string>()

export class UIEditTrackable<DataType extends {} = any> {
    public constructor(
        public data: DataType,
        public readonly id: string | number = getRandId(),
    ) {
        InitialStateMappings.set(this, this.toHash())
    }

    public get initialState() {
        return InitialStateMappings.get(this) ?? ''
    }

    public resetInitialState(value: DataType) {
        this.data = order(value)
        InitialStateMappings.set(this, this.toHash())
    }

    public hasChanged() {
        return this.toHash() !== this.initialState
    }

    public equals(other: this) {
        return this.toHash() === other.toHash()
    }

    public clone<T extends this>() {
        return new (this.constructor as new (...args: any[]) => T)(structuredClone(this.data))
    }

    public toString() {
        return JSON.stringify(this)
    }

    public toJSON() {
        return this.data
    }

    public toHash(value?: DataType) {
        const orderedData = order(value ?? this.data)
        return btoa(encodeURI(JSON.stringify(orderedData)))
    }
}
