import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { TrophyFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'

export const NotificationAbWinnerBadge: React.FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<IBadgeProps>>
> = (props) => {
    const badge = (
        <span className="icon-ab-winner">
            <TrophyFilled className="test-winner-icon" />
        </span>
    )

    return (
        <span className="notification-ab-winner-badge">
            {props.hideTooltip ? badge : <Tooltip title={props.tooltip || 'Winning Variant'}>{badge}</Tooltip>}
        </span>
    )
}
