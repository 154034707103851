import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'
import { ICanvasOuterDefaultProps } from '@mrblenny/react-flow-chart'
import { ZoomUtils } from './zoom-utils'
import { getClassNames } from '../../_utils/classnames'
import { CampaignEditableState } from './enums'
import { osName } from 'react-device-detect'
import { CampaignDto } from '../../features/campaigns/dtos/campaign-dto'
import { DomainDto } from '../../dtos/domain'

/* TODO:
 *  1. v1 - add "FIT" button and calculations
 *     * abstract tree calculations (campaign-builder.tsx) into utility class
 *  2. v2 - add undo/redo
 */

interface ICanvasOuter {
    ref: (ref: any) => void
    getIsLoading: () => boolean
    getZoomElementRef: () => any
    getEditableState: () => CampaignEditableState
    getSubmitDisabledState: () => boolean
    onClear: (e: React.MouseEvent<HTMLElement>) => void
    onChange?: (changeSet: any) => void
    onSubmit?: () => void
    getCampaign: () => CampaignDto
    getDomain: () => DomainDto
    title?: string | React.ReactNode
    // onRevisionChange?: (revision: any, campaign: any) => any,
    // showRevisionSelector?: boolean,
}

interface ICanvas extends ICanvasOuter {
    onTransformReset: () => void
}

class Canvas extends React.Component<React.PropsWithChildren<ICanvas>, any> {
    public state: any = {
        loading: false,
        showInstructions: false,
    }

    public canvasWell: any

    private instructionTimer: any

    public render() {
        const isMac = /mac/i.test(osName)
        const loading = this.state.loading || this.props.getIsLoading()

        const isReadonly = this.props.getEditableState() === CampaignEditableState.READONLY

        const FragmentWrapper = (_) => _.children
        const WellWrapper = isReadonly
            ? (_) => <div className={`campaign-builder-well-actions`}>{_.children}</div>
            : FragmentWrapper
        const ActionsWrapper = isReadonly ? (_) => <div className={`actions`}>{_.children}</div> : FragmentWrapper

        return (
            <Well
                ref={(el) => (this.canvasWell = el)}
                className={`campaign-builder-well ${
                    this.props.getEditableState() === CampaignEditableState.READONLY && 'readonly'
                }`}
                title={this.renderTitle()}
                loading={loading}
                hideFooter={false}
                submitText="Save"
                onSubmit={this.props.onSubmit}
                disableSubmit={this.props.getSubmitDisabledState()}
                hideCancel={true}
                hideSubmit={isReadonly}
                actions={
                    <WellWrapper>
                        <div className="show-revision-selector">
                            {/*{this.props.showRevisionSelector && this.renderRevisionSelector()}*/}
                        </div>
                        <ActionsWrapper>
                            <Button
                                size="small"
                                onMouseDown={() => ZoomUtils.zoom(this.props.getZoomElementRef(), 50, 50, -1)}
                                onMouseUp={() => ZoomUtils.cancelZoom()}
                            >
                                <ZoomInOutlined />
                            </Button>
                            <Button
                                size="small"
                                onMouseDown={() => ZoomUtils.zoom(this.props.getZoomElementRef(), 50, 50, 1)}
                                onMouseUp={() => ZoomUtils.cancelZoom()}
                            >
                                <ZoomOutOutlined />
                            </Button>
                            <Button
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.props.onTransformReset()
                                }}
                            >
                                <span>Reset Zoom</span>
                            </Button>
                            {this.props.getEditableState() !== CampaignEditableState.READONLY ? (
                                <>
                                    <div className="v-div" />

                                    <Button
                                        size="small"
                                        disabled={this.props.getEditableState() !== CampaignEditableState.EDITABLE}
                                        onClick={(e) => {
                                            this.props.onClear(e)
                                            setTimeout(() => this.props.onTransformReset(), 100)
                                        }}
                                    >
                                        <span>Clear</span>
                                    </Button>
                                </>
                            ) : null}
                        </ActionsWrapper>
                    </WellWrapper>
                }
            >
                <div
                    className={getClassNames('campaign-canvas-instructions', {
                        visible: this.state.showInstructions,
                    })}
                >
                    <div className="instruction-set">
                        <span>Use ctrl + scroll to zoom in and out</span>
                        <span>Click and drag to move the canvas around</span>
                    </div>
                </div>
                {this.props.children}
            </Well>
        )
    }

    public async showInstructions() {
        clearTimeout(this.instructionTimer)

        if (!this.state.showInstructions) {
            this.setState({ showInstructions: true })
        }

        this.instructionTimer = setTimeout(() => this.hideInstructions(), 1725)
    }

    public async hideInstructions() {
        clearTimeout(this.instructionTimer)

        if (this.state.showInstructions) {
            this.setState({ showInstructions: false })
        }
    }

    protected renderTitle() {
        return this.props.title ?? 'Builder'
    }
}

export const CanvasOuter = (props: ICanvasOuter) => {
    const Outer = styled.div.attrs({
        className: getClassNames('campaign-canvas-outer'),
    })<ICanvasOuterDefaultProps>``

    return React.forwardRef(
        ({ onTransformReset, children, ...otherProps }: ICanvasOuterDefaultProps, ref: React.Ref<HTMLDivElement>) => {
            return (
                <Outer ref={ref} {...(otherProps as any)}>
                    <Canvas {...props} ref={props.ref} onTransformReset={onTransformReset as () => void}>
                        {children}
                    </Canvas>
                </Outer>
            )
        },
    )
}
