import React, { useEffect, useState } from 'react'
import './styles/notification-builder.scss'
import clone from 'clone'
import { getClassNames } from '../../_utils/classnames'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import { PromiseableProp } from '../../_utils/promiseable-prop'
import { DomainDto } from '../../dtos/domain'
import { NotificationDto } from '../../features/notifications'
import { INotificationBuilderState, TemplateDetails } from './interfaces/notification-builder-state.interface'
import { NotificationTestConfigurationModel } from '../../models/notification/notification-test-configuration.model'
import { NotificationAudienceModel } from '../../models/notification/notification-audience.model'
import {
    addVariant,
    getVariantTtlConfig,
    resolveNotificationsProp,
    resolveTemplatesProp,
    rmVariant,
    validateSubmit,
    validateVariantSwitch,
} from './helpers'
import VariantManager from './elements/variant-manager'
import { useRefEffect } from '../../hooks/use-ref-effect'
import { NotificationVariantModel } from '../../models/notification/notification-variant.model'
import { NotificationDeliveryModel } from '../../models/notification/notification-delivery.model'
import { NotificationBuilderLevel, NotificationBuilderTheme } from './enums'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { AccountDto } from '../../dtos/account.dto'
import { EcommItemPickStrategy } from '../../enums/ecomm-item-pick-strategy'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import * as moment from 'moment-timezone'
import FlagList from '../../structs/flags-list'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { AccountService, AppService } from '../../services'
import { getEnabledDeliveryChannels } from '../../_utils/domain'
import { getAccountEnabledDeliveryChannels } from '../../_utils/account'
import { NotificationNamedTemplate } from '@pushly/models/lib/structs'
import { ActionDispatcher } from './types'
import { getActionDispatcher, getBuilderStateReducer } from './reducers'
import { NotificationPayload } from '../../features/journeys/types/notification-payload'
import { NotificationDefaultContent } from '@pushly/models/lib/structs/notification-default-content'
import { NotificationDefaultContentModel } from '../../models/notification/notification-default-content.model'

interface INotificationBuilderCustomRenderProps {
    builder: INotificationBuilderState
    dispatchChanges: ActionDispatcher
    onChannelChange: (change: DeliveryChannel[]) => void
    onDetailsChange: (change: TemplateDetails) => void
    onTestChange: (test: NotificationTestConfigurationModel) => void
    onAudienceChange: (audience: NotificationAudienceModel) => void
    onDistributionChange: (builder: INotificationBuilderState) => void
    variants: NotificationVariantModel[]
    variantManager: any
    onVariantChange: (variant: NotificationVariantModel, id: number) => void
    onSelectedTemplateChange: (change: { id?: number; domain: DomainDto }) => void
    validateSubmit: (
        builder: INotificationBuilderState,
        domain: DomainDto,
        flags: FlagList,
        isPreview?: boolean,
        isDraft?: boolean,
    ) => Promise<boolean>
}

interface INotificationBuilder {
    className?: string
    domain: DomainDto
    account?: AccountDto
    theme?: NotificationBuilderTheme
    level?: NotificationBuilderLevel
    mode?: 'create' | 'edit'
    type?: 'template' | 'notification'
    notifications?: PromiseableProp<NotificationDto[] | undefined>
    templates?: PromiseableProp<NotificationNamedTemplate[] | NotificationPayload[] | undefined>
    defaultSelectedNotifId?: number
    defaultSelectedTemplateId?: number
    defaultChannels?: DeliveryChannel[]
    showWebhookOptions?: boolean
    showEcommStrategy?: boolean
    defaultEcommStrategy?: EcommItemPickStrategy
    onChange?: (builder: INotificationBuilderState) => any
    children: (props: INotificationBuilderCustomRenderProps) => JSX.Element
}

// Runs once on reducer init
const getBuilderStateInitializer: any =
    (domain: DomainDto, account?: AccountDto) => (initialState: INotificationBuilderState) => {
        return {
            ...initialState,
            variants: [getInitialVariant(domain, account, initialState)],
        }
    }

export const getInitialVariant = (
    domain: DomainDto,
    account: AccountDto | undefined,
    state: INotificationBuilderState,
) => {
    const isOrgLevel = state.level === NotificationBuilderLevel.ORG
    const isDomainLevel = state.level === NotificationBuilderLevel.DOMAIN
    const isCampaignLevel = state.level === NotificationBuilderLevel.CAMPAIGN

    const defaultContentValues: any = {}

    const variant = NotificationVariantModel.build({})

    const content = variant.getContent().getDefaultContent()
    content.setIconUrl(isOrgLevel ? null : domain.defaultIconUrl)
    content.setBadgeUrl(isOrgLevel ? null : domain.defaultBadgeUrl)
    content.setIsUsingDomainDefaultIcon(isOrgLevel)
    content.setIsUsingDomainDefaultBadge(isOrgLevel)
    if (isOrgLevel && !!account?.defaultNotificationLandingUrl) {
        content.setLandingUrl(account.defaultNotificationLandingUrl)
    }

    // is an ecomm notification
    if (isCampaignLevel && state.showEcommStrategy) {
        content.setLandingUrl('{{item.url}}')
        content.setTitle('{{item.title}}')
        content.setBody('{{item.description | default: ""}}')
        content.setImageUrl('{{item.image}}')
    }

    const expOpts = content.getExperienceOptions()
    // pass null (ogr context) to use global defaults
    const ttlSet = getVariantTtlConfig(isOrgLevel ? account : domain, expOpts)
    expOpts.setTtlSeconds(ttlSet.seconds)
    expOpts.setTtlMetric(ttlSet.metric)

    const delivery = NotificationDeliveryModel.build({})
    if (isDomainLevel) {
        const defaultType = domain.displayMeta.default_delivery_type
        const defaultWindow = domain.displayMeta.default_delivery_window

        delivery.setType(defaultType ?? NotificationDeliveryType.IMMEDIATE)
        delivery.setWindow(defaultWindow ?? NotificationDeliveryWindow.STANDARD)

        // ensure a default date/time and time zone are set for
        //  domains with a non-imm/std default combination
        if (defaultType === NotificationDeliveryType.SCHEDULED) {
            const now = moment()
            if (defaultWindow === NotificationDeliveryWindow.INFORMED) {
                now.hour(0).minute(0)
            }

            delivery.setSendDate(now.toISOString())

            if (defaultWindow !== NotificationDeliveryWindow.TIMEZONE) {
                delivery.setTimeZone(domain.timezone)
            }
        }
    }
    variant.setDelivery(delivery)

    return variant
}

const NotificationBuilder = (props: INotificationBuilder) => {
    const {
        className,
        domain,
        account,
        notifications,
        onChange,
        showWebhookOptions,
        showEcommStrategy,
        defaultEcommStrategy,
    } = props

    const appState = useService(AppState)
    const appSvc = useService(AppService)
    const accountSvc = useService(AccountService)
    const [_mounted, runIfMounted] = useMountedRef()

    const mode = props.mode === 'edit' ? 'edit' : 'create'
    const type = props.type === 'template' ? 'template' : 'notification'

    const theme = props.theme ?? NotificationBuilderTheme.STANDARD
    const themeClassName = theme === NotificationBuilderTheme.MOBILE ? 'mobile' : 'standard'

    const level = props.level ?? NotificationBuilderLevel.DOMAIN
    const isOrgLevel = level === NotificationBuilderLevel.ORG
    const isDomainLevel = level === NotificationBuilderLevel.DOMAIN
    const isCampaignLevel = level === NotificationBuilderLevel.CAMPAIGN
    const levelClassName = isCampaignLevel ? 'campaign' : isOrgLevel ? 'org' : 'domain'

    let initialChannels = isDomainLevel
        ? getEnabledDeliveryChannels(domain, true)
        : account
        ? getAccountEnabledDeliveryChannels(account!)
        : []

    if (props.defaultChannels) {
        initialChannels = props.defaultChannels
    }

    const channelStateContainer = isOrgLevel ? 'org' : 'domain'
    const channelStateContainerId = isOrgLevel ? account?.id : domain.id
    if (channelStateContainerId && !isCampaignLevel) {
        const userChannelsState = appSvc.getUserNotifChannelState(channelStateContainer, channelStateContainerId)

        // TODO: remove !isCampaignLevel once channels are fully implemented in campaigns
        if (mode === 'create' && userChannelsState && !isCampaignLevel && type !== 'template') {
            initialChannels = userChannelsState
        }
    }

    const initialState: INotificationBuilderState = {
        theme,
        level,
        mode,
        type,
        availableDomainsLoaded: false,
        availableDomains: [],
        domain,
        org: account,
        loading: true,
        initialNotificationStateLoaded: false,
        initialNotificationState: [],
        availableSegmentsLoaded: false,
        availableSegments: [],
        reachEstimateLoaded: false,
        test: undefined,
        variants: [],
        channels: initialChannels,
        details: { name: '' },
        initialTemplatesStateLoaded: false,
        availableTemplates: [],
        selectedTemplate: undefined,
        usingTemplate: false,
        selectedVariantIdx: 0,
        showWebhookOptions,
        showEcommStrategy,
        ecommItemPickStrategy: !showEcommStrategy ? undefined : defaultEcommStrategy ?? EcommItemPickStrategy.RANDOM,
    }

    const [value, _dispatch] = React.useReducer(
        getBuilderStateReducer,
        initialState,
        getBuilderStateInitializer(domain, account),
    )

    const dispatch = getActionDispatcher(_dispatch, onChange)

    useEffect(() => {
        const loadAvailableDomains = async () => {
            if (isOrgLevel) {
                const accountDomains = await accountSvc.fetchAccountDomains(account!.id, { pagination: 0 })
                dispatch({ type: 'patch', entity: 'available-domains', data: accountDomains })
            } else {
                dispatch({ type: 'patch', entity: 'available-domains', data: [domain] })
            }
        }

        if (!value.availableDomainsLoaded) {
            loadAvailableDomains()
        }
    }, [level, value.org?.id, domain.id, value.availableDomainsLoaded])

    useRefEffect(() => {
        const resolvePromisableProps = async () => {
            let initialUpdate: Partial<INotificationBuilderState> = {
                loading: false,
            }

            const notifUpdate = await resolveNotificationsProp(
                domain,
                notifications,
                workingValue,
                props.defaultSelectedNotifId,
            )
            initialUpdate = { ...initialUpdate, ...notifUpdate }

            if (mode === 'create') {
                const templateUpdate = await resolveTemplatesProp(domain, workingValue, props.defaultSelectedTemplateId)
                initialUpdate = { ...initialUpdate, ...templateUpdate }
            }

            return runIfMounted(() => {
                dispatch({ type: 'patch', entity: 'root', data: initialUpdate })
            })
        }

        return resolvePromisableProps()
    }, [domain.id])

    const workingValue = clone(value)
    const variants = workingValue.variants

    const variantManager = !workingValue.test ? undefined : (
        <VariantManager
            loading={workingValue.loading}
            value={variants}
            selectedIndex={workingValue.selectedVariantIdx}
            onAdd={async (v: NotificationVariantModel) => {
                if (await validateVariantSwitch(workingValue, domain)) {
                    const update = addVariant(v, workingValue)
                    dispatch({
                        type: 'patch',
                        entity: 'root',
                        data: {
                            test: update.test,
                            variants: update.variants,
                            selectedVariantIdx: update.variants.length - 1,
                        },
                    })
                }
            }}
            onRemove={(v, idx, selectedIdx) => {
                const update = rmVariant(idx, workingValue)
                dispatch({
                    type: 'patch',
                    entity: 'root',
                    data: {
                        test: update.test,
                        variants: update.variants,
                        selectedVariantIdx: selectedIdx,
                    },
                })
            }}
            onSelectedIndexChange={async (idx) => {
                if (await validateVariantSwitch(workingValue, domain)) {
                    dispatch({
                        type: 'patch',
                        entity: 'root',
                        data: {
                            selectedVariantIdx: idx,
                        },
                    })
                }
            }}
        />
    )

    const onTestChange = React.useCallback((test: NotificationTestConfigurationModel) => {
        dispatch({ type: 'patch', entity: 'test', data: test })
    }, [])

    const onChannelChange = React.useCallback((channels: DeliveryChannel[]) => {
        dispatch({ type: 'patch', entity: 'channel', data: channels })
        appSvc.setUserNotifChannelState(isDomainLevel ? 'domain' : 'org', isDomainLevel ? domain : account!, channels)
    }, [])

    const onDetailsChange = React.useCallback((change: TemplateDetails) => {
        dispatch({ type: 'patch', entity: 'detail', data: change })
    }, [])

    const onAudienceChange = React.useCallback(
        (audience: NotificationAudienceModel) => {
            dispatch({ type: 'patch', entity: 'audience', data: { audience, domain } })
        },
        [domain.id],
    )

    const onDistributionChange = React.useCallback((builder: INotificationBuilderState) => {
        dispatch({ type: 'patch', entity: 'root', data: builder })
    }, [])

    const onVariantChange = React.useCallback((variant: NotificationVariantModel, id: number) => {
        dispatch({ type: 'patch', entity: 'variant', data: { id, variant } })
    }, [])

    const onSelectedTemplateChange = React.useCallback((change: { id: number; domain: DomainDto }) => {
        dispatch({ type: 'patch', entity: 'selected-template', data: change })
    }, [])

    const customRenderProps: INotificationBuilderCustomRenderProps = {
        builder: workingValue,
        dispatchChanges: dispatch,
        onChannelChange,
        onDetailsChange,
        onTestChange,
        onAudienceChange,
        variants,
        variantManager,
        onDistributionChange,
        onVariantChange,
        onSelectedTemplateChange,
        validateSubmit,
    }

    return (
        <div
            className={getClassNames(
                'notification-builder',
                className,
                `mode-${mode}`,
                `theme-${themeClassName}`,
                `level-${levelClassName}`,
            )}
        >
            {props.children(customRenderProps)}
        </div>
    )
}

export default NotificationBuilder
