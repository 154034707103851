import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'
import './styles/app-message-builder-aside.scss'
import { Sticky } from '../../sticky/sticky'

type BuilderAside = {
    className?: string
    ref?: any
}

export const AppMessageBuilderAside: React.FC<React.PropsWithChildren<React.PropsWithChildren<BuilderAside>>> = (
    props,
) => {
    const { className, ref, children } = props

    return (
        <div className={getClassNames('app-message-builder-aside-wrapper')}>
            <Sticky threshold={28}>
                <div className={getClassNames('app-message-builder-aside', className)} ref={ref}>
                    {children}
                </div>
            </Sticky>
        </div>
    )
}
