import * as React from 'react'
import { observer } from 'mobx-react'
import { BetterComponent } from '../better-component/better-component'
// import { Well } from '../well/well'
import '../../styles/globals.scss'
import '../../features/pre-auth/login/login.scss'
import '../../styles/503.scss'
import { Well } from '@pushly/aqe/lib/components'

interface IProps {}

interface IState {}

@observer
export class Maintenance503 extends BetterComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props)
    }

    public render() {
        const message =
            process.env.MAINT_MESSAGE?.trim() ||
            'We are currently experiencing issues and our team is actively investigating.'

        return (
            <div id="pufferfish" className="mode-503 maintenance-mode">
                <div className="login-component">
                    <div className="mode-503-wrapper">
                        <Well mode="ghost" hideHeader={true} hideFooter={true}>
                            <div className="mode-503-content">
                                <div className="header">{message}</div>
                                <div className="sub-header">We'll be back up as soon as possible.</div>
                            </div>
                        </Well>
                    </div>
                </div>
            </div>
        )
    }
}
