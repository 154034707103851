import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import moment from 'moment-timezone'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DEFAULT_HIGHCHARTS_CONFIG, INSIGHTS_CLICKS_COLOR } from '../../../constants'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'

interface ISASGProps {
    stats: any[]
    timeBreakdown: string
}

export const SegmentAudienceSizeGraph: React.FC<React.PropsWithChildren<ISASGProps>> = ({
    stats,
    timeBreakdown,
    ...props
}: ISASGProps) => {
    if (!stats) {
        stats = []
    }

    const xAxisData = stats.map((stat) =>
        moment
            .tz((stat as any).send_date, 'UTC')
            .startOf(timeBreakdown as any)
            .format('MMM D'),
    )

    const subscribedData = stats.map((stat) => stat.size || 0)

    const options = {
        ...DEFAULT_HIGHCHARTS_CONFIG,
        swContext: () => props,
        chart: {
            type: 'spline',
            backgroundColor: 'transparent',
            height: 300,
            // zoomType: 'x',
        },
        title: {
            text: '',
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, 'rgba(96, 195, 172, 0.75)'],
                        [1, 'rgba(96, 195, 172, 0)'],
                    ],
                },
                marker: {
                    radius: 2,
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1,
                    },
                },
                threshold: null,
            },
        },
        xAxis: {
            categories: xAxisData,
            title: { enabled: false },
            tickInterval: 1,
            minPadding: 0.065,
            maxPadding: 0.065,
        },
        yAxis: [
            {
                title: { text: '' },
                allowDecimals: false,
                startOnTick: 0,
                min: 0,
            },
        ],
        tooltip: {
            formatter: HighchartsUtils.defaultTooltipFormatter,
            pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
            shared: true,
        },
        series: [
            {
                type: 'area',
                name: 'Segment Size',
                data: subscribedData,
                color: 'rgb(96, 195, 172)',
                tooltip: {
                    pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                },
            },
        ],
    }

    return (
        <Well
            className="thin primary-stats-well subscriber-stats-well"
            title="Segment Size"
            hideFooter={true}
            mode="ghost"
        >
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Well>
    )
}
