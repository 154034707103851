import * as React from 'react'
import { Table, Popover } from 'antd'

const CharacterLimitTooltip: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<any>>> = ({
    className,
    children,
}) => {
    const data = [
        {
            key: '1',
            platform: 'Mobile',
            title: '~50',
            body: '~150',
        },
        {
            key: '2',
            platform: 'Windows: Chrome',
            title: '~40',
            body: '~0-150',
        },
        {
            key: '3',
            platform: 'Windows: Firefox',
            title: '~40',
            body: '~140-190',
        },
        {
            key: '4',
            platform: 'Mac OS: Chrome, Firefox',
            title: '~20-40',
            body: '~20-50',
        },
    ]

    const columns = ['Platform', 'Title', 'Body']
    const columnDefinitions = columns.map((col: string) => ({
        title: col,
        dataIndex: col.toLowerCase(),
    }))

    return (
        <Popover
            overlayClassName={className}
            content={<Table size="small" dataSource={data} columns={columnDefinitions} pagination={false} />}
            trigger="click"
        >
            {children}
        </Popover>
    )
}

export default React.memo(CharacterLimitTooltip)
