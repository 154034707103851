import * as React from 'react'
import './org-notification-audience-badge.scss'
import { IBadgeProps } from './interfaces'
import { TeamOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { SwLink } from '../sw-link/sw-link'
import { OrgNotificationModel } from '../../models/notification/org-notification.model'
import { useService } from '../../hooks/use-service'
import { AppService } from '../../services'
import { AppState } from '../../stores/app'
import { flatMap } from '../../_utils/array'
import { AppActionContext } from '../../enums/app-action-context'

interface IProps extends IBadgeProps {
    source: OrgNotificationModel
}

const EmptyWrapperNode = ({ children }: any) => <>{children}</>
const alphaSort = (a: string, b: string, caseSensitive = false) => {
    a = !caseSensitive ? a.trim().toLowerCase() : a.trim()
    b = !caseSensitive ? b.trim().toLowerCase() : b.trim()
    return a > b ? 1 : a < b ? -1 : 0
}

function getUniqueSegmentPartials<T extends any>(segments: T[]): T[] {
    return Array.from(new Set(segments.map((s: any) => s.id))).map((id) => {
        const segment: any = segments.find((s: any) => s.id === id)!

        return {
            id,
            name: segment.name,
            isDefault: segment.isDefault,
        }
    }) as T[]
}

const OrgNotificationAudienceBadge: React.FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<IProps>>
> = ({ source, hideTooltip, ...props }) => {
    const { currentUserDomains } = useService<AppState>(AppState)
    const appState: AppState = useService<AppState>(AppState)
    const appSvc: AppService = useService<AppService>(AppService)

    const WrapperNode = hideTooltip ? EmptyWrapperNode : Popover

    /**
     * Users with access to 1 domain only
     * should not see "All Domains" verbiage
     * leading to the notion of multiple domains.
     */
    const userOrgDomains = currentUserDomains ?? []
    const exposeMultiDomain = userOrgDomains.length > 1

    const children = Array.from(source.getNotifications() ?? [])
    children.sort((a, b) => alphaSort(a.domainDisplayName, b.domainDisplayName))

    const allISegments = getUniqueSegmentPartials(
        flatMap(
            children,
            (n) =>
                n.includedSegments?.map((s) => ({
                    id: s.groupId,
                    name: s.groupName,
                    isDefault: s.isDefault,
                })) ?? [],
        ),
    )
    allISegments.sort((a, b) => alphaSort(a.name, b.name))
    const allXSegments = getUniqueSegmentPartials(
        flatMap(
            children,
            (n) =>
                n.excludedSegments?.map((s) => ({
                    id: s.groupId,
                    name: s.groupName,
                })) ?? [],
        ),
    )
    allXSegments.sort((a, b) => alphaSort(a.name, b.name))

    const isMultiDomain = source.getIsMultiDomain() && children.length > 1
    const isAllDomainSend = source.getIsMultiDomain() && children.length === (currentUserDomains?.length ?? 0)
    const isAllSegmentSend = allISegments.length > 0 && allISegments.every((s) => s.isDefault ?? false)
    const isMultiISegment = !isAllSegmentSend && allISegments.length > 1
    const isMultiXSegment = allXSegments.length > 1

    const handleClickContextChange = (ev: any) => {
        if (!ev?.altKey) {
            appState.actionContext = AppActionContext.DOMAIN
        }
    }

    const badgeSet = (
        <span className="included-badge">
            <span className="included-icon">
                <TeamOutlined />
            </span>
            {isAllDomainSend ? (
                <span>{exposeMultiDomain ? 'All Domains' : userOrgDomains[0].displayName}</span>
            ) : (
                <span>
                    {children.length} {isMultiDomain ? 'Domains' : 'Domain'}
                </span>
            )}
        </span>
    )

    return (
        <span className="notification-audience-badge">
            <WrapperNode
                overlayClassName="org-notification-list-audience-popover"
                title="Audience"
                content={
                    <>
                        <div>
                            <b>{isMultiDomain ? 'Domains' : 'Domain'}:</b>
                            {children.map((n) => (
                                <div key={n.domainId}>
                                    <SwLink
                                        href={`/domains/${n.domainId}/notifications`}
                                        onClick={handleClickContextChange}
                                    >
                                        {n.domainDisplayName}
                                    </SwLink>
                                </div>
                            ))}
                        </div>

                        <div>
                            <br />
                            <b>Included {isMultiISegment ? 'Segments' : 'Segment'}:</b>
                            <div>
                                {isAllSegmentSend ? (
                                    <div>All Subscribers</div>
                                ) : (
                                    allISegments.map((s) => (
                                        <div key={s.id}>
                                            <SwLink href={`/organizations/${source.getAccountId()}/segments/${s.id}`}>
                                                {s.name}
                                            </SwLink>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>

                        {allXSegments.length > 0 && (
                            <div>
                                <br />
                                <b>Excluded {isMultiXSegment ? 'Segments' : 'Segment'}:</b>
                                <div>
                                    {allXSegments.map((s) => (
                                        <div key={s.id}>{s.name}</div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                }
            >
                {badgeSet}
            </WrapperNode>
        </span>
    )
}

export default OrgNotificationAudienceBadge
