import * as React from 'react'
import './style/campaign-wizard.scss'
import * as clone from 'clone'
import { TabbedView } from '../tabbed-view/tabbed-view'
import { getClassNames } from '../../_utils/classnames'
import { CampaignConfigurationTab } from './tabs/campaign-configuration.tab'
import { CampaignBuilderTab } from './tabs/campaign-builder.tab'
import { ICampaignWizard } from './interfaces'
import { CampaignModel } from '../../models/campaign/campaign.model'
import { AppState } from '../../stores/app'
import { CampaignV2Service } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { CampaignBuilderMode } from '../campaign-builder/enums'
import { StatusType } from '../../enums/status-type'
import { simpleNotification } from '../../_utils/utils'
import { DeliveryChannel } from '@pushly/models/lib/enums/delivery-channels.enum'
import { NodeType } from '../../features/journeys/enums'
import { Journey } from '../../features/journeys/types/journey-api-response'

interface IState {
    tabs: any[]
    disableRouterPrompt?: boolean
}

export class CampaignWizard extends TabbedView<ICampaignWizard, IState> {
    public state: IState = {
        tabs: [],
    }

    protected animated = false

    protected readonly appState: AppState
    protected readonly campaignService: CampaignV2Service

    public constructor(props: ICampaignWizard) {
        super(props)

        this.appState = Container.get(AppState)
        this.campaignService = Container.get(CampaignV2Service)
    }

    public componentDidMount() {
        this.loadTabsState().then()
    }

    public componentDidUpdate(prevProps: ICampaignWizard) {
        // ensure campaign reload resets tabs
        if (!prevProps.campaign && !!this.props.campaign) {
            this.loadTabsState().then()
        }
    }

    public render() {
        return <div className={getClassNames('campaign-wizard')}>{this.renderTabs()}</div>
    }

    protected async loadTabsState(): Promise<void> {
        const tabs = [
            {
                component: CampaignConfigurationTab,
                props: () => ({
                    ...this.props,
                    onChange: () => {},
                    onSubmit: this.handleConfigSubmit,
                    onSave: this.handleConfigSave,
                    disableRouterPrompt: this.state.disableRouterPrompt,
                }),
            },
            {
                disabled: this.props.mode === CampaignBuilderMode.CREATE,
                component: CampaignBuilderTab,
                props: () => ({
                    ...this.props,
                    onSubmit: this.handleBuilderSubmit,
                    disableRouterPrompt: this.state.disableRouterPrompt,
                }),
            },
        ]

        const activeTab = this.determineActiveTab(tabs)

        return this.setState({
            tabs,
            activeTab,
        })
    }

    protected handleConfigSubmit = async (campaign: CampaignModel) => {
        const res = await this.saveConfiguration(campaign)
        if (res.ok) {
            await this.setState({ disableRouterPrompt: true })

            if (this.props.mode === CampaignBuilderMode.CREATE) {
                this.appService.routeWithinDomain(`/campaigns/${res.data.id}/builder${location.search}`)
            } else {
                await this.handleTabChange(CampaignBuilderTab.tabName)
            }

            this.setState({ disableRouterPrompt: false })
        }

        return res
    }

    protected handleConfigSave = async (campaign: CampaignModel) => {
        return this.saveConfiguration(campaign)
    }

    protected handleBuilderSubmit = async (campaign: Journey, remove?: boolean) => {
        const statusId = this.props.campaign.statusId
        const res = await this.saveSteps(campaign, remove)

        if (res.ok) {
            if (statusId === StatusType.DRAFT.id) {
            }

            await this.setState({ disableRouterPrompt: true })
            this.appService.routeWithinDomain('/campaigns')
        }

        return res
    }

    protected saveConfiguration = async (campaign: CampaignModel) => {
        let res: any = { ok: false }

        const opts = {
            showLoadingScreen: true,
            cancellationKey: 'cwiz.config.patch',
        }

        const data = campaign.serialize()
        // remove revision data from config update
        delete data.revisions
        delete data.revision

        // remove unecessary columns
        delete data.start_date_utc
        delete data.end_date_utc
        delete data.date_completed_utc
        delete data.manually_completed

        // legacy behavior defaults to STZ time zone
        data.date_time_zone = data.date_time_zone ?? 'STZ'

        if (this.props.mode === CampaignBuilderMode.CREATE) {
            // initial status via UI is SETUP
            data.status = StatusType.DRAFT.name
            data.type = data.campaign_type

            // persist initial trigger -> exit
            data.steps = campaign
                .getCurrentRevision()
                ?.getSteps()
                ?.map((s) => s.serialize())

            data.steps.forEach((step) => {
                if (step.type === NodeType.ACTION) {
                    const stepConfig = clone(step.configuration ?? {})
                    const hasNotifBlock = !!stepConfig?.params?.notification

                    if (hasNotifBlock && stepConfig.params.notification?.channels) {
                        const channels = stepConfig.params.notification?.channels
                        const { default: defaults } = stepConfig.params.notification.template.channels
                        const newTemplate: any = {
                            default: defaults,
                        }

                        // re-assign new steps
                        for (const channel of channels) {
                            if (channel) {
                                const chKeyFormatted = channel.toLowerCase()
                                newTemplate[chKeyFormatted] =
                                    stepConfig.params.notification.template.channels[chKeyFormatted]

                                // strip actions when defaults should be applied for action enabled templates
                                if (channel === DeliveryChannel.WEB || channel === DeliveryChannel.NATIVE_ANDROID) {
                                    if (newTemplate[chKeyFormatted]?.displayMeta?.override_default_actions === false) {
                                        if (newTemplate[chKeyFormatted].actions) {
                                            delete newTemplate[chKeyFormatted].actions
                                        }

                                        // UI has an all or nothing actions control - all actions are default is not overridden
                                        if (newTemplate.default.actions) {
                                            delete newTemplate.default.actions
                                        }
                                    }
                                }
                            }
                        }

                        stepConfig.params.notification.template.channels = newTemplate

                        step.configuration = stepConfig
                    }
                }
            })

            res = await this.campaignService.create(data, opts)
        } else if (data.id) {
            // pass null to remove end date value
            data.date_end = data.date_end ?? null

            // persist trigger changes
            data.steps = campaign
                .getCurrentRevision()
                ?.getSteps()
                ?.map((s) => s.serialize())

            // ensure un-edited action steps send notification_id only
            // this can only occur during config-edit
            data.steps.forEach((step) => {
                if (step.type === NodeType.ACTION) {
                    const stepConfig = clone(step.configuration ?? {})
                    const hasNotifId = !!stepConfig?.params?.notification_id
                    const hasNotifBlock = !!stepConfig?.params?.notification

                    if (hasNotifId && hasNotifBlock) {
                        delete stepConfig.params.notification
                        step.configuration = stepConfig
                    }
                }
            })

            res = await this.campaignService.patch(data.id, data, opts)
        }

        if (res.ok) {
            if (this.props.mode !== CampaignBuilderMode.CREATE) {
                simpleNotification('success', `Campaign, ${data.name}, was successfully saved.`)
            }

            this.props.onSubmit?.(res.data)
        }

        return res
    }

    protected saveSteps = async (campaign: Journey, remove?: boolean) => {
        const opts = {
            showLoadingScreen: true,
            cancellationKey: 'cwiz.builder.patch',
        }

        const exitStep = campaign.steps.find((s) => s.type === NodeType.EXIT)
        const globalTriggers = this.props.campaign?.steps?.filter((s) => s.type === NodeType.GLOBAL_TRIGGER) ?? []
        if (globalTriggers.length > 0) {
            // reset out_step_id to current exitStep
            globalTriggers.forEach((trigger) => {
                if (trigger.configuration.out_step_id !== exitStep?.id) {
                    trigger.configuration.out_step_id = exitStep?.id
                }

                const tIdx = campaign.steps.findIndex((step) => step.id.toString() === trigger.id.toString())
                if (tIdx !== -1) {
                    campaign.steps.splice(tIdx, 1, trigger)
                } else {
                    campaign.steps.push(trigger)
                }
            })
        }

        // clean up notifications for DTO post/patch
        campaign.steps.forEach((step) => {
            if (step.type === NodeType.ACTION) {
                const notif = clone(step.configuration.params.notification)

                if (notif) {
                    // ensure notif id is set to update
                    step.configuration.params.notification_id = notif.id

                    // remove id so new template is created
                    if (notif.template?.channels?.default?.id) {
                        const channels = notif.channels
                        delete notif.template.channels.default.id
                        const { default: defaults } = notif.template.channels

                        const newTemplate: any = {
                            default: defaults,
                        }

                        // re-assign new steps by selected channels
                        for (const channel of channels) {
                            if (channel) {
                                const chKeyFormatted = channel.toLowerCase()
                                newTemplate[chKeyFormatted] = notif.template.channels[chKeyFormatted]

                                // strip actions when defaults should be applied for action enabled templates
                                if (channel === DeliveryChannel.WEB || channel === DeliveryChannel.NATIVE_ANDROID) {
                                    if (newTemplate[chKeyFormatted]?.displayMeta?.override_default_actions === false) {
                                        if (newTemplate[chKeyFormatted].actions) {
                                            delete newTemplate[chKeyFormatted].actions
                                        }

                                        // UI has an all or nothing actions control - all actions are default is not overridden
                                        if (newTemplate.default.actions) {
                                            delete newTemplate.default.actions
                                        }
                                    }
                                }
                            }
                        }

                        notif.template.channels = newTemplate
                    }

                    step.configuration.params.notification = notif
                }
            }
        })

        const data: any = {
            steps: campaign.steps,
        }

        if (campaign.status === StatusType.DRAFT.name) {
            data.status = StatusType.ACTIVE.name
        }

        data.remove = remove ? remove : false

        const res = await this.campaignService.patch(campaign.id, data, opts)

        if (res.ok) {
            if (this.props.campaign.statusId === StatusType.DRAFT.id) {
                simpleNotification('success', `Campaign setup for ${campaign.name} was successfully completed.`)
            } else {
                simpleNotification('success', `Campaign, ${campaign.name}, was successfully saved.`)
            }

            this.props.onSubmit?.(res.data)
        }

        return res
    }
}
