import TemplatizedTextField, { InputFocusRef } from '../../templatized-text-field/templatized-text-field'
import { getClassNames } from '../../../_utils/classnames'
import ColorPickerPopover from '../../color-picker-popover/color-picker-popover'
import { Button, Input, Radio, Select, Switch, Tooltip } from 'antd'
import colorConvert from 'color-convert'
import * as React from 'react'
import { useRef } from 'react'
import {
    AppMessageTemplateButtonConfig,
    MutableAppMessageTemplateButtonConfig,
} from '@pushly/models/lib/structs/app-messages/app-message-template-button-config'
import { CtaButtonType, CtaGroupTuple } from '../types'
import { titleCase } from '../../../_utils/utils'
import { AppMessageActionType } from '@pushly/models/lib/enums/app-messages/app-message-action-type'
import { BgColorsOutlined, FontColorsOutlined } from '@ant-design/icons'
import { MacroType } from '../../macro-manager/types'
import { DEFAULT_CTA_BG_COLOR, DEFAULT_CTA_FG_COLOR } from '../constants'
import { enumAsSelectOptions } from '../../../_utils/antd'
import { AppMessageActionStyle } from '@pushly/models/lib/enums/app-messages/app-message-action-style'
import { AppMessageTemplateStyle } from '@pushly/models/lib/enums'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { Position } from '@pushly/models/lib/enums/position'
import { MutableAppMessageTemplateImageConfig } from '@pushly/models/lib/structs'
import { ConditionalTooltip } from '../../conditional-popover/conditional-popover'
import { MacroManager } from '@pushly/aqe/lib/components/macro-manager/macro-manager'

type AppMessageCtaBuilderProps = {
    value: CtaGroupTuple
    type: CtaButtonType
    rootCtas: CtaGroupTuple[]
    templateStyle: AppMessageTemplateStyle
    onChange: (change: Partial<AppMessageTemplateButtonConfig>, action: CtaButtonType) => void
    disabled: boolean
}

export const AppMessageCtaBuilder = (props: AppMessageCtaBuilderProps) => {
    const { disabled, value, rootCtas, templateStyle } = props

    const [context, dispatch] = useAppMessageBuilderContext()
    const templateValue = context.variants[context.selectedVariantIdx].template

    // 2nd action not available for banners
    if (props.type === CtaButtonType.SECONDARY && templateStyle === AppMessageTemplateStyle.BANNER) return null

    const labelRef = useRef<InputFocusRef>(null)

    const handleChange = (change: Partial<AppMessageTemplateButtonConfig>) => {
        // cannot disable Primary when Secondary contains a value
        if ('enabled' in change && props.type === CtaButtonType.PRIMARY && !change.enabled && rootCtas[1][1].enabled) {
            return
        }

        props.onChange(change, props.type)
    }

    const localValue = value[1]
    const opposingAction = rootCtas[props.type === CtaButtonType.PRIMARY ? 0 : 1][1]
    const defaultValue = localValue.enabled
        ? localValue
        : ({
              enabled: true,
              fgColor: DEFAULT_CTA_FG_COLOR,
              bgColor: DEFAULT_CTA_BG_COLOR,
              action: opposingAction.enabled
                  ? opposingAction.action === AppMessageActionType.CLOSE
                      ? AppMessageActionType.OPEN_URL
                      : AppMessageActionType.CLOSE
                  : AppMessageActionType.OPEN_URL,
          } as MutableAppMessageTemplateButtonConfig)

    const [siblingCtaTuple] = rootCtas
        .filter(([type, _]) => {
            return type !== props.type
        })
        .map(([_, cta]) => cta)

    const utmMacroTypes: MacroType[] = ['domain', 'device', 'location', 'app_message', 'custom']

    const isBannerButtonUnavailable =
        templateValue &&
        templateValue.style === AppMessageTemplateStyle.BANNER &&
        templateValue?.image.enabled &&
        templateValue.image.position !== Position.LEFT

    return (
        <div className={getClassNames('app-message-cta-builder', {})}>
            <div className="switch-row">
                <ConditionalTooltip
                    title="A button cannot be enabled on a Banner Message when the Image is Position Right. Please select Position Left
                    first to enabled a button"
                    condition={isBannerButtonUnavailable ?? false}
                >
                    <Tooltip
                        title={
                            props.type === CtaButtonType.PRIMARY && rootCtas[1][1].enabled
                                ? 'Primary Button cannot be disabled while a Secondary button is enabled.'
                                : ''
                        }
                    >
                        <label>
                            <Switch
                                size="small"
                                onChange={(enabled) => {
                                    if (enabled) {
                                        if (templateStyle === AppMessageTemplateStyle.BANNER) {
                                            const ctaKey = props.type === 'PRIMARY' ? 'ctaPrimary' : 'ctaSecondary'

                                            const update: {
                                                image: MutableAppMessageTemplateImageConfig
                                                [ctaKey: string]: MutableAppMessageTemplateButtonConfig
                                            } = {
                                                image: templateValue!.image.clone(),
                                                [ctaKey]: templateValue![ctaKey].clone({ enabled }),
                                            }
                                            if (!localValue.style) {
                                                update[ctaKey].style = AppMessageActionStyle.TAB
                                            }

                                            if (!localValue.label) {
                                                update[ctaKey].label = '›'
                                            }

                                            if (templateValue?.image.enabled && templateValue.image.position) {
                                                update.image.clone({ position: Position.LEFT })
                                            }

                                            dispatch({
                                                type: 'patch',
                                                entity: 'template',
                                                data: {
                                                    image: update.image,
                                                    [ctaKey]: update[ctaKey],
                                                },
                                            })
                                        } else {
                                            handleChange({ ...defaultValue, enabled })
                                        }

                                        if (labelRef.current) {
                                            labelRef?.current.focusInput()
                                        }
                                    } else {
                                        // reset button
                                        handleChange({
                                            enabled,
                                        })
                                    }
                                }}
                                checked={localValue.enabled}
                                disabled={
                                    (props.type === CtaButtonType.PRIMARY && isBannerButtonUnavailable) ||
                                    (props.type === CtaButtonType.SECONDARY && !rootCtas[0][1].enabled) ||
                                    disabled
                                }
                            />
                            <span className="label-with-component-l">{titleCase(props.type)} Button</span>
                        </label>
                    </Tooltip>
                </ConditionalTooltip>
            </div>

            <div className="app-message-action">
                <div className="app-message-btn-style-row">
                    <span className="label-with-component-r">Style</span>
                    {templateStyle === AppMessageTemplateStyle.BANNER && (
                        <div className="btn-style-value">
                            <Radio.Group
                                buttonStyle="solid"
                                size="small"
                                disabled={!localValue.enabled || disabled}
                                value={localValue.style}
                                onChange={(ev) => {
                                    handleChange({ style: ev.target.value })
                                }}
                            >
                                {enumAsSelectOptions(AppMessageActionStyle).map(({ value: style, label }) => (
                                    <Radio.Button key={style} value={style}>
                                        {label}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </div>
                    )}

                    <div className="btn-style-color-pickers">
                        <ColorPickerPopover
                            className="color-popover-btn"
                            content={
                                <Tooltip title="Font Color" trigger="hover">
                                    <Button
                                        disabled={!localValue.enabled || disabled}
                                        style={{
                                            color:
                                                colorConvert.hex.hsl(localValue.fgColor ?? defaultValue.fgColor!)?.[2] <
                                                70
                                                    ? 'white'
                                                    : 'black' ?? 'black',
                                        }}
                                    >
                                        <span
                                            className="color-wrap"
                                            style={{
                                                backgroundColor: localValue.fgColor ?? defaultValue.fgColor,
                                            }}
                                        >
                                            <FontColorsOutlined />
                                        </span>
                                    </Button>
                                </Tooltip>
                            }
                            disabled={!localValue.enabled || disabled}
                            defaultColor={defaultValue.fgColor!}
                            color={localValue.fgColor ?? defaultValue.fgColor!}
                            onChange={(color) => {
                                handleChange({ fgColor: color!.toString() })
                            }}
                        />
                        <ColorPickerPopover
                            className="color-popover-btn"
                            content={
                                <Tooltip title="Background Color" trigger="hover">
                                    <Button
                                        disabled={!localValue.enabled || disabled}
                                        style={{
                                            color:
                                                colorConvert.hex.hsl(localValue.bgColor ?? defaultValue.bgColor!)?.[2] <
                                                70
                                                    ? 'white'
                                                    : 'black' ?? 'black',
                                        }}
                                    >
                                        <span
                                            className="color-wrap"
                                            style={{
                                                backgroundColor: localValue.bgColor ?? defaultValue.bgColor,
                                            }}
                                        >
                                            <BgColorsOutlined />
                                        </span>
                                    </Button>
                                </Tooltip>
                            }
                            disabled={!localValue.enabled || disabled}
                            defaultColor={defaultValue.bgColor!}
                            color={localValue.bgColor ?? defaultValue.bgColor!}
                            onChange={(color) => {
                                handleChange({ bgColor: color!.toString() })
                            }}
                        />
                    </div>
                </div>

                {templateStyle !== AppMessageTemplateStyle.BANNER && (
                    <div className="app-message-cta-label">
                        <TemplatizedTextField
                            ref={labelRef}
                            className={getClassNames(null)}
                            value={localValue.label}
                            disabled={!localValue.enabled || disabled}
                            onChange={(label) => {
                                handleChange({ label })
                            }}
                            placeholder={`${titleCase(props.type)} Call-to-Action`}
                        />
                    </div>
                )}

                <div className="action-type-select">
                    <Input.Group compact={true} size="default">
                        <span
                            className={getClassNames(null, 'ant-input-group-addon', {
                                disabled: !localValue.enabled || disabled,
                            })}
                        >
                            Action
                        </span>
                        <Select<AppMessageActionType>
                            disabled={!localValue.enabled || disabled}
                            value={localValue.action ?? AppMessageActionType.OPEN_URL}
                            defaultValue={AppMessageActionType.OPEN_URL}
                            onChange={(action) => handleChange({ action })}
                        >
                            <Select.Option value={AppMessageActionType.OPEN_URL}>Open URL</Select.Option>
                            {templateStyle !== AppMessageTemplateStyle.BANNER && (
                                <Select.Option
                                    value={AppMessageActionType.CLOSE}
                                    disabled={siblingCtaTuple.action === AppMessageActionType.CLOSE}
                                >
                                    Dismiss
                                </Select.Option>
                            )}
                            <Select.Option
                                value={AppMessageActionType.PROMPT_NOTIFICATIONS}
                                disabled={siblingCtaTuple.action === AppMessageActionType.PROMPT_NOTIFICATIONS}
                            >
                                Prompt For Notification Permissions
                            </Select.Option>
                        </Select>
                    </Input.Group>
                </div>

                {localValue.action === AppMessageActionType.OPEN_URL && (
                    <div className="action-type-url">
                        <MacroManager disabled={!localValue.enabled} types={utmMacroTypes}>
                            <Input
                                className={getClassNames(null, 'url-input-group', {
                                    disabled: !localValue.enabled || disabled,
                                })}
                                placeholder="app://..."
                                addonBefore="URL"
                                disabled={!localValue.enabled || disabled}
                                value={localValue.url}
                                onChange={(ev) => handleChange({ url: ev.target.value })}
                            />
                        </MacroManager>
                    </div>
                )}
            </div>
        </div>
    )
}
