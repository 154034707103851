import * as React from 'react'
import { DrawerProps } from 'antd/lib/drawer'
import { Drawer as AntDrawer } from 'antd'
import { Well } from '@pushly/aqe/lib/components'

interface IDrawer extends DrawerProps {
    onSubmit?: () => any
    submitText?: string
    disableSubmit?: boolean
    hideFooter?: boolean
    footer?: any
}

export class Drawer extends React.Component<React.PropsWithChildren<IDrawer>> {
    public render() {
        const { onSubmit, submitText, disableSubmit, hideFooter, footer, className, children, ...props } = this.props

        return (
            <AntDrawer {...props} className={`aqe-drawer ${className}`}>
                <Well
                    className="aqe-drawer-outer-well nested"
                    mode="ghost"
                    hideHeader={true}
                    hideCancel={true}
                    onSubmit={onSubmit}
                    submitText={submitText}
                    disableSubmit={disableSubmit}
                    hideFooter={hideFooter}
                    footer={footer}
                >
                    {children}
                </Well>
            </AntDrawer>
        )
    }
}
