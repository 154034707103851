import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import './lower-drawer.scss'
import autobind from 'autobind-decorator'
import { observer } from 'mobx-react'
import * as randomstring from 'randomstring'
import { DownCircleFilled } from '@ant-design/icons'

interface ILowerDrawerProps {
    className?: string
    open?: boolean
    onClose: (open: boolean) => any
}

interface ILowerDrawerState {}

@observer
export class LowerDrawer extends BetterComponent<React.PropsWithChildren<ILowerDrawerProps>, ILowerDrawerState> {
    public readonly defaultClassName: string = 'sw-mv-lower-drawer'
    public readonly eid: string

    protected appState: AppState
    protected appService: AppService

    public constructor(props: ILowerDrawerProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.eid = `${this.defaultClassName}-${randomstring.generate()}`

        this.state = {}
    }

    public render(): React.ReactNode {
        return (
            <div id={this.eid} className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('toggle-wrapper')}>
                        <span className={this.buildClassName('toggle')} onClick={this.close}>
                            <DownCircleFilled />
                        </span>
                    </div>
                    <div className={this.buildClassName('content-wrapper')}>
                        <div className={this.buildClassName('content')}>{this.props.children}</div>
                    </div>
                </div>
            </div>
        )
    }

    public get isOpen(): boolean {
        return !!this.props.open
    }

    @autobind
    protected close(): void {
        this.props.onClose(false)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName, this.isOpen ? 'open' : 'closed']

        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
