import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'
import { ICampaignWizard } from '../interfaces'
import { simpleNotification } from '../../../_utils/utils'
import { CampaignBuilderMode } from '../../campaign-builder/enums'
import { JourneysBuilder } from '../../../features/journeys/journeys-builder'
import { JourneyBuilderMode, NodeType } from '../../../features/journeys/enums'
import { Journey } from '../../../features/journeys/types/journey-api-response'

interface ICampaignBuilderTab extends ICampaignWizard {
    activeTab?: boolean
    disableRouterPrompt?: boolean
}

interface IState {
    tmpValue?: Journey
}

export class CampaignBuilderTab extends React.Component<ICampaignBuilderTab, IState> {
    public static tabName = 'builder'
    public static tabLabel = 'Visual Builder'

    public state: IState = {}

    public componentDidUpdate(prevProps: Readonly<ICampaignBuilderTab>) {
        if (!!prevProps.campaign !== !!this.props.campaign) {
            this.setState({ tmpValue: this.props.campaign })
        }
    }

    public render() {
        const journeyMode =
            this.props.mode === CampaignBuilderMode.CREATE
                ? JourneyBuilderMode.CREATE
                : this.props.mode === CampaignBuilderMode.EDIT
                ? JourneyBuilderMode.EDIT
                : JourneyBuilderMode.READONLY

        return (
            <div className={getClassNames('cwzd-builder')}>
                <JourneysBuilder
                    loading={this.props.loading}
                    domain={this.props.domain}
                    journey={this.currentCampaignState}
                    onChange={this.handleUpdate}
                    onSubmit={this.handleSubmit}
                    mode={journeyMode}
                />
            </div>
        )
    }

    protected get currentCampaignState() {
        return this.state.tmpValue ?? this.props.campaign
    }

    protected validate(campaign: Journey): { ok: boolean; error?: any } {
        const res: any = { ok: true, error: undefined }

        const setNotOk = (reason: string) => {
            res.ok = false
            res.error = reason
        }

        const steps = campaign.steps ?? []
        const trigger = steps.find((s) => s.type === NodeType.TRIGGER)
        const actions = steps.filter((s) => s.type === NodeType.ACTION)

        if (!trigger || !trigger.configuration?.type || !trigger.configuration?.params) {
            setNotOk('Campaign entry conditions have not been set.')
        } else if (actions.length === 0) {
            setNotOk('At least one campaign action is required.')
        }

        return res
    }

    protected handleUpdate = (campaign: Journey) => {
        this.setState({ tmpValue: campaign })
    }

    protected handleSubmit = async (campaign: Journey, remove?: boolean) => {
        if (!campaign && this.state.tmpValue) {
            campaign = this.state.tmpValue
        }

        const res = this.validate(campaign)

        if (!res.ok) {
            simpleNotification('error', res)
        } else {
            const submitRes = this.props.onSubmit?.(campaign, remove)
            if (submitRes.ok) {
                await this.setState({ tmpValue: submitRes.data })
            }

            return submitRes
        }

        return res
    }
}
