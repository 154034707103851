import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Popover, Progress } from 'antd'
import { BetterComponent } from '../better-component/better-component'
import { Translate } from '../translate/translate'
import { AppService, TranslationService } from '../../services/index'
import './password-popover.scss'
import { AppState } from '../../stores/app'
import { PasswordValidators, valueMeetsCharacterReqs } from '../../_utils/password-validations'

interface IProps {
    password?: string
}

interface IState {
    validLength?: boolean
    isSameAsUserName?: boolean
    isSameAsUserEmail?: boolean
    isWeak?: boolean
    score?: string
    isMissingRequiredCharacters?: any
}

enum PasswordStrengthPct {
    VERY_WEAK = 0,
    WEAK = 33,
    REASONABLE = 66,
    STRONG = 88,
    VERY_STRONG = 100,
}

export class PasswordPopover extends BetterComponent<React.PropsWithChildren<IProps>, IState> {
    public state: IState = {}

    private appState: AppState
    private appService: AppService
    private translationService: TranslationService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.translationService = Container.get(TranslationService)
    }

    public UNSAFE_componentWillReceiveProps(newProps: IProps) {
        this.validatePassword(newProps.password)
    }

    public render() {
        return (
            <Popover
                content={this.renderPopoverContent()}
                title="Your password must:"
                trigger="focus"
                placement="right"
                getPopupContainer={this.appService.getAppContainer}
            >
                {this.props.children}
            </Popover>
        )
    }

    public renderPopoverContent() {
        const validIcon = <CheckCircleOutlined className="pw-rq-good" />
        const invalidIcon = <CheckCircleOutlined className="pw-rq-bad" />
        const characterReqsStatus = valueMeetsCharacterReqs(this.props.password!, true)
        const requirementsMet =
            Object.keys(characterReqsStatus ?? {}).filter((req) => characterReqsStatus[req] === true).length >= 3
        const charPhrase = {
            lowercase: 'Lower case letter (a-z)',
            uppercase: ' Upper case letters (A-Z)',
            number: 'Numbers (i.e. 0-9)',
            special: 'Special characters (e.g. !@#$%^&*)',
        }

        const passwordEmpty = !this.props.password

        return (
            <div className="password-popover">
                <div>
                    {!passwordEmpty && this.state.validLength ? validIcon : invalidIcon}{' '}
                    <Translate name="common.passwordPopover.passwordLengthRequirement" />
                </div>
                <div>
                    {!passwordEmpty && this.state.isSameAsUserName ? validIcon : invalidIcon}{' '}
                    <Translate name="common.passwordPopover.passwordNotUserNameRequirement" />
                </div>
                <div>
                    {!passwordEmpty && this.state.isSameAsUserEmail ? validIcon : invalidIcon}{' '}
                    <Translate name="common.passwordPopover.passwordNotUserEmailRequirement" />
                </div>
                <div>
                    {!passwordEmpty && requirementsMet ? validIcon : invalidIcon}{' '}
                    <span>Contains at least 3 of the 4 following character requirements:</span>
                    <ul>
                        {Object.keys(charPhrase).map((val: string, idx) => (
                            <li key={idx}>
                                {!passwordEmpty && characterReqsStatus?.[val] ? validIcon : invalidIcon}{' '}
                                {charPhrase[val]}
                            </li>
                        ))}
                    </ul>
                </div>
                {!passwordEmpty && this.calculatePercent(this.state.score) >= PasswordStrengthPct.REASONABLE
                    ? validIcon
                    : invalidIcon}{' '}
                <Translate name="common.passwordPopover.passwordBeStrong" />
                <div>
                    <Progress percent={this.calculatePercent(this.state.score)} size="small" showInfo={false} />
                    {!passwordEmpty && this.state.score ? `Your password is ${this.state.score}` : null}
                </div>
            </div>
        )
    }

    private calculatePercent(score) {
        let percent = PasswordStrengthPct.VERY_WEAK

        switch (score) {
            case 'very weak':
                percent = PasswordStrengthPct.VERY_WEAK
                break
            case 'weak':
                percent = PasswordStrengthPct.WEAK
                break
            case 'medium':
                percent = PasswordStrengthPct.REASONABLE
                break
            case 'strong':
                percent = PasswordStrengthPct.STRONG
                break
            case 'very strong':
                percent = PasswordStrengthPct.VERY_STRONG
                break
        }

        return percent
    }

    private get passwordValidators() {
        return new PasswordValidators(this.appState.currentUser!)
    }

    private validatePassword(password?: string) {
        const validLength = !this.passwordValidators.tooShort(password)
        const score = this.passwordValidators.score(password)
        const isWeak = this.passwordValidators.isWeak(password)
        const isSameAsUserEmail = !this.passwordValidators.isSameAsUserEmail(password)
        const isSameAsUserName = !this.passwordValidators.isSameAsUserName(password)
        const isMissingRequiredCharacters = !this.passwordValidators.isMissingRequiredCharacters(password)

        this.setState(() => ({
            validLength,
            isWeak,
            score,
            isSameAsUserName,
            isSameAsUserEmail,
            isMissingRequiredCharacters,
        }))
    }
}
