import React, { useEffect, useState } from 'react'
import { PageHeader, Well } from '@pushly/aqe/lib/components'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService, CampaignV2Service } from '../../services'
import { JourneysBuilder } from './journeys-builder'
import { useParams } from 'react-router-dom'
import { AppState } from '../../stores/app'
import { getClassNames } from '../../_utils/classnames'
import './styles/edit-journey.scss'
import { noop } from '../../_utils/utils'

type EditJourneyProps = {}

export const EditJourney = (props: EditJourneyProps) => {
    const appState = useService(AppState)
    const appSvc = useService(AppService)
    const campaignSvc = useService(CampaignV2Service)

    const [loading, setLoading] = React.useState(false)
    const [journey, setJourney] = useState<any | null>(null)

    const { campaignId: journeyId } = useParams<{ campaignId?: string }>()

    // fetch resources
    useEffect(() => {
        const fetchJourney = async (id: string) => {
            setLoading(true)

            const res = await campaignSvc.fetchById(parseInt(id, 10))
            if (res.ok && res.data) {
                setJourney(res.data)
            }

            setLoading(false)
        }

        if (journeyId) {
            fetchJourney(journeyId)
        }
    }, [journeyId])

    return (
        <div className={getClassNames('edit-journey-page')}>
            <PageHeader
                title={`${loading ? 'Loading...' : `Journey: ${journey?.name}`}`}
                append={<span>ID: {journey?.id ?? '...'}</span>}
                onTitleSet={appSvc.customizeTabTitle}
            />
            <Well
                className={getClassNames('journey-builder-well', 'nested')}
                title="Builder"
                hideFooter={true}
                loading={loading}
            >
                {/*{!loading && (*/}
                {/*    <JourneysBuilder domain={appState.currentDomain!} journey={journey} onChange={() => noop} />*/}
                {/*)}*/}
            </Well>
        </div>
    )
}
