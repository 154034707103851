import { RelativeDateDisplayMetric } from '@pushly/models/lib/enums/relative-date-display-metric'
import { NotificationDto } from '../../notifications'
import { NotificationPayload } from './notification-payload'
import { EcommItemPickStrategy } from '../../../enums/ecomm-item-pick-strategy'
import { ActionType, DelayType, GlobalTriggerType, NodeType, TriggerType } from '../enums'
import { Edge, Node } from '@xyflow/react'
import { UIEditTrackable } from '../../../structs/ui-edit-trackable'

// region Journey Nodes / Steps
// region Param Types

export type DelayParams = {
    delay_seconds: number
    qualifier: RelativeDateDisplayMetric
}

export type SendActionParams = {
    notification?: NotificationDto | NotificationPayload
    notification_id?: number | string
    title?: string
}

export type SendCartActionParams = SendActionParams & {
    item_strategy?: EcommItemPickStrategy
}

export type JourneyExitParams = {
    exit_state: 'completed'
}

// endregion

// region Configuration types

export type BaseJourneyConfig<
    CType extends ActionType | TriggerType | GlobalTriggerType | DelayType.RELATIVE | undefined = undefined,
> = {
    out_step_id: number | string
    type?: CType
}

export type DelayConfiguration = BaseJourneyConfig<DelayType.RELATIVE> & {
    params: DelayParams
}

export type SendNotificationConfiguration = BaseJourneyConfig<ActionType.SEND_NOTIFICATION> & {
    params: SendActionParams
}

export type SendCartNotificationConfiguration = BaseJourneyConfig<ActionType.SEND_CART_NOTIFICATION> & {
    params: SendCartActionParams
}

export type ActionConfiguration = SendNotificationConfiguration | SendCartNotificationConfiguration

export type GlobalTriggerConfiguration = BaseJourneyConfig<GlobalTriggerType> & {}

export type TriggerConfiguration = BaseJourneyConfig<TriggerType> & {}

export type ExitConfiguration = BaseJourneyConfig & {
    params: JourneyExitParams
}

// endregion

// region JourneyStep types

export type BaseJourneyStep<StepType extends NodeType = any> = {
    id: string | number
    revision_id?: number
    campaign_id?: number
    type: StepType
    meta: Record<string, any>
}

export type DelayStep = BaseJourneyStep<NodeType.DELAY> & {
    configuration: DelayConfiguration
}

export type ActionStep = BaseJourneyStep<NodeType.ACTION> & {
    configuration: ActionConfiguration
}

export type SendNotificationActionStep = ActionStep & { configuration: SendNotificationConfiguration }
export function isSendNotificationActionStep(step: ActionStep): step is SendNotificationActionStep {
    return step.configuration.type === ActionType.SEND_NOTIFICATION
}

export type SendCartNotificationActionStep = ActionStep & { configuration: SendCartNotificationConfiguration }
export function isSendCartNotificationActionStep(step: ActionStep): step is SendCartNotificationActionStep {
    return step.configuration.type === ActionType.SEND_CART_NOTIFICATION
}

export type GlobalTriggerStep = BaseJourneyStep<NodeType.GLOBAL_TRIGGER> & {
    configuration: any
}

export type TriggerStep = BaseJourneyStep<NodeType.TRIGGER> & {
    configuration: TriggerConfiguration
}

export type ExitStep = BaseJourneyStep<NodeType.EXIT> & {
    configuration: ExitConfiguration
}

export type JourneyStep = GlobalTriggerStep | TriggerStep | DelayStep | ActionStep | ExitStep

// endregion

// region JourneyNode / Edge Types

export type JourneyNode<T extends JourneyStep = JourneyStep> = Node<JourneyStepData<T>>

export function isGlobalTriggerJourneyNode(node: JourneyNode): node is JourneyNode<GlobalTriggerStep> {
    return node.data.step.type === NodeType.GLOBAL_TRIGGER
}
export function isTriggerJourneyNode(node: JourneyNode): node is JourneyNode<TriggerStep> {
    return node.data.step.type === NodeType.TRIGGER
}
export function isDelayJourneyNode(node: JourneyNode): node is JourneyNode<DelayStep> {
    return node.data.step.type === NodeType.DELAY
}
export function isActionJourneyNode(node: JourneyNode): node is JourneyNode<ActionStep> {
    return node.data.step.type === NodeType.ACTION
}
export function isExitJourneyNode(node: JourneyNode): node is JourneyNode<ExitStep> {
    return node.data.step.type === NodeType.EXIT
}

// @todo NS-1663 - determine if Edges require data? May need for branching / etc features?
export type JourneyEdge = Edge<any>

// endregion
// endregion

// region UIEditTrackingClass
export class JourneyStepData<DataType extends JourneyStep> extends UIEditTrackable<DataType> {
    readonly [x: string]: unknown

    constructor(data: DataType) {
        super(data, data.id)
    }

    public get step(): DataType {
        return this.data
    }

    public setStep(value: DataType) {
        this.data = value
    }
}

// endregion

// region Util Types

// available types of nodes to add
export type AddNodeType = NodeType.DELAY | ActionType.SEND_NOTIFICATION | ActionType.SEND_CART_NOTIFICATION

export type EditableNode = JourneyNode<ActionStep | DelayStep>

export type NodeEditorRef =
    | {
          onSubmit: () => JourneyNode<DelayStep> | Promise<JourneyNode<ActionStep> | void>
      }
    | undefined

// endregion
