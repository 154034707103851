import React, { forwardRef, useImperativeHandle } from 'react'
import { Skeleton } from 'antd'
import { useJourneyContext } from '../context'
import { NotificationDto } from '../../notifications'
import { TriggerType } from '../enums'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../../stores/app'
import { NotificationService } from '../../../services'
import NotificationBuilder from '../../../components/notification-builder/notification-builder'
import { NotificationBuilderLevel } from '../../../components/notification-builder/enums'
import {
    buildPreviewOptions,
    buildSubmittedNotificationPackages,
    validateSubmit as validateNotificationBuilder,
} from '../../../components/notification-builder/helpers'
import { handleNodeParamsUpdate } from '../helpers'
import NotificationBuilderAside from '../../../components/notification-builder/elements/notification-builder-aside'
import NotificationBuilderPreview from '../../../components/notification-builder/elements/notification-builder-preview'
import { ApiVersion } from '../../../enums/api-version.enum'
import NotificationBuilderMain from '../../../components/notification-builder/elements/notification-builder-main'
import NotificationEcommOptions from '../../../components/notification-builder/elements/notification-ecomm-options'
import NotificationVariantBuilder from '../../../components/notification-builder/elements/notification-variant-builder'
import { noop } from '../../../_utils/utils'
import { stripUndefined } from '../../../_utils/strip-undefined'
import { ActionStep, isSendCartNotificationActionStep, JourneyNode, NodeEditorRef } from '../types/journey-nodes'

type SendNotificationEditorProps = {
    node: JourneyNode<ActionStep>
}

export const SendNotificationEditor = forwardRef<NodeEditorRef, SendNotificationEditorProps>(({ node }, ref) => {
    const [state, _dispatch] = useJourneyContext()

    const appState = useService(AppState)
    const notifService = useService(NotificationService)

    const journey = state.journey
    const domain = state.domain

    const action = node.data.data
    const notification = action.configuration.params.notification

    const isSendCartNotificationAction = isSendCartNotificationActionStep(action)
    const shouldShowEcommStrategy =
        journey &&
        isSendCartNotificationAction &&
        [TriggerType.ABANDONED_BROWSE, TriggerType.ABANDONED_CART].includes(
            journey.configuration?.computed_trigger_type,
        )

    console.debug(isSendCartNotificationAction ? action.configuration.params.item_strategy : undefined)
    return (
        <div>
            <Skeleton
                loading={!state.domain}
                active={true}
                title={false}
                avatar={false}
                paragraph={{
                    rows: 1,
                    width: '100%',
                }}
            >
                {!state.domain ? (
                    <></>
                ) : (
                    <NotificationBuilder
                        domain={domain}
                        level={NotificationBuilderLevel.CAMPAIGN}
                        mode="create"
                        notifications={notification ? [NotificationDto.parse(notification)] : undefined}
                        defaultChannels={state.journey?.channels}
                        defaultSelectedNotifId={notification?.id}
                        showWebhookOptions={false}
                        showEcommStrategy={shouldShowEcommStrategy}
                        defaultEcommStrategy={
                            isSendCartNotificationAction ? action.configuration.params.item_strategy : undefined
                        }
                    >
                        {(builderProps) => {
                            const { builder, dispatchChanges, validateSubmit, variants, onVariantChange } = builderProps
                            const activeVariant = variants[builder.selectedVariantIdx]
                            const options = buildPreviewOptions(builder.channels)

                            useImperativeHandle(ref, () => ({
                                onSubmit: async (): Promise<JourneyNode<ActionStep> | void> => {
                                    const valid = await validateNotificationBuilder(
                                        builder,
                                        domain,
                                        appState.flags,
                                        false,
                                        false,
                                    )

                                    if (valid) {
                                        const builds = buildSubmittedNotificationPackages(
                                            domain,
                                            builder,
                                            appState.flags,
                                        )

                                        if (builds.length) {
                                            const notificationParam = builds[0]

                                            return handleNodeParamsUpdate(
                                                node,
                                                stripUndefined({
                                                    notification: notificationParam,
                                                    title: notificationParam.template.channels.default.title,
                                                    item_strategy: isSendCartNotificationAction
                                                        ? builder.ecommItemPickStrategy
                                                        : undefined,
                                                }),
                                            )
                                        }
                                    }
                                },
                            }))

                            return (
                                <>
                                    <NotificationBuilderAside>
                                        <NotificationBuilderPreview
                                            level="domain"
                                            levelId={domain.id}
                                            loading={builder.loading}
                                            domain={domain}
                                            source={activeVariant}
                                            onPreviewRequest={async (type) => {
                                                const valid = await validateSubmit(
                                                    builder,
                                                    domain,
                                                    appState.flags,
                                                    true,
                                                )

                                                if (valid) {
                                                    const builds = buildSubmittedNotificationPackages(
                                                        domain,
                                                        builder,
                                                        appState.flags,
                                                        type,
                                                        {
                                                            campaign_id: state.journey?.id,
                                                            campaign_notification_type: isSendCartNotificationAction
                                                                ? 'cart_notification'
                                                                : 'standard_notification',
                                                        },
                                                    )

                                                    const previewBuild = builds[builder.selectedVariantIdx]

                                                    await notifService.sendNotification(domain.id, previewBuild, {
                                                        cancellationKey: `notif-send-preview`,
                                                        version: ApiVersion.V4,
                                                    })
                                                }
                                            }}
                                            options={options}
                                        />
                                    </NotificationBuilderAside>
                                    <NotificationBuilderMain>
                                        {shouldShowEcommStrategy && (
                                            <NotificationEcommOptions
                                                loading={builder.loading}
                                                builder={builder}
                                                setBuilder={dispatchChanges}
                                                showPreviouslyChosenItemOption={action.meta.actionIndex > 0}
                                            />
                                        )}

                                        <NotificationVariantBuilder
                                            domain={domain}
                                            builder={builder}
                                            id={builder.selectedVariantIdx}
                                            variant={activeVariant}
                                            onChange={onVariantChange}
                                            onSubmit={noop}
                                            hideSubmit={true}
                                            hideDelivery={true}
                                            hideImageMacroToggle={!isSendCartNotificationAction}
                                        />
                                    </NotificationBuilderMain>
                                </>
                            )
                        }}
                    </NotificationBuilder>
                )}
            </Skeleton>
        </div>
    )
})
