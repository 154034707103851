import * as React from 'react'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Button, Tooltip } from 'antd'
import { BetterComponent } from '../better-component/better-component'
import { AsyncButton } from '../async-button/async-button.component'
import { TranslationService } from '../../services/index'
import { isFunction } from '../../_utils/utils'
import { DynamicJsxFunction, DynamicJsxType } from '../../types'
import { WellConfiguration } from './well-configuration'
import './well.scss'

export interface ISubmitButtonAction {
    text: string
    onClick: Function
}

interface IProps {
    readonly ghost?: boolean
    readonly collapsible?: boolean
    readonly collapsed?: boolean
    readonly submitting?: boolean
    readonly triggerSubmitOnEnter?: boolean
    readonly disabled?: boolean
    readonly className?: string
    readonly loading?: boolean
    readonly style?: any

    readonly showHeader?: boolean
    readonly header?: DynamicJsxType
    readonly headerExtension?: React.ReactNode // DynamicJsxType
    readonly title?: DynamicJsxType
    readonly action?: string | DynamicJsxType
    readonly onActionClick?: () => any

    readonly showFooter?: boolean
    readonly showFooterWhenCollapsed?: boolean
    readonly footer?: DynamicJsxType
    readonly submitText?: string
    readonly submitActions?: ISubmitButtonAction[]
    readonly submitDisabled?: boolean
    readonly submitDisabledTooltip?: string
    readonly onSubmit?: Function
    readonly showSubmit?: boolean
    readonly cancelText?: string
    readonly onCancel?: Function
    readonly showCancel?: boolean
}

export interface IWellState {
    readonly disabled: boolean
    readonly collapsed: boolean
    readonly submitting: boolean
    readonly showHeader: boolean
    readonly showFooter: boolean
}

@observer
export class Well extends BetterComponent<React.PropsWithChildren<IProps>, IWellState> {
    private translationService: TranslationService

    private config: WellConfiguration

    constructor(props: WellConfiguration) {
        super(props)

        this.translationService = Container.get(TranslationService)

        this.config = new WellConfiguration(props)

        this.state = {
            disabled: this.config.disabled === true,
            collapsed: this.config.collapsed === true,
            submitting: this.config.submitting === true,
            showHeader: this.config.showHeader !== false,
            showFooter: this.config.showFooter !== false,
        }
    }

    public componentDidUpdate() {
        if (this.props.disabled !== undefined && this.props.disabled !== this.state.disabled) {
            this.setState({ disabled: this.props.disabled })
        }

        if (this.props.showHeader !== undefined && this.props.showHeader !== this.state.showHeader) {
            this.setState({ showHeader: this.props.showHeader })
        }

        if (this.props.showFooter !== undefined && this.props.showFooter !== this.state.showFooter) {
            this.setState({ showFooter: this.props.showFooter })
        }
    }

    public render() {
        const header = this.buildHeader()
        const footer = this.buildFooter()
        const classNames: string[] = ['component-well']

        if (this.state.showHeader && this.state.collapsed) {
            classNames.push('collapsed')
        }
        if (this.config.ghost) {
            classNames.push('ghost')
        }
        if (this.config.className) {
            classNames.push(this.config.className)
        }
        if (this.config.collapsible) {
            classNames.push('collapsible')
        }
        if (this.state.disabled === true) {
            classNames.push('disabled')
        }
        if (this.props.showFooterWhenCollapsed) {
            classNames.push('show-footer-when-collapsed')
        }

        return (
            <div className={classNames.join(' ')}>
                <div className="well-content" style={this.props.style || {}}>
                    {this.state.showHeader && <div className="well-header">{header}</div>}
                    {this.props.headerExtension && (
                        <div className="well-header-extension">{this.props.headerExtension}</div>
                    )}
                    <div className="well-body">
                        <div className={`well-loading-screen${this.props.loading === true ? ' on' : ''}`}>
                            <div className="well-loading-content">
                                <LoadingOutlined spin={true} />
                            </div>
                        </div>

                        <div className="well-body-window">{this.props.children}</div>
                    </div>
                </div>
                {this.state.showFooter && <div className="well-footer">{footer}</div>}
            </div>
        )
    }

    private buildHeader(): React.ReactNode {
        if (this.props.header) {
            return isFunction(this.props.header) ? (this.props.header as DynamicJsxFunction)() : this.props.header
        }

        let action
        if (this.props.action) {
            action = isFunction(this.props.action) ? this.props.action(this) : this.props.action
        }

        const actionWrapper = this.props.onActionClick ? <a onClick={this.handleActionClick}>{action}</a> : action

        return (
            <React.Fragment>
                <span className="well-title">{this.config.title}</span>
                {action && <span className="well-action">{actionWrapper}</span>}
                {this.config.collapsible && (
                    <React.Fragment>
                        <div style={{ flex: 1 }} />
                        <Button className="well-toggle" onClick={this.toggleCollapsed}>
                            <LegacyIcon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                        </Button>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    private buildFooter(): React.ReactNode {
        if (this.props.footer) {
            return isFunction(this.props.footer) ? this.props.footer() : this.props.footer
        }

        const submitBtn = (
            <span className={`well-submit${this.props.submitDisabled === true ? ' disabled' : ''}`}>
                <AsyncButton
                    className="submit"
                    actions={this.props.submitActions}
                    type="primary"
                    onClick={this.handleSubmit}
                    triggerSubmitOnEnter={this.props.triggerSubmitOnEnter}
                    disabled={this.props.submitDisabled === true}
                >
                    <span>{this.props.submitText || 'Submit'}</span>
                </AsyncButton>
            </span>
        )

        return (
            <React.Fragment>
                {this.props.showCancel !== false && (
                    <span className="well-cancel">
                        <Button type="ghost" className="cancel" onClick={this.handleCancel}>
                            <span>{this.props.cancelText || 'Cancel'}</span>
                        </Button>
                    </span>
                )}
                {this.props.showSubmit !== false &&
                    (this.props.submitDisabled && !!this.props.submitDisabledTooltip ? (
                        <Tooltip title={this.props.submitDisabledTooltip}>
                            <span>{submitBtn}</span>
                        </Tooltip>
                    ) : (
                        submitBtn
                    ))}
            </React.Fragment>
        )
    }

    @autobind
    private async handleSubmit(): Promise<any> {
        if (this.props.onSubmit) {
            return await this.props.onSubmit()
        }
    }

    @autobind
    private handleCancel() {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    @autobind
    private handleActionClick() {
        if (this.props.onActionClick) {
            this.props.onActionClick()
        }
    }

    @autobind
    private toggleCollapsed(): void {
        this.setState(({ collapsed }) => ({ collapsed: !collapsed }))
    }
}
