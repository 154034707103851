import { DEFAULT_CUSTOM_EDGE_CONFIG, EDGE_ID_PREFIX } from './constants'
import { RelativeDateDisplayMetric } from '@pushly/models/lib/enums/relative-date-display-metric'
import { Edge, EdgeChange, NodeChange } from '@xyflow/react'
import { ActionType, DelayType, NodeType } from './enums'
import { EcommItemPickStrategy } from '../../enums/ecomm-item-pick-strategy'
import { getRandId } from '@pushly/models/lib/utils/get-rand-id'
import { safeEnumFromValue } from '../../_utils/enum'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { NotificationDto } from '../notifications'
import {
    ActionStep,
    AddNodeType,
    DelayStep,
    EditableNode,
    JourneyEdge,
    JourneyNode,
    JourneyStep,
    JourneyStepData,
} from './types/journey-nodes'
import { JourneyState } from './types/journey-context'

// region Basic Utils

export const getEdgeId = (id: number | string) => `${EDGE_ID_PREFIX}${id}`

export const handleNodeParamsUpdate = <T extends EditableNode>(
    node: T,
    params: Partial<T['data']['data']['configuration']['params']>,
): T => {
    node.data.data.configuration.params = {
        ...node.data.data.configuration.params,
        ...params,
    }
    return node
}

/**
 * Transpose nodes from ReactFlow journey back into steps API schema
 *
 * @param state
 */
export const parseJourneyFromFlow = (state: JourneyState) => {
    const steps: any[] = []
    const { nodes, journey } = state
    if (!journey) {
        return
    }
    const journeyUpdate = structuredClone(journey)

    for (const node of nodes) {
        const nodeData = node.data.step
        // API CREATE/PUT step
        const step: JourneyStep = {
            ...nodeData, // 'abc' if new | '123' if already present
            campaign_id: !journey ? undefined : journey.id,
            configuration: {
                ...nodeData.configuration,
                out_step_id: nodeData.configuration.out_step_id, // parseInt(node.data.configuration.out_step_id, 10) || node.data.configuration.out_step_id,
            },
        }

        steps.push(step)
    }

    journeyUpdate.steps = steps

    return journeyUpdate
}

function getBaseConfigurationForNodeType(
    nodeType: NodeType.DELAY | ActionType.SEND_NOTIFICATION | ActionType.SEND_CART_NOTIFICATION,
    stepId: string,
    outStepId: number | string,
    addtOptions?: {
        meta: Record<string, any>
        channels?: DeliveryChannel[]
    },
): DelayStep | ActionStep {
    const actionIdx = addtOptions?.meta?.actionIndex

    switch (nodeType) {
        case NodeType.DELAY:
            return {
                id: stepId,
                type: nodeType,
                configuration: {
                    type: DelayType.RELATIVE,
                    out_step_id: outStepId,
                    params: {
                        delay_seconds: 60 * 60 * 24 * 2, // 2 days
                        qualifier: RelativeDateDisplayMetric.DAYS,
                    },
                },
                meta: {},
            }
        case ActionType.SEND_NOTIFICATION:
            return {
                id: stepId,
                type: NodeType.ACTION,
                configuration: {
                    type: nodeType,
                    out_step_id: outStepId,
                    params: {},
                },
                meta: {},
            }
        case ActionType.SEND_CART_NOTIFICATION:
            return {
                id: stepId,
                type: NodeType.ACTION,
                configuration: {
                    type: nodeType,
                    out_step_id: outStepId,
                    params: {
                        item_strategy:
                            !!actionIdx && actionIdx > 0
                                ? EcommItemPickStrategy.PREVIOUSLY_SELECTED
                                : EcommItemPickStrategy.RANDOM,
                    },
                },
                meta: {},
            }
    }
}

// endregion //

// region Node Manipulation

/**
 * Takes journey steps and orders them head to tail via id -> out_step_id -> next.id
 *
 * @param steps
 */
export const orderJourneySteps = (steps: JourneyStep[]) => {
    const orderedSteps: JourneyStep[] = []
    let nextStep = steps.find((step) => step.type === NodeType.TRIGGER)
    while (nextStep) {
        orderedSteps.push(nextStep)

        if (!nextStep.configuration.out_step_id) {
            break
        }
        nextStep = steps.find((step) => step.id === nextStep!.configuration.out_step_id)
    }

    return orderedSteps
}

/**
 * Transpose Journey steps into nodes and edges to be passed to a ReactFlow.
 *
 * @param steps - can be unordered as ordering is conducted during logic
 */
export const buildInitialJourneyFlow = (steps: JourneyStep[]): [nodes: JourneyNode[], edges: JourneyEdge[]] => {
    const nodeUpdates: JourneyNode[] = []
    const edgeUpdates: JourneyEdge[] = []

    // metadata for send_cart notification UI
    let actionIndex = 0
    orderJourneySteps(steps).forEach((step, idx) => {
        if (!step.type) return

        const data = new JourneyStepData(step)

        const newNode: JourneyNode = {
            id: step.id.toString(),
            position: {
                x: 0,
                y: idx * 120,
            },
            data,
            type: 'custom',
        }

        // global trigger is positioned hidden before the rest of the graph
        if (safeEnumFromValue(NodeType, data.step.type) === NodeType.GLOBAL_TRIGGER) {
            newNode.hidden = true
            newNode.position = {
                x: 0,
                y: -120,
            }
        }

        // action editor uses the position of the action in the flow to show specific options
        // set actionIndex according to order of actions from steps
        if (safeEnumFromValue(NodeType, data.step.type) === NodeType.ACTION) {
            data.step.meta = {
                ...data.step.meta,
                actionIndex,
            }

            data.resetInitialState(data.step)

            actionIndex++
        }

        nodeUpdates.push(newNode)

        // edges join node to node via source, target properties
        if (step.configuration.out_step_id) {
            const newEdge: JourneyEdge = {
                ...DEFAULT_CUSTOM_EDGE_CONFIG,
                id: getEdgeId(step.id),
                source: step.id.toString(),
                target: step.configuration.out_step_id.toString(),
            }

            edgeUpdates.push(newEdge)
        }
    })

    return [nodeUpdates, edgeUpdates]
}

export const getAddNodeMutations = (
    nodeType: AddNodeType,
    nodes: JourneyNode[],
    sourceNode: JourneyNode,
    targetNode: JourneyNode,
): [
    nodeChanges: NodeChange<JourneyNode>[],
    edgeChanges: EdgeChange<JourneyEdge>[],
    newNode: JourneyNode | undefined,
] => {
    const nodeChanges: NodeChange<JourneyNode>[] = []
    const edgeChanges: EdgeChange<JourneyEdge>[] = []

    const sourceNodeId = sourceNode.data.id
    const targetNodeId = targetNode.data.id

    const newNodeId = getRandId()

    // adding a step after exit not supported
    if (sourceNode.data.step.type === NodeType.EXIT) {
        return [nodeChanges, edgeChanges, undefined]
    }

    // re-assign out_step_id to new node - added to changes via below type: "replace" change
    sourceNode.data.step.configuration.out_step_id = newNodeId
    const meta: Record<string, any> = {}

    // if an ACTION step is added determine it's step index
    let newActionIndex
    if (nodeType === ActionType.SEND_NOTIFICATION || nodeType === ActionType.SEND_CART_NOTIFICATION) {
        const startingIndex = nodes.findIndex((n) => n.data.step.id === sourceNodeId)

        if (startingIndex > 0) {
            for (let i = startingIndex + 1; i--; i <= 0) {
                if (nodes[i].data.step.type === NodeType.ACTION) {
                    newActionIndex = nodes[i].data.step.meta.actionIndex + 1
                    break
                }
            }
        } else {
            newActionIndex = startingIndex
        }

        meta.actionIndex = newActionIndex
    }

    const data = new JourneyStepData(getBaseConfigurationForNodeType(nodeType, newNodeId, targetNodeId, { meta }))
    const newNode: JourneyNode = {
        id: newNodeId,
        data,
        position: {
            x: 0,
            y: sourceNode!.position.y + 120,
        },
        type: 'custom',
    }

    // update nodes from source to new and new to target
    // current: source { out_step_id: target }
    // new: source {out_step_id: new }, new {out_step_id: target}
    nodeChanges.push(
        // mutation to add newly created step/node
        {
            type: 'add',
            item: newNode,
        },
        // replace source node with out_step_id: newNodeId
        {
            type: 'replace',
            id: sourceNodeId.toString(),
            item: sourceNode,
        },
    )

    /**
     * update edges
     * ```
     *  _________________________________________________________
     * |                                                        |
     * |    current                                             |
     * |        nodes: [source] -----> [target]                 |
     * |        edges:      \__eid-source__/                    |
     * |--------------------------------------------------------|
     * |                                                        |
     * |    new                                                 |
     * |        nodes: [source]----> [new node] ----> [target]  |
     * |        edges:     \_eid-source_/ \_eid-new-node_/      |
     * |________________________________________________________|
     *
     * ```
     */
    edgeChanges.push(
        // add edge from source->new node
        {
            type: 'add',
            item: {
                ...DEFAULT_CUSTOM_EDGE_CONFIG,
                id: getEdgeId(sourceNodeId),
                source: sourceNodeId.toString(),
                target: newNodeId.toString(),
            },
        },
        // add edge from new node->target
        {
            type: 'add',
            item: {
                ...DEFAULT_CUSTOM_EDGE_CONFIG,
                id: getEdgeId(newNodeId),
                source: newNodeId,
                target: targetNodeId.toString(),
            },
        },
        // remove old edge from source->target
        {
            type: 'remove',
            id: getEdgeId(sourceNodeId),
        },
    )

    // apply position/meta changes for each node occurring after the added node
    nodeChanges.push(...applyChangesPostNode('add', nodes, targetNodeId.toString(), newNode.data.data.type))

    return [nodeChanges, edgeChanges, newNode]
}

export const getRemoveNodeMutations = (
    node: JourneyNode,
    nodes: JourneyNode[],
    edges: JourneyEdge[],
): [nodeChanges: NodeChange<JourneyNode>[], edgeChanges: EdgeChange<JourneyEdge>[]] => {
    const nodeChanges: NodeChange<JourneyNode>[] = []
    const edgeChanges: EdgeChange<JourneyEdge>[] = []

    // cannot remove Trigger or Exit steps return empty changes
    if (node.data.step.type === NodeType.EXIT || node.data.step.type === NodeType.TRIGGER) {
        return [nodeChanges, edgeChanges]
    }

    const sourceNodeEdge = edges.find((e) => e.target === node.id)!
    const targetNodeEdge = edges.find((e) => e.source === node.id)!

    const prevNodeIdx = nodes.findIndex((n) => n.id === sourceNodeEdge.source)!
    const prevNode = nodes[prevNodeIdx]
    const postNodeIdx = nodes.findIndex((n) => n.id === targetNodeEdge.target)
    const postNode = nodes[postNodeIdx]

    prevNode.data.step.configuration.out_step_id = postNode.data.id

    /**
     * update node/edges
     * ```
     *  _________________________________________________________
     * |                                                        |  [action] node will be removed
     * |    current                                             |
     * |        nodes: [trigger]----> [action]  ---->  [exit]   |  [trigger] will be replaced with
     * |        edges:     \_eid-trigger_/ \_eid-action_/       |       [trigger] out_step_id: exit.id
     * |--------------------------------------------------------|
     * |                                                        |  eid-action edge will be removed
     * |    new                                                 |
     * |        nodes: [trigger]  ----->  [exit]                |  eid-trigger will be replaced with
     * |        edges:      \__eid-trigger__/                   |       eid-trigger where target: exit.id
     * |________________________________________________________|
     *
     * ```
     */
    nodeChanges.push(
        {
            id: prevNode.id,
            type: 'replace',
            item: prevNode,
        },
        {
            id: node.id,
            type: 'remove',
        },
    )

    edgeChanges.push(
        {
            id: sourceNodeEdge.id,
            type: 'replace',
            item: {
                ...sourceNodeEdge,
                source: prevNode.id,
                target: postNode.id,
            },
        },
        {
            id: targetNodeEdge.id,
            type: 'remove',
        },
    )

    // apply position changes for each node occurring after the removed node
    nodeChanges.push(...applyChangesPostNode('remove', nodes, postNode.id, node.data.data.type))

    return [nodeChanges, edgeChanges]
}

/**
 * Traverses each step -> out_step_id chain and repositions and applies changes for each node from the affected starting
 * node.
 *
 * @param effect
 * @param nodes
 * @param startingId
 * @param nodeTypeChange
 */
export const applyChangesPostNode = (
    effect: 'add' | 'remove',
    nodes: JourneyNode[],
    startingId: string,
    nodeTypeChange?: NodeType,
) => {
    const nodeChanges: NodeChange<JourneyNode>[] = []

    let nextStep: string | undefined = startingId
    while (nextStep) {
        const step = nodes.find((n) => n.id === nextStep)
        if (step) {
            const positionUpdates = {
                x: 0,
                y: effect === 'add' ? step.position.y + 120 : step.position.y - 120,
            }

            if (step.data.step.type === NodeType.ACTION && nodeTypeChange === NodeType.ACTION) {
                step.data.step.meta.actionIndex =
                    effect === 'add' ? step.data.step.meta.actionIndex + 1 : step.data.step.meta.actionIndex - 1

                nodeChanges.push({
                    id: step.id,
                    type: 'replace',
                    item: {
                        ...step,
                        position: positionUpdates,
                        data: step.data,
                    },
                })
            } else {
                nodeChanges.push({
                    id: step.id,
                    type: 'position',
                    position: positionUpdates,
                })
            }

            if (step.data.step.configuration.out_step_id) {
                nextStep = step.data.step.configuration.out_step_id.toString()
                continue
            }
        }

        nextStep = undefined
    }

    return nodeChanges
}

// endregion
