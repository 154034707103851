import React from 'react'
import './style/user-menu.scss'
import classnames from 'classnames'
import { Popover } from 'antd'
import { CodeOutlined, StarFilled, UserOutlined } from '@ant-design/icons'
import LogoutButton from './logout-button'
import SidebarActionItem from './sidebar-action-item'
import LinkTag from '../html-element-wrappers/link-tag'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { observer } from 'mobx-react'
import { asLinkAltClick } from '../../_utils/routing'
import { NoTranslate } from '../no-translate/no-translate'

declare const PlatformNotifications: any

interface IUserMenuItem {
    className?: string
    href?: string
    onClick?: (ev: any) => any
    target?: string
    rel?: string
}
const UserMenuItem: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IUserMenuItem>>> = ({
    className,
    href,
    onClick,
    target,
    rel,
    children,
}) => {
    // tslint:disable-next-line:no-shadowed-variable
    const Wrapper = !!href ? LinkTag : ({ onClick, children }: any) => <div onClick={onClick}>{children}</div>
    const wrapperProps: any = { onClick }
    if (!!href) {
        wrapperProps.href = href
    }
    if (!!target) {
        wrapperProps.target = target
    }
    if (!!rel) {
        wrapperProps.rel = rel
    }

    return (
        <div className={classnames('app-user-menu-item', className)}>
            <Wrapper {...wrapperProps}>{children}</Wrapper>
        </div>
    )
}

@observer
class UserMenu extends React.Component {
    private appState: AppState
    private appService: AppService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const { currentUser } = this.appState
        const profilePath = `/users/${this.appState.currentUser!.id}`

        return (
            <div className={classnames('app-user-menu')}>
                <Popover
                    overlayClassName={classnames('app-user-menu-overlay')}
                    trigger="click"
                    placement="leftBottom"
                    content={
                        <>
                            <UserMenuItem
                                className={classnames('profile-jump')}
                                href={profilePath}
                                onClick={(ev) =>
                                    asLinkAltClick(ev, () => {
                                        this.appService.route(profilePath)
                                    })
                                }
                            >
                                <CodeOutlined />
                                <span>User Settings</span>
                            </UserMenuItem>

                            {PlatformNotifications.supported && (
                                <UserMenuItem
                                    className="register-preview-device"
                                    href="/register-preview-device"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <StarFilled />
                                    <span>Register Preview Device</span>
                                </UserMenuItem>
                            )}

                            <UserMenuItem className={classnames('logout-jump')}>
                                <LogoutButton mode="link" />
                            </UserMenuItem>
                        </>
                    }
                >
                    <SidebarActionItem
                        className={classnames('app-user-menu-toggle')}
                        title={<NoTranslate>{currentUser?.name}</NoTranslate>}
                        description={false}
                        icon={<UserOutlined />}
                    />
                </Popover>
            </div>
        )
    }
}

export default UserMenu
