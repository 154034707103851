import * as React from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../services'
import {
    Background,
    BackgroundVariant,
    Controls,
    MiniMap,
    ReactFlow,
    ReactFlowProps,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from '@xyflow/react'
import { Drawer, Well } from '@pushly/aqe/lib/components'
import { CustomNode } from './node-types/custom-node/custom-node'
import { CustomEdge } from './edge-types/custom-edge/custom-edge'
import { NodeEditor } from './editors/node-editor'
import { JourneyBuilderMode, NodeType } from './enums'
import { DomainDto } from '../../dtos/domain'
import { JourneyContext } from './context'
import { useJourneyReducer } from './reducer'
import titleCase from 'title-case'
import { getClassNames } from '../../_utils/classnames'
import { buildInitialJourneyFlow, parseJourneyFromFlow } from './helpers'

import './styles/journey.scss'
import './node-types/node-types.scss'
import { RemoveStepConfirmationModal } from '../../components/campaign-builder/modals/remove-step-confirmation-modal'
import { noop } from '../../_utils/utils'
import { getEditableState } from '../../components/campaign-builder/helpers/campaign'
import { CampaignEditableState } from '../../components/campaign-builder/enums'
import { Journey } from './types/journey-api-response'
import { JourneyEdge, JourneyNode, JourneyStep, NodeEditorRef } from './types/journey-nodes'
import { JourneyAction, JourneyState } from './types/journey-context'

type JourneyBuilderProps = {
    loading?: boolean
    domain: DomainDto
    journey: Journey
    onChange: (journey: any) => void
    onSubmit: (journey: Journey, remove?: boolean) => void
    mode?: JourneyBuilderMode
    flowOverrides?: ReactFlowProps<JourneyNode, JourneyEdge>
}

export const JourneysBuilder = ({
    loading,
    domain,
    journey,
    mode,
    onChange,
    onSubmit,
    flowOverrides,
}: JourneyBuilderProps) => {
    // region Builder Definitions
    const appSvc = useService(AppService)

    const [nodes, setNodes, onNodesChange] = useNodesState<JourneyNode>([])
    const [edges, setEdges, onEdgesChange] = useEdgesState<JourneyEdge>([])
    const [showEditor, setShowEditor] = React.useState(false)
    const [activeNode, setActiveNode] = React.useState<JourneyNode | null>(null)
    const [removedNodes, setRemovedNodes] = React.useState<JourneyNode[]>([])
    const [removeModalOpen, setRemoveModalOpen] = React.useState(false)

    const [journeyHasChanged, setJourneyHasChanged] = React.useState(false)

    const nodeRef = useRef<NodeEditorRef>(null)

    // Nodes are rendered from these types passed to ReactFlow nodeTypes prop
    // node.type === nodeTypes[node.type] determines render component
    const nodeTypes = useMemo(
        () => ({
            custom: CustomNode,
        }),
        [],
    )

    // Edges are rendered the these types passed to ReactFlow.edgeTypes
    // edge.type === edgeTypes[node.type] determines render component
    const edgeTypes = useMemo(
        () => ({
            custom: CustomEdge,
        }),
        [],
    )
    // endregion

    // region Node Handlers
    // --------------- Node Interactions -------------------- //
    // setup node interactivity handlers/callbacks to register to context
    const handleNodeAdd = (node: JourneyNode) => {
        if (node.data.step.type !== NodeType.EXIT && node.data.step.type !== NodeType.TRIGGER) {
            setActiveNode(node)
            setShowEditor(true)
        }
    }

    const handleNodeUpdate = <T extends JourneyStep>(node: JourneyNode<T>, nodeUpdate: JourneyNode<T>) => {
        setNodes((prev) =>
            prev.map((n) => {
                if (n.id === node.id) {
                    return nodeUpdate
                }

                return n
            }),
        )

        return nodes
    }

    const handleNodeRemove = (node: JourneyNode) => {
        if (node.data.step.type !== NodeType.EXIT && node.data.step.type !== NodeType.TRIGGER) {
            setRemovedNodes((prev) => [...prev, node])
        }
    }

    // local handlers

    const handleNodeClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, node: JourneyNode) => {
            setActiveNode(node)
            setShowEditor(true)
        },
        [nodes],
    )

    const handleCloseEditor = () => {
        setActiveNode(null)
        setShowEditor(false)
    }

    const handleSubmitJourney = () => {
        const journeyUpdate = parseJourneyFromFlow(state)

        if (
            journeyHasChanged &&
            mode !== JourneyBuilderMode.CREATE &&
            // @todo NS-1663 - update to Journey types/helpers
            getEditableState(journey) === CampaignEditableState.RUNNING &&
            removedNodes.length > 0
        ) {
            setRemoveModalOpen(true)
        } else {
            onSubmit?.(journeyUpdate)
        }
    }

    // endregion

    const emitChanges = (journeyState: JourneyState) => {
        onChange?.(parseJourneyFromFlow(journeyState))
    }

    // initialize journey context
    const [state, dispatch] = useJourneyReducer({
        domain,
        journey: structuredClone(journey),
        mode: mode ?? JourneyBuilderMode.READONLY,
        callbacks: {
            onNodeAdd: handleNodeAdd,
            onNodeUpdate: handleNodeUpdate,
            handleNodeRemove,
            onNodeClick: handleNodeClick,
            emitChanges,
        },
        nodes,
        edges,
    })

    // region Builder Effects
    // ------------------------- Effects ------------------------ //

    // all effects will trigger context update separate from flow update handling
    // maintain this pattern for better rendering/memoization performance
    useEffect(() => {
        dispatch({ action: JourneyAction.ON_JOURNEY_CHANGES, data: { nodes, edges } })
    }, [nodes, edges, setNodes, setEdges])

    // on mount build journey flow, dispatch to journey context initial states
    useEffect(() => {
        const buildInitialJourneyNodesAndEdges = () => {
            if (journey) {
                const [initialNodes, initialEdges] = buildInitialJourneyFlow(journey.revision.steps)

                dispatch({ action: JourneyAction.SET_JOURNEY, data: journey })
                dispatch({
                    action: JourneyAction.ON_JOURNEY_CHANGES,
                    data: { nodes: initialNodes, edges: initialEdges },
                })

                setNodes(initialNodes)
                setEdges(initialEdges)
            }
        }

        buildInitialJourneyNodesAndEdges()
    }, [journey?.id])
    // endregion

    const detectChanges = () => {
        // skip checking steps | if length changes then campaign def has changed
        if (state.nodes.length !== journey.steps.length) {
            return true
        }

        return state.nodes.some(({ data }) => {
            if (data.step.type === NodeType.ACTION) {
                delete data.step.configuration.params.notification?.template?.channels?.default?.keywords
            }

            return data.hasChanged()
        })
    }

    useEffect(() => {
        const determineSubmitAvailable = () => {
            setJourneyHasChanged(detectChanges())
        }

        if (state.journey?.id) {
            determineSubmitAvailable()
        }
    }, [state.nodes, JSON.stringify(state.journey)])

    return (
        <Well
            className={getClassNames('journey-builder-well', 'nested')}
            title="Builder"
            onSubmit={handleSubmitJourney}
            disableSubmit={!journeyHasChanged}
            hideSubmit={mode === JourneyBuilderMode.READONLY}
            hideCancel={true}
            loading={loading}
        >
            {!loading && state.journey && (
                <JourneyContext.Provider value={{ state, dispatch }}>
                    <ReactFlowProvider>
                        <ReactFlow<JourneyNode, JourneyEdge>
                            className={getClassNames('journey-builder')}
                            nodes={state.nodes}
                            edges={state.edges}
                            nodeTypes={nodeTypes}
                            onNodesChange={onNodesChange}
                            nodesConnectable={false}
                            nodesDraggable={false}
                            nodesFocusable={mode !== JourneyBuilderMode.READONLY}
                            edgeTypes={edgeTypes}
                            onEdgesChange={onEdgesChange}
                            edgesReconnectable={false}
                            edgesFocusable={false}
                            fitView={true}
                            zoomOnDoubleClick={false}
                            {...flowOverrides}
                        >
                            <Background
                                variant={BackgroundVariant.Lines}
                                bgColor="rgb(242, 242, 242)"
                                color="rgb(217, 217, 217)"
                                lineWidth={0.5}
                                gap={15}
                            />
                            <MiniMap pannable={true} />
                            <Controls position="top-right" orientation="horizontal" showInteractive={false} />
                        </ReactFlow>
                    </ReactFlowProvider>

                    <Drawer
                        getContainer={appSvc.getAppContainer}
                        className={getClassNames('journey-node-editor', activeNode?.data?.data?.type)}
                        destroyOnClose={true}
                        title={`Edit ${titleCase(activeNode?.data.data.type ?? '')}`}
                        placement="right"
                        closable={true}
                        maskClosable={false}
                        visible={showEditor}
                        onClose={handleCloseEditor}
                        onSubmit={async () => {
                            const nodeChange = await nodeRef?.current?.onSubmit()

                            if (nodeChange) {
                                dispatch({
                                    action: JourneyAction.ON_NODE_UPDATE,
                                    data: { node: activeNode!, updates: nodeChange },
                                })
                                handleCloseEditor()
                            }
                        }}
                    >
                        {activeNode && <NodeEditor node={activeNode!} ref={nodeRef} />}
                    </Drawer>

                    <RemoveStepConfirmationModal
                        changeSet={{ campaign: parseJourneyFromFlow(state) }}
                        visible={removeModalOpen}
                        onConfirm={(updatedJourney, removeSubs) => {
                            setRemoveModalOpen(false)
                            onSubmit?.(updatedJourney, removeSubs)
                        }}
                        onCancel={() => setRemoveModalOpen(false)}
                    />
                </JourneyContext.Provider>
            )}
        </Well>
    )
}
