import * as React from 'react'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { Well } from '@pushly/aqe/lib/components'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, Input } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import autobind from 'autobind-decorator'
import { NotificationScheduleService } from '../../../services/notification-schedule'
import { Container } from 'typescript-ioc/es5'
import { NotificationScheduleDto } from '../dtos/notification-schedule-dto'

interface IProps extends FormComponentProps {
    getEntity: () => NotificationScheduleDto
    onSubmit: Function
    onCancel: Function
}

interface IState {
    schedule: NotificationScheduleDto
    initialDetails?: string
    details?: string
}

class AdditionalDetailsForm extends BetterFormComponent<IProps, IState> {
    private scheduleService: NotificationScheduleService

    public constructor(props: IProps) {
        super(props)

        this.scheduleService = Container.get(NotificationScheduleService)

        const schedule = props.getEntity()

        this.state = {
            schedule,
            initialDetails: schedule.additionalDetails,
        }
    }

    public render(): React.ReactNode {
        return !!this.state.schedule ? (
            <>
                <Row gutter={16}>
                    <Col span={6}>
                        <b>Notification ID:</b>
                    </Col>
                    <Col span={18}>{this.state.schedule.notificationId}</Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <b>Schedule ID:</b>
                    </Col>
                    <Col span={18}>{this.state.schedule.id}</Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <b>Title:</b>
                    </Col>
                    <Col span={18}>{this.state.schedule.webTemplate.title}</Col>
                </Row>

                <hr />

                <Well
                    className="additional-details-well thin"
                    mode="ghost"
                    title="Additional Details"
                    onSubmit={this.handleSubmit}
                    onCancel={this.props.onCancel}
                >
                    <Form>
                        <div className="fx-row">
                            <div className="fx-col">
                                Add additional notification details to display to the publisher.
                            </div>
                            <div className="fx-col fx-shrink">
                                <a onClick={this.clearDetails}>
                                    <span>Clear</span>
                                </a>
                            </div>
                        </div>

                        <Form.Item>
                            <Input.TextArea
                                onChange={this.updateDetailsState}
                                value={this.state.details || this.state.initialDetails}
                            />
                        </Form.Item>
                    </Form>
                </Well>
            </>
        ) : (
            <></>
        )
    }

    @autobind
    protected updateDetailsState(ev: React.ChangeEvent<HTMLTextAreaElement>) {
        return this.setState({
            initialDetails: undefined,
            details: ev.target.value,
        })
    }

    @autobind
    protected clearDetails() {
        return this.setState({
            initialDetails: undefined,
            details: undefined,
        })
    }

    @autobind
    protected async handleSubmit() {
        try {
            await this.scheduleService.setScheduleDetails(
                this.state.schedule,
                !this.state.details ? null : this.state.details,
            )

            this.props.getEntity().additionalDetails = this.state.details!
            this.props.onSubmit()
        } catch {}
    }
}

export const AdditionalDetailsDataForm = Form.create<IProps>()(AdditionalDetailsForm)
