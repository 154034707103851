import clone from 'clone'

export function stripUndefined<R = any>(obj: any): R {
    if (obj === undefined || obj === null) {
        return obj
    }

    const objClone = clone(obj)
    if (Array.isArray(objClone)) {
        // @ts-ignore
        return objClone.map(stripUndefined)
    } else if (typeof objClone === 'object' && Object.keys(objClone).length > 0) {
        const keys = Object.keys(objClone)
        for (const key of keys) {
            const value = objClone[key]
            if (value === undefined) {
                delete objClone[key]
            } else {
                objClone[key] = stripUndefined(objClone[key])
            }
        }
    }

    return objClone
}
