import * as React from 'react'
import './sandbox.scss'
import { PageHeader } from '../../components/page-header/page-header'

function Sandbox() {
    return (
        <React.Fragment>
            <PageHeader title="Sandbox" />
        </React.Fragment>
    )
}

export default Sandbox
