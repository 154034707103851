import * as React from 'react'
import { IBadgeProps } from './interfaces'
import Icon from '@ant-design/icons'
import { Tooltip } from 'antd'
import { IconAb } from '../svg/icon-ab'

export const NotificationAbBadge: React.FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<IBadgeProps>>
> = (props) => {
    const badge = (
        <span className="icon-ab">
            <Icon component={IconAb} />
        </span>
    )

    return (
        <span className="notification-ab-badge">
            {props.hideTooltip ? badge : <Tooltip title={props.tooltip || 'Notification Test'}>{badge}</Tooltip>}
        </span>
    )
}
