import * as React from 'react'
import { Well, DayPartSelect, IDayPartValue } from '@pushly/aqe/lib/components'
import '@ant-design/compatible/assets/index.css'
import { Form } from '@ant-design/compatible'
import { Select, Input } from 'antd'
import clone from 'clone-deep'
import {
    calculateDelayQualifiedValue,
    calculateDelaySecondsValue,
    DEFAULT_DELAY_QUALIFIER,
    DEFAULT_DELAY_SECONDS,
    DEFAULT_EXCLUDED_DAYS,
    DelayQualifier,
} from '../helpers/delay-node'
import '../style/delay-editor.scss'
import { ITypeEditor } from '../interfaces'
import { CampaignEditableState, NodeType } from '../enums'
import { CURRENT_BUILDER_ITERATION } from '../constants'
import { updateNodeParams } from '../helpers/node'
import { tryParseFloat } from '../../../_utils/try-parse'

export const RelativeDelayPicker = ({ value, showExclusions, onChange, disabled }: any) => {
    const delaySeconds = value.delay_seconds ?? DEFAULT_DELAY_SECONDS
    const delayQualifier = value.qualifier ?? DEFAULT_DELAY_QUALIFIER

    const excludedDays: any = showExclusions ? clone(DEFAULT_EXCLUDED_DAYS) : []
    if (showExclusions && value.excluded_days) {
        value.excluded_days.forEach((day: string) => {
            excludedDays[day] = false
        })
    }

    let qualifiedValue: any = calculateDelayQualifiedValue(delaySeconds, delayQualifier)
    if (/^0./.test(qualifiedValue.toString())) {
        qualifiedValue = qualifiedValue.toString().replace(/^0/gi, '')
    }

    return (
        <>
            <Input
                className="relative-delay-picker"
                size="small"
                type="number"
                min={1}
                max={60}
                autoComplete="off"
                defaultValue={calculateDelayQualifiedValue(DEFAULT_DELAY_SECONDS, DEFAULT_DELAY_QUALIFIER)}
                value={qualifiedValue}
                disabled={disabled}
                addonAfter={
                    <Select
                        className="campaign-delay-editor-qualifier-selection"
                        dropdownClassName="campaign-delay-editor-qualifier-dropdown"
                        value={delayQualifier}
                        disabled={disabled}
                        onChange={(qualifier: DelayQualifier) => {
                            onChange({
                                qualifier,
                                delay_seconds: calculateDelaySecondsValue(qualifiedValue, qualifier),
                            })
                        }}
                    >
                        <Select.Option value="minutes">minute(s)</Select.Option>
                        <Select.Option value="hours">hour(s)</Select.Option>
                        <Select.Option value="days">day(s)</Select.Option>
                    </Select>
                }
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    let qv: string | number = ev.target.value
                    if (qv === '') {
                        qv = 0
                    }

                    qv = tryParseFloat(qv.toString())
                    if (qv < 0) {
                        qv = 1
                    }

                    onChange({
                        qualifier: delayQualifier,
                        delay_seconds: calculateDelaySecondsValue(qv, delayQualifier),
                    })
                }}
            />

            {showExclusions && (
                <Form.Item label="Execute next step on the following days">
                    <DayPartSelect
                        daysOnly={true}
                        dayFormat={'ddd'}
                        weekBeginsOnSunday={true}
                        defaultValue={DEFAULT_EXCLUDED_DAYS}
                        value={excludedDays}
                        onChange={(days) => {
                            const dayKeys = Object.keys(days)
                            const skipDays = dayKeys.filter((key: keyof IDayPartValue) => !days[key])

                            onChange({
                                qualifier: delayQualifier,
                                delay_seconds: delaySeconds,
                                excluded_days: skipDays.length > 0 ? skipDays : undefined,
                            })
                        }}
                    />
                </Form.Item>
            )}
        </>
    )
}

export class DelayEditor extends React.PureComponent<ITypeEditor> {
    public render() {
        const { node, onChange } = this.props
        const params = node.properties?.[NodeType.DELAY]?.params ?? {}

        return (
            <>
                <Well mode="ghost" title="Schedule" hideFooter={true}>
                    <RelativeDelayPicker
                        disabled={this.props.editableState === CampaignEditableState.COMPLETED || this.props.readonly}
                        value={params}
                        showExclusions={CURRENT_BUILDER_ITERATION >= 2}
                        onChange={(updates: any) => {
                            onChange(updateNodeParams(node, updates))
                        }}
                    />
                </Well>
            </>
        )
    }
}
