import React from 'react'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'

type EdgeActionProps = {
    visible: boolean
    onClick: () => void
}

export const EdgeAction = ({ visible, onClick }: EdgeActionProps) => {
    let icon: React.ReactNode
    if (visible) {
        icon = <CloseOutlined />
    } else {
        icon = <PlusOutlined />
    }

    return (
        <Button
            size="small"
            type="primary"
            shape="circle"
            className="nodrag nopan journey-edge-action-add"
            onClick={onClick}
        >
            {icon}
        </Button>
    )
}
