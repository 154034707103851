import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { AppleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const NativeIosBadge: React.FC<React.PropsWithChildren<React.PropsWithChildren<IBadgeProps>>> = (props) => {
    const badge = (
        <span className="icon-ios">
            <AppleFilled {...props} />
        </span>
    )

    return (
        <span className="delivery-channel-ios-badge">
            {props.hideTooltip ? (
                badge
            ) : (
                <Tooltip
                    title={
                        props.tooltip || `Delivery Channel: ${DeliveryChannel.getLongName(DeliveryChannel.NATIVE_IOS)}`
                    }
                >
                    {badge}
                </Tooltip>
            )}
        </span>
    )
}
