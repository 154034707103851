import React, { forwardRef, ReactElement, Ref } from 'react'
import { DelayEditor } from './delay-editor'
import { SendNotificationEditor } from './send-notification-editor'
import { isActionJourneyNode, isDelayJourneyNode, JourneyNode, NodeEditorRef } from '../types/journey-nodes'

const getEditor = (activeNode: JourneyNode | null, forwardedRef?: Ref<NodeEditorRef>) => {}

type NodeEditorProps = {
    node: JourneyNode
}

export const NodeEditor = forwardRef<NodeEditorRef, NodeEditorProps>(({ node }, ref) => {
    let editor: ReactElement | null

    if (!node) {
        return null
    }

    if (isDelayJourneyNode(node)) {
        editor = <DelayEditor node={node} ref={ref} />
    } else if (isActionJourneyNode(node)) {
        editor = <SendNotificationEditor node={node} ref={ref} />
    } else {
        editor = null
    }

    return editor
})
