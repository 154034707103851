import { Popover } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import React from 'react'
import { NodeType } from '../../enums'
import titleCase from 'title-case'

type InvalidConnectionLabelProps = {
    sourceType: NodeType
}

export const InvalidConnectionLabel = ({ sourceType }: InvalidConnectionLabelProps) => {
    const renderStepType = titleCase(sourceType)

    return (
        <Popover
            overlayClassName="custom-edge-invalid-label-popover"
            className="nodrag nopan"
            content={`${renderStepType} Steps cannot be adjacent. Please remove one of the ${renderStepType.toLowerCase()}s or add a step between them.`}
        >
            <WarningFilled />
        </Popover>
    )
}
