import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { getClassNames } from '../../_utils/classnames'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Card, Input, Modal, Result, Button, Skeleton, Descriptions, InputRef } from 'antd'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import { useService } from '../../hooks/use-service'
import { DomainIntegrationService } from '../../services'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import { simpleNotification } from '../../_utils/utils'
import { IIntegrationRow, IShopifyIntegration } from './interfaces'
import { handleResponseErrorMessage } from '../../_utils/response-error-utils'
import { InputProps } from 'antd/lib/input'

const ShopifyIntegration = (props: IIntegrationRow<IShopifyIntegration>) => {
    const { domainId, loading, integration } = props

    const history = useHistory()
    const shopNameInputRef = React.useRef<InputRef>()
    const runIfMounted = useMountedRef()[1]
    const [value, setValue] = useLoadableDataState({
        loading: false,
        data: integration,
    })
    const svc = useService<DomainIntegrationService>(DomainIntegrationService)

    const qs = queryString.parse(location.search)
    React.useEffect(() => {
        const processOauthCallback = async () => {
            if (qs.provider === 'shopify' && !integration) {
                setValue({
                    loading: true,
                    data: {
                        ...(value.data ?? ({} as any)),
                        isActive: true,
                        data: {
                            shop: qs.shop,
                        },
                    },
                })

                Modal.destroyAll()
                const modal = Modal.success({
                    className: 'completing-oauth-dialog',
                    title: 'Integration Finalizing',
                    icon: <span className="shopify-logo-color" />,
                    okText: 'Complete',
                    cancelText: 'Cancel',
                    footer: null,
                    content: (
                        <div>
                            <Loading3QuartersOutlined spin={true} />
                        </div>
                    ),
                } as any)

                const res = await svc.completeIntegrationSetup(domainId, 'shopify', qs, 'shopify-integration')

                runIfMounted(() => {
                    setValue({
                        loading: false,
                        data: res.data,
                    })

                    if (res.ok) {
                        modal.update({
                            className: 'completed-oauth-dialog',
                            title: 'Integration Completed',
                            content: (
                                <Result
                                    status="success"
                                    title={false}
                                    subTitle={
                                        <>
                                            <p>
                                                The Platform Shopify app has been successfully installed on your store.
                                                Within the next 15 minutes visitors to your store will begin to be
                                                prompted to opt-in to web push notifications.
                                            </p>
                                            <p className="details">
                                                Your Shopify product catalog has been automatically imported to the
                                                platform and you may set up Abandonment Notifications via the Campaigns
                                                section of the platform.
                                            </p>
                                        </>
                                    }
                                />
                            ),
                            okText: 'Close',
                            onOk: () => {
                                history.replace({ search: '', hash: 'integrations' })
                                modal.destroy()
                                simpleNotification('success', 'Integration successfully completed.')
                            },
                        })
                    } else {
                        modal.update({
                            className: 'failed-oauth-dialog',
                            title: 'Integration Failed',
                            content: (
                                <Result
                                    status="warning"
                                    title={false}
                                    subTitle="An error occurred during your integration and we were unable to complete the setup."
                                />
                            ),
                            onOk: () => {
                                history.replace({ hash: 'integrations', search: '' })
                                modal.destroy()
                            },
                        })
                    }
                })
            }
        }

        processOauthCallback()
    }, [qs.state])

    const createIntegration = () => {
        setValue({
            loading: true,
            data: undefined,
        })

        Modal.confirm({
            className: 'setup-oauth-dialog',
            title: 'Integration Setup',
            icon: <span className="shopify-logo-color" />,
            okText: 'Connect',
            cancelText: 'Cancel',
            cancelButtonProps: { className: 'cancel-btn' },
            content: (
                <div>
                    Shop address
                    <Input ref={shopNameInputRef as any} autoFocus={true} addonAfter={<span>.myshopify.com</span>} />
                </div>
            ),
            onOk: () => {
                return new Promise((res, rej) => {
                    const inputRef = shopNameInputRef.current!.input!
                    const v = inputRef.value.trim()

                    if (!v) {
                        rej()
                    }
                    svc.fetchIntegrationRedirectUrl('shopify', domainId, {
                        shop: `${v}.myshopify.com`,
                    }).then((redirectUrl) => {
                        if (!redirectUrl.ok) {
                            const err = redirectUrl.error as any
                            handleResponseErrorMessage(err)
                            rej()
                        } else {
                            const { authUrl } = redirectUrl.data!
                            window.location.href = authUrl
                        }
                    })
                })
            },
            onCancel: () => {
                setValue({
                    loading: false,
                    data: {
                        ...(value.data ?? ({} as any)),
                        isActive: false,
                    },
                })
            },
        })
    }

    const removeIntegration = async () => {
        if (!!integration || !!value.data) {
            setValue({
                ...value,
                loading: true,
            })

            const res = await svc.removeIntegration(domainId, value.data!.id!)
            if (res.ok) {
                simpleNotification('success', 'Integration successfully removed.')
                runIfMounted(() => {
                    setValue({
                        loading: false,
                        data: undefined,
                    })
                })
            }
        }
    }

    const isActive = value.data?.isActive

    return (
        <Card
            size="small"
            className={getClassNames('domain-integration', 'shopify', {
                active: isActive,
                empty: !value.data?.id,
            })}
            title={<span className="shopify-logo-color" />}
            extra={
                <Button
                    className={getClassNames(null, 'integration-toggle', 'enabled', {
                        linked: !!integration?.id,
                    })}
                    size="small"
                    loading={loading || value.loading}
                    icon={<LegacyIcon type={!!integration?.id ? 'disconnect' : 'link'} />}
                    onClick={() => (!!integration?.id ? removeIntegration() : createIntegration())}
                >
                    <span>{loading || value.loading ? 'Loading' : !integration?.id ? 'New Connection' : 'Remove'}</span>
                </Button>
            }
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false} paragraph={{ rows: 1 }}>
                {!!value.data?.id && (
                    <Descriptions>
                        <Descriptions.Item label="Shop">{value.data?.data?.shop}</Descriptions.Item>
                    </Descriptions>
                )}
            </Skeleton>
        </Card>
    )
}

export default ShopifyIntegration
