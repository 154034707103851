import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { Input, Popover, Select, Switch } from 'antd'
import { AppMessagePriority } from '@pushly/models/lib/enums/app-messages/app-message-priority'
import { getClassNames } from '../../../_utils/classnames'
import './styles/app-message-behavior-builder.scss'
import { InfoCircleOutlined } from '@ant-design/icons'
import { RelativeDateDisplayMetric } from '@pushly/models/lib/enums/relative-date-display-metric'
import { enumAsSelectOptions } from '../../../_utils/antd'
import {
    AppMessageBehavior,
    MutableAppMessageBehavior,
} from '@pushly/models/lib/structs/app-messages/app-message-behavior'
import { RelativeDate } from '@pushly/models/lib/structs/relative-date'
import { convertSeconds, convertToSeconds } from '../../../_utils/time'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { useState } from 'react'
import { Section } from './wrappers'
import { AppMessageAnimationsConfigBuilder } from './app-message-animations-config-builder'

export const AppMessageBehaviorBuilder: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const [context, dispatch] = useAppMessageBuilderContext()
    const { variants, selectedVariantIdx } = context
    const value = variants[selectedVariantIdx]
    // TODO: update in Schedules PR
    const behavior = value.schedules![0].behavior
    const maxDisplayTime = behavior.maxDisplayTime
    const redisplay = behavior.redisplay

    const [plainFcap, setPlainFcap] = useState(
        redisplay.enabled ? convertSeconds(redisplay.fcap!.intervalSeconds, redisplay.fcap!.displayMetric) : null,
    )

    const handleBehaviorChange = (change: Partial<MutableAppMessageBehavior>) => {
        const newBehavior: MutableAppMessageBehavior = behavior.clone(change)

        // custom handle null setting
        if (change.maxDisplayTime === null) {
            newBehavior.maxDisplayTime = null
        }

        dispatch({ type: 'patch', entity: 'behavior', data: newBehavior })
    }

    return (
        <Well className={getClassNames('app-message-behavior-builder', 'nested')} title="Behavior" hideFooter={true}>
            <Section hideHeader={true}>
                <div className="priority">
                    <span className="label-with-component-r">Priority</span>
                    <Select<AppMessagePriority>
                        value={value.priority}
                        defaultValue={AppMessagePriority.DEFAULT}
                        options={enumAsSelectOptions(AppMessagePriority, 'number')}
                        disabled={context.disabled}
                        onChange={(priority) => {
                            dispatch({ type: 'patch', entity: 'base', data: value.clone({ priority }) })
                        }}
                    />
                    <Popover
                        overlayClassName={getClassNames('app-message-builder-popover')}
                        content={
                            <>
                                <p>
                                    App Message priority determines which message appears first when you send multiple
                                    in-app messages. High priority will display first.
                                </p>
                            </>
                        }
                    >
                        <InfoCircleOutlined className="info-icon" />
                    </Popover>
                </div>

                <div className="auto-dismiss">
                    <Switch
                        size="small"
                        checked={!!behavior.maxDisplayTime}
                        disabled={context.disabled}
                        onChange={(enabled) => {
                            let mdt: AppMessageBehavior['maxDisplayTime'] = null
                            if (enabled) {
                                mdt = {
                                    intervalSeconds: 60,
                                    displayMetric: RelativeDateDisplayMetric.SECONDS,
                                } as RelativeDate
                            }

                            handleBehaviorChange({ maxDisplayTime: mdt })
                        }}
                    />
                    <span className="label-with-component-l-r">Auto dismiss after</span>
                    <Input
                        disabled={!behavior.maxDisplayTime || context.disabled}
                        type="number"
                        addonAfter="Seconds"
                        min={1}
                        max={120}
                        value={
                            maxDisplayTime
                                ? convertSeconds(maxDisplayTime!.intervalSeconds, maxDisplayTime!.displayMetric)
                                : undefined
                        }
                        onChange={(ev) => {
                            // maxDisplayTime can only be changed if it exists
                            handleBehaviorChange({
                                maxDisplayTime: {
                                    ...behavior.maxDisplayTime!,
                                    intervalSeconds: parseInt(ev.target.value, 10),
                                },
                            })
                        }}
                    />
                    <Popover
                        overlayClassName={getClassNames('app-message-builder-popover', 'image-popover')}
                        content={
                            <>
                                <p>
                                    This app message will be displayed in the application screen for the duration
                                    provided and will then be automatically removed from the view.
                                </p>
                            </>
                        }
                    >
                        <InfoCircleOutlined className="info-icon" />
                    </Popover>
                </div>

                <div className="allow-redisplay">
                    <Switch
                        size="small"
                        checked={behavior.redisplay.enabled}
                        disabled={context.disabled}
                        onChange={(enabled) => {
                            const rFcap = enabled
                                ? {
                                      intervalSeconds: convertSeconds(86400, RelativeDateDisplayMetric.DAYS),
                                      displayMetric: RelativeDateDisplayMetric.DAYS,
                                  }
                                : null

                            setPlainFcap(1)
                            handleBehaviorChange({ redisplay: redisplay.clone({ enabled, fcap: rFcap }) })
                        }}
                    />
                    <span className="label-with-component-l-r">Allow redisplay after</span>
                    <Input
                        disabled={!behavior.redisplay.enabled || context.disabled}
                        type="number"
                        addonAfter={
                            <Select<
                                Extract<
                                    RelativeDateDisplayMetric,
                                    RelativeDateDisplayMetric.HOURS | RelativeDateDisplayMetric.DAYS
                                >
                            >
                                defaultValue={RelativeDateDisplayMetric.DAYS}
                                options={enumAsSelectOptions(RelativeDateDisplayMetric).filter((opt) => {
                                    return (
                                        opt.value !== RelativeDateDisplayMetric.SECONDS &&
                                        opt.value !== RelativeDateDisplayMetric.MINUTES
                                    )
                                })}
                                disabled={context.disabled}
                                onChange={(metric) => {
                                    handleBehaviorChange({
                                        redisplay: {
                                            ...behavior.redisplay.clone({
                                                fcap: {
                                                    intervalSeconds: convertToSeconds(plainFcap!, metric),
                                                    displayMetric: metric,
                                                },
                                            }),
                                        },
                                    })
                                }}
                                value={
                                    (behavior.redisplay?.fcap?.displayMetric ??
                                        RelativeDateDisplayMetric.DAYS) as Extract<
                                        RelativeDateDisplayMetric,
                                        RelativeDateDisplayMetric.HOURS | RelativeDateDisplayMetric.DAYS
                                    >
                                }
                            />
                        }
                        min={1}
                        max={365}
                        value={behavior.redisplay.enabled && behavior.redisplay.fcap ? plainFcap ?? 1 : undefined}
                        onChange={(ev) => {
                            const val = parseInt(ev.target.value, 10)
                            // fcap only exists when this field is enabled
                            const rFcap = behavior.redisplay.fcap!.clone({
                                ...behavior.redisplay.fcap,
                                intervalSeconds: convertToSeconds(val, behavior.redisplay.fcap!.displayMetric),
                            })

                            setPlainFcap(val)
                            handleBehaviorChange({ redisplay: behavior.redisplay.clone({ fcap: rFcap }) })
                        }}
                    />
                    <Popover
                        overlayClassName={getClassNames('app-message-builder-popover', 'image-popover')}
                        content={
                            <>
                                <p>
                                    Determine how frequently an in app message is allowed to be displayed in a user
                                    application. Maximum 1 message per day (24 hours) as default.
                                </p>
                            </>
                        }
                    >
                        <InfoCircleOutlined className="info-icon" />
                    </Popover>
                </div>
            </Section>

            <AppMessageAnimationsConfigBuilder />
        </Well>
    )
}
