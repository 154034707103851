import * as React from 'react'
import { Select } from 'antd'
import { Modal } from '@pushly/aqe/lib/components'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Journey } from '../../../features/journeys/types/journey-api-response'

interface IRemoveStepConfirmationModal {
    changeSet?: any
    visible: boolean
    onConfirm: (node: Journey, remove?: boolean) => void
    onCancel: () => void
}

interface IState {
    removeSubs?: number
}

export class RemoveStepConfirmationModal extends React.Component<IRemoveStepConfirmationModal, IState> {
    public state: IState = {
        removeSubs: 0,
    }

    public render() {
        const { visible, changeSet } = this.props
        return (
            <Modal
                className="campaign-remove-step-modal"
                title={
                    <>
                        <QuestionCircleOutlined /> Removed Steps Behavior
                    </>
                }
                visible={visible}
                okText="Continue"
                onOk={() => {
                    this.props.onConfirm?.(changeSet.campaign, this.state.removeSubs === 1)
                }}
                cancelText="Cancel"
                onCancel={() => this.props.onCancel?.()}
            >
                <div className="campaign-remove-step-modal-body">
                    <div className="campaign-remove-step-modal-body-upper">
                        <p>
                            You are altering the flow of a running campaign. To continue you must choose whether
                            subscribers currently within deleted campaign steps are:
                        </p>
                        <Select
                            size="small"
                            onChange={(val) => this.setState({ removeSubs: val })}
                            defaultValue={this.state.removeSubs}
                        >
                            <Select.Option value={1}>Removed From Campaign</Select.Option>
                            <Select.Option value={0}>Moved to the Next Valid Step</Select.Option>
                        </Select>
                    </div>
                </div>
            </Modal>
        )
    }
}
