import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { Button, Drawer, Well } from '@pushly/aqe/lib/components'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import { Input, Skeleton, Tooltip } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import * as randomstring from 'randomstring'
import { StatusType } from '../../enums/status-type'
import { UserDto } from '../../dtos/user'
import { UserType } from '../../enums/user-type'
import { AppService, UserService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import { AppState } from '../../stores/app'
import { validateFields } from '../../_utils/antd'
import { simpleFormErrorNotification, simpleNotification } from '../../_utils/utils'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'
import { StatusBadge } from '../badges/status-badge'
import { AbilityAction } from '../../enums/ability-action.enum'
import { NoTranslate } from '../no-translate/no-translate'

interface IUserDetailsEditor extends FormComponentProps {
    user: UserDto
    visible: boolean
    onClose: (e: any) => void
    onUpdated: (account: Partial<UserDto>) => void
}

interface IUDEState {
    changes: boolean
}

export const UserDetailsEditor = Form.create<IUserDetailsEditor>()(
    class extends React.Component<IUserDetailsEditor, IUDEState> {
        public state: IUDEState = {
            changes: false,
        }

        protected appState: AppState
        protected appService: AppService
        protected userService: UserService

        public constructor(props: IUserDetailsEditor) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.userService = Container.get(UserService)
        }

        public render() {
            const { user } = this.props
            const currentValues = this.getCurrentValues()

            return (
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('user-details-editor')}
                    title="Edit User Information"
                    placement="right"
                    closable={true}
                    onSubmit={this.handleSubmit}
                    disableSubmit={Object.keys(currentValues).length === 0}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}>
                        <div>
                            <Form.Item label="Name" className={getClassNames('ude-name')}>
                                {this.props.form.getFieldDecorator('name', {
                                    initialValue: user.name,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Name is a required field.',
                                        },
                                        {
                                            pattern: new RegExp('^(?!.*<.*>).*'),
                                            message: 'Name cannot contain HTML tags.',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>

                            <Tooltip
                                placement="bottom"
                                title={'Please contact your account manager to request email address changes'}
                            >
                                <Form.Item label="Email" className={getClassNames('udf-details-editor-email')}>
                                    {this.props.form.getFieldDecorator('email', {
                                        initialValue: user.email,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Email is a required field.',
                                            },
                                            {
                                                type: 'email',
                                                message: 'A valid email address is required.',
                                            },
                                        ],
                                    })(<Input disabled={true} autoComplete={randomstring.generate()} />)}
                                </Form.Item>
                            </Tooltip>
                        </div>
                    </Form>
                </Drawer>
            )
        }

        protected get user(): UserDto {
            return this.props.user
        }

        protected getCurrentValues(): Partial<UserDto> {
            const formValues = this.props.form.getFieldsValue()
            const values: Partial<UserDto & { password: string }> = {}

            if (formValues.name && formValues.name !== this.user.name) {
                values.name = formValues.name
            }

            if (formValues.email && formValues.email.toLowerCase().trim() !== this.user.email) {
                values.email = formValues.email.toLowerCase().trim()
            }

            return values
        }

        protected handleSubmit = async () => {
            try {
                await validateFields(this.props.form)
                const values = this.getCurrentValues()

                const { ok, data } = await this.userService.updateById(this.props.user.id, values, {
                    showLoadingScreen: true,
                })

                if (ok) {
                    // removes <HIDDEN> obfuscation on API response
                    if (values.email) {
                        data!.email = values.email
                    } else {
                        data!.email = this.props.user.email
                    }

                    this.props.onUpdated(data!)

                    this.props.onClose(null)
                    simpleNotification('success', `User, ${this.props.user.name}, has been successfully updated.`)
                }
            } catch (err) {
                simpleFormErrorNotification(err)
            }
        }
    },
)

interface IUserDetails {
    user: UserDto | any
    loading: boolean
    onUpdated: (user: Partial<UserDto>) => void
    emptyUserState?: React.ReactNode
    domainId?: number
    accountId?: number
}

interface IUserDetailsState {
    showDrawer: boolean
    drawerKey?: string
}

export class UserDetailsWell extends React.Component<IUserDetails, IUserDetailsState> {
    protected appService: AppService
    protected appState: AppState
    protected userService: UserService

    public constructor(props: IUserDetails) {
        super(props)

        this.appService = Container.get(AppService)
        this.appState = Container.get(AppState)
        this.userService = Container.get(UserService)

        this.state = {
            showDrawer: false,
        }
    }

    public render() {
        return (
            <Well className="nested" title="Details" hideFooter={true} actions={this.buildActions()}>
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading &&
                        (!this.props.user && this.props.emptyUserState ? (
                            this.props.emptyUserState
                        ) : (
                            <>
                                {this.renderDetails()}

                                <UserDetailsEditor
                                    key={this.state.drawerKey}
                                    visible={this.state.showDrawer}
                                    user={this.props.user}
                                    onClose={this.handleDrawerClose}
                                    onUpdated={this.props.onUpdated}
                                />
                            </>
                        ))}
                </Skeleton>
            </Well>
        )
    }

    protected renderDetails(): React.ReactNode {
        const user = this.props.user

        let userTypeText = '--'
        let userTypeIcon = 'user'
        switch (user.userType) {
            case UserType.INTERNAL:
                userTypeText = 'Internal Platform User'
                break
            case UserType.INTERNAL_API:
                userTypeText = 'Internal API User'
                userTypeIcon = 'deployment-unit'
                break
            case UserType.EXTERNAL:
                userTypeText = 'External Platform User'
                break
            case UserType.EXTERNAL_API:
                userTypeText = 'External API User'
                userTypeIcon = 'deployment-unit'
                break
        }
        return (
            <Well title="User Information" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Email</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        <NoTranslate>{user.email}</NoTranslate>
                    </div>
                </div>

                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Name</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        <NoTranslate>{user.name}</NoTranslate>
                    </div>
                </div>

                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Current Status</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        <StatusBadge type="user" status={user.status} expanded={true} />
                    </div>
                </div>
            </Well>
        )
    }

    protected buildActions(): React.ReactNode {
        const user = this.props.user
        const actions: any[] = []
        const isSelfEdit = user && user.id === this.appState.currentUser?.id

        if (user) {
            const userDisabled = user.statusId === StatusType.DISABLED.id
            const showReInvite = this.props.accountId && user.statusId === StatusType.INVITED.id
            const showResetPassword = this.props.accountId && user.statusId !== StatusType.INVITED.id && user.isDBUser

            // Domain manager actions - user cannot update self and must be able to manage domain views
            const showDisableUser =
                !isSelfEdit &&
                this.appState.abilityStore.can(AbilityAction.UPDATE, this.appState.abilityStore.currentDomainIdentity)

            if (!isSelfEdit) {
                if (userDisabled) {
                    actions.push(
                        <span key="activate">
                            <Button size="small" onClick={this.activateUser} disabled={this.props.loading}>
                                <CheckCircleOutlined />
                                <span>Activate</span>
                            </Button>
                        </span>,
                    )
                } else {
                    if (showReInvite) {
                        actions.push(
                            <span key="reinvite">
                                <Button size="small" onClick={this.reInviteUser} disabled={this.props.loading}>
                                    <MailOutlined />
                                    <span>Re-Invite</span>
                                </Button>
                            </span>,
                        )
                    }
                    if (showResetPassword) {
                        actions.push(
                            <span key="resetpwd">
                                <Button size="small" onClick={this.forcePasswordReset} disabled={this.props.loading}>
                                    <LockOutlined />
                                    <span>Password Reset</span>
                                </Button>
                            </span>,
                        )
                    }
                    if (showDisableUser) {
                        actions.push(
                            <span key="deactivate">
                                <Button size="small" onClick={this.deactivateUser} disabled={this.props.loading}>
                                    <CloseCircleOutlined />
                                    <span>Deactivate</span>
                                </Button>
                            </span>,
                        )
                    }
                }
            }
        }

        if (actions.length > 0) {
            actions.push(<span key="spacer1" className="actions-spacer" />)
        }

        actions.push(
            <span key="edit">
                <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                    <EditOutlined />
                    <span>Edit</span>
                </Button>
            </span>,
        )

        return actions
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }

    protected deactivateUser = async () => {
        const response = await this.userService.updateById(
            this.props.user.id,
            { status: 'DISABLED' },
            {
                showLoadingScreen: true,
                cancellationKey: `disable-user-${this.props.user.id}`,
            },
        )

        if (response.ok) {
            this.props.onUpdated({
                statusId: response.data!.statusId,
                status: response.data!.status,
            })
        }
    }

    protected activateUser = async () => {
        const response = await this.userService.updateById(
            this.props.user.id,
            { status: 'ACTIVE' },
            {
                showLoadingScreen: true,
                cancellationKey: `enable-user-${this.props.user.id}`,
            },
        )

        if (response.ok) {
            this.props.onUpdated({
                statusId: response.data!.statusId,
                status: response.data!.status,
            })
        }
    }

    protected reInviteUser = async (): Promise<void> => {
        const response = await this.userService.triggerInviteForUserId(this.props.user.id, this.props.accountId!, {
            showLoadingScreen: true,
            cancellationKey: `reinvite-user-${this.props.user.id}`,
        })

        if (response.ok) {
            this.props.onUpdated({
                statusId: response.data.statusId,
                status: response.data.status,
            })
        }
    }

    protected forcePasswordReset = async (): Promise<void> => {
        const response = await this.userService.triggerPasswordResetForUserId(
            this.props.user.id,
            this.props.accountId!,
            {
                showLoadingScreen: true,
                cancellationKey: `resetpwd-user-${this.props.user.id}`,
            },
        )

        if (response.ok) {
            this.props.onUpdated({
                statusId: response.data.statusId,
                status: response.data.status,
            })
        }
    }
}
