import React from 'react'
import classnames from 'classnames'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import { Link } from 'react-router-dom'

type AppMenuItemProps = MenuItemProps & {
    linkTo: string
    title: string
    className?: string
}

export const MenuItemLabel = ({ linkTo, title, className }: AppMenuItemProps) => {
    const isExternalLink = /^http/i.test(linkTo)

    return (
        <div
            onClick={(event) => {
                if (!isExternalLink) {
                    event.stopPropagation()
                }
            }}
            className={classnames('app-menu-item', className)}
        >
            {!isExternalLink ? (
                <Link to={linkTo}>{title}</Link>
            ) : (
                <a href={linkTo} target="_blank" rel="noopener noreferrer">
                    {title}
                </a>
            )}
        </div>
    )
}
