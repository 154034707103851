import * as React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Tooltip } from 'antd'
import titleCase from 'title-case'
import { IBadgeProps } from './interfaces'
import './status-badge.scss'

type StatusBadgeType = 'account' | 'domain' | 'billing' | 'user' | 'generic'

/*
    "expanded" view forces hidden tooltip
    and displays the status text to the right
    of the badge
 */
interface IProps extends IBadgeProps {
    type: StatusBadgeType
    status: string
    expanded?: boolean
}

export const StatusBadge: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IProps>>> = (
    props,
) => {
    const hideTooltip = props.hideTooltip || props.expanded

    const text = props.status
    let icon = 'check-circle'
    let theme: 'filled' | undefined

    if (props.status === 'DISABLED') {
        icon = 'pause-circle'
    }

    if (props.type === 'billing') {
        switch (props.status) {
            case 'OPEN':
                icon = 'info-circle'
                break
            case 'PENDING':
                icon = 'clock-circle'
                break
            case 'UNCONFIRMED':
                icon = 'exclamation-circle'
                break
        }
    } else if (props.type === 'generic') {
        switch (props.status) {
            case 'EXPIRED':
                icon = 'exclamation-circle'
                break
        }
    } else if (props.type === 'user') {
        theme = 'filled'

        switch (props.status) {
            case 'INVITED':
                icon = 'mail'
                break
            case 'PASSWORD_RESET':
                icon = 'lock'
                break
            case 'DISABLED':
                icon = 'close-circle'
                break
        }
    }

    const badge = (
        <span className={`status-icon status-${text}`}>
            <LegacyIcon type={icon} theme={theme} />
        </span>
    )

    return (
        <span className={`status-badge ${props.type}-status-badge`}>
            {hideTooltip ? (
                !props.expanded ? (
                    badge
                ) : (
                    <>
                        {badge}
                        <span className="status-name">{titleCase(text)}</span>
                    </>
                )
            ) : (
                <Tooltip title={props.tooltip || text}>{badge}</Tooltip>
            )}
        </span>
    )
}
