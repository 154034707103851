import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { TemplateSelector } from '../../template-selector/template-selector'
import { DomainDto } from '../../../dtos/domain'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { Skeleton } from 'antd'
import TemplatizedTextField from '@pushly/aqe/lib/components/templatized-text-field/templatized-text-field'

type AppMessageDetailsBuilderProps = {
    domain: DomainDto
}
export const AppMessageDetailsBuilder = (props: AppMessageDetailsBuilderProps) => {
    const [context, dispatch] = useAppMessageBuilderContext()
    const { domain } = props

    const { details } = context

    const onChange = (update: any) => {
        dispatch({ type: 'patch', entity: 'details', data: { ...update, domain } })
    }

    const showTemplateSelector =
        context.type === 'message' && context.mode === 'create' && context.availableTemplates.length > 0

    return (
        <Well title="Details" hideFooter={true}>
            <Skeleton
                loading={context.loading || (context.type === 'message' && !context.initialAppMessageStateLoaded)}
                active={true}
                avatar={false}
                title={false}
            >
                <TemplatizedTextField
                    disabled={context.disabled}
                    size="middle"
                    hideMacros={true}
                    label="Name"
                    placeholder="App Message Name"
                    value={details.name}
                    limits={{ max: 100, maxConstraint: true }}
                    onChange={(nameVal) => {
                        let newVal: string | undefined = nameVal?.trim()

                        if (newVal === '') {
                            newVal = undefined
                        }

                        onChange({ name: nameVal })
                    }}
                />
                {context.type === 'template' && (
                    <>
                        <TemplatizedTextField
                            disabled={context.disabled}
                            size="middle"
                            hideMacros={true}
                            label="Description"
                            placeholder="App Message Description"
                            value={details.description}
                            limits={{ max: 255, maxConstraint: true }}
                            onChange={(descVal) => {
                                let newVal: string | undefined = descVal?.trim()

                                if (newVal === '') {
                                    newVal = undefined
                                }

                                onChange({ description: newVal })
                            }}
                        />
                    </>
                )}

                {showTemplateSelector && (
                    <TemplateSelector
                        disabled={context.disabled}
                        domain={domain}
                        config={context}
                        loading={!context.templatesLoaded}
                        wellMode="ghost"
                        onChange={(data) => dispatch({ type: 'patch', entity: 'named-template', data })}
                        value={!context.templatesLoaded ? undefined : context.selectedTemplateId}
                    />
                )}
            </Skeleton>
        </Well>
    )
}
