import * as React from 'react'
import { Route } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { AppService } from './services/index'
import { MobileRoot } from './mobile-view/root/mobile-root'
import { AppState } from './stores/app'
import { ModuleLoadingScreen } from './components/module-loading-screen/module-loading-screen'
import { Pufferfish } from './pufferfish'
import { PreAuthFeature } from './features/pre-auth/index'
import { CustomView, isMobile, isMobileOnly, MobileOnlyView, osName } from 'react-device-detect'
import './_utils/whitelabeling'
import './styles/globals.scss'
import * as bowser from 'bowser'
import { isApplicationVersionCurrent } from './_utils/is-application-version-current'
/**
 * using legacy airbrake-js library.
 * @airbrake/browser is currently broken and does not send annotations
 * { params, context, session, ... }
 */
import { streamApplicationError } from './_utils/airbrake'
import { PlatformIdentityContext } from './providers/platform-identity-provider/platform-identity-provider'
import { PlatformUser } from './providers/user-provider/user-provider'
import { WhitelabelConfigModel } from './models/whitelabel-config/whitelabel-config.model'
import { ErrorPage } from './components/error-page/error-page'

interface IProps {
    whiteLabel: WhitelabelConfigModel
    user: PlatformUser
}

interface IState {
    hasError: boolean
    error: any
    errorInfo: any
}

@observer
export class App extends React.Component<IProps, IState> {
    public static contextType = PlatformIdentityContext
    public context!: React.ContextType<typeof PlatformIdentityContext>

    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        }
    }

    public componentDidMount(): void {
        this.ensurePushlyHost()
        this.watchForVersionChange()
    }

    public componentDidCatch(error: any, info: any): void {
        this.setState({
            hasError: true,
            error,
            errorInfo: info,
        })

        this.handleApplicationError(error, info)
    }

    public render() {
        return (
            <Provider AbilityStore={this.appState.abilityStore}>
                <div id="pufferfish" className={this.getClassNames()} ref={this.setAppContainer}>
                    <ModuleLoadingScreen loading={this.appState.appIsLoading} />
                    {this.state.hasError ? (
                        <ErrorPage errorCode={500} error={this.state.error} info={this.state.errorInfo} />
                    ) : (
                        <>
                            <CustomView condition={!isMobileOnly} viewClassName="device-wrapper bv-wrapper">
                                <>
                                    <Route component={PreAuthFeature} />
                                    <Route component={Pufferfish} />
                                </>
                            </CustomView>
                            <MobileOnlyView viewClassName="device-wrapper mv-wrapper">
                                <>
                                    <Route component={PreAuthFeature} />
                                    <Route component={MobileRoot} />
                                </>
                            </MobileOnlyView>
                        </>
                    )}
                </div>
            </Provider>
        )
    }

    protected getClassNames(): string {
        const classNames: string[] = ['module', 'app', isMobile ? 'mv' : 'bv', osName.toLowerCase()]

        if (this.appState.isAuthenticated) classNames.push('authenticated')
        if (!this.appState.sidebar.collapsed) classNames.push('sidebar-open')

        classNames.push(bowser.name.toLowerCase())
        classNames.push(`${bowser.name.toLowerCase()}__${bowser.version}`)

        return classNames.join(' ')
    }

    protected setAppContainer = (element: any) => {
        this.appService.appContainerRef = element
    }

    protected ensurePushlyHost() {
        const platformRgx = /platform\.p-n\.io/i

        if (process.env.NODE_ENV === 'production' && platformRgx.test(location.href)) {
            window.location.href = location.href.replace(platformRgx, 'platform.pushly.com')
        }
    }

    protected watchForVersionChange = async () => {
        const isCurrent = await isApplicationVersionCurrent()
        if (isCurrent === false) {
            this.appState.isVersionCurrent = false
        } else {
            setTimeout(this.watchForVersionChange, 5 * 60 * 1000)
        }
    }

    protected handleApplicationError(error: any, info: any) {
        streamApplicationError(error, info, this.appState.currentUser, this.appState.currentDomain)
    }
}
