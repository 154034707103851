import * as React from 'react'
import './provider-list.scss'
import { FolderOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Dropdown, Form, Menu, Select, Table } from 'antd'
import { getClassNames } from '../../_utils/classnames'
import { Button, Well } from '@pushly/aqe/lib/components'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT, DEMO } from '../../constants'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'
import classnames from 'classnames'
import { Drawer } from '@pushly/aqe/lib/components/index'
import { IdentityProviderService } from '../../services/identity-provider.service'
import { useLoadableDataState, useService } from '@pushly/aqe/lib/hooks'
import { IdentityProviderDto } from '../../dtos/identity-provider.dto'
import { useState } from 'react'
import { OpenIdIdentityProviderModel } from '../../models/openid-identity-provider.model'
import { IdentityProviderTypeDto } from '../../dtos/identity-provider-type.dto'
import { IdentityProviderTypeService } from '../../services/identity-provider-type.service'
import deepEqual from 'react-fast-compare'
import { simpleNotification } from '../../_utils/utils'
import { OktaProviderForm } from './okta-provider-form'
import { GoogleWorkspaceProviderForm } from './google-workspace-provider-form'
import { Auth0ConnectionStrategy } from '../../enums/auth0-connection-strategy-type.enum'
import { IdpFormMode } from '../../enums/idp-form-modes.enum'
import { MicrosoftAzureADProviderForm } from './microsoft-azure-ad-provider-form'

interface IProps {
    accountId: number
}

function generateNewProviderModel(): OpenIdIdentityProviderModel {
    return OpenIdIdentityProviderModel.build({
        name: undefined,
        strategy: undefined,
        domain: undefined,
        client_id: undefined,
        client_secret: undefined,
        associated_email_domains: undefined,
    })
}

export const ProviderList = (props: IProps) => {
    const identityProviderService = useService(IdentityProviderService)
    const identityProviderTypeService = useService(IdentityProviderTypeService)

    const [showProviderDrawer, setShowProviderDrawer] = React.useState<boolean>(false)
    const [formMode, setFormMode] = React.useState<string>(IdpFormMode.CREATE)
    const [providers, setProviders] = useLoadableDataState<{ identityProviders: IdentityProviderDto[] }>({
        data: { identityProviders: [] },
    })
    const [providerTypes, setProviderTypes] = useLoadableDataState<{
        identityProviderTypes: IdentityProviderTypeDto[]
    }>({ data: { identityProviderTypes: [] } })
    const [loadingProvider, setLoadingProvider] = React.useState<boolean>(false)
    const [originalProvider, setOriginalProvider] = useState<any>(undefined)
    const [workingModel, setWorkingModel] = useState<OpenIdIdentityProviderModel>(generateNewProviderModel())
    const baseClassName = 'security-provider'

    const [form] = Form.useForm()

    const handleNewProviderClick = () => {
        const updated = workingModel.clone()
        updated.setStrategy(Auth0ConnectionStrategy.OKTA)
        setWorkingModel(updated)

        setShowProviderDrawer(true)
    }

    const handleDrawerClose = () => {
        setShowProviderDrawer(false)
        setFormMode(IdpFormMode.CREATE)
        setWorkingModel(generateNewProviderModel())
    }

    const getIdentityProviders = async () => {
        setProviders((state) => ({ ...state, loading: true }))

        const response = await identityProviderService.fetchByAccountId(props.accountId)
        setProviders({ loading: false, data: { identityProviders: response.data } })
    }

    const getIdentityProvider = async (idpId) => {
        setLoadingProvider(true)
        setFormMode(IdpFormMode.EDIT)
        setShowProviderDrawer(true)

        const updated = workingModel.clone()
        const response = await identityProviderService.fetchIdpById(props.accountId, idpId)

        updated.setStrategy(response.data.identityProviderType.auth0Strategy as Auth0ConnectionStrategy)
        updated.setId(response.data.id)
        updated.setName(response.data.name)
        updated.setDomain(response.data.domain)
        updated.setAssociatedEmailDomains(response.data.associatedEmailDomains)

        setOriginalProvider(updated)
        setWorkingModel(updated)
        setLoadingProvider(false)
    }

    const archiveIdentityProvider = async (idpId) => {
        const response = await identityProviderService.archive(props.accountId, idpId, {
            showLoadingScreen: true,
        })

        if (response) {
            await getIdentityProviders()
        }
    }

    async function save() {
        if (isValidIdp()) {
            const identityProviderDto = workingModel.serialize()
            const { ok, data: identityProvider } =
                formMode === IdpFormMode.CREATE
                    ? await identityProviderService.create(props.accountId, identityProviderDto, {
                          showLoadingScreen: true,
                      })
                    : await identityProviderService.edit(props.accountId, workingModel.getId()!, getCurrentValues(), {
                          showLoadingScreen: true,
                      })

            if (ok) {
                handleDrawerClose()
                await getIdentityProviders()
            }
        }
    }

    React.useEffect(() => {
        const getIdentityProviderTypes = async () => {
            setProviderTypes((state) => ({ ...state, loading: true }))

            const response = await identityProviderTypeService.fetchIdentityProviderTypes()
            setProviderTypes({ loading: false, data: { identityProviderTypes: response.data } })
        }

        getIdentityProviders()
        getIdentityProviderTypes()
    }, [])

    const renderActions = React.useCallback(
        (identityProvider: IdentityProviderDto) => {
            const actions: any[] = []

            actions.push({
                key: 'archive',
                text: 'Archive',
                icon: <FolderOutlined />,
            })

            return (
                <Dropdown.Button
                    size="small"
                    className="feeds-row-actions-btn"
                    onClick={() => getIdentityProvider(identityProvider.id)}
                    overlay={
                        <Menu
                            onClick={({ key, domEvent }) => {
                                handleRowAction(key, identityProvider, props.accountId, domEvent)
                            }}
                        >
                            {actions.map((item) => (
                                <Menu.Item key={`${item.key}`}>
                                    {item.icon}
                                    <span>{item.text}</span>
                                </Menu.Item>
                            ))}
                        </Menu>
                    }
                >
                    <span>Edit</span>
                </Dropdown.Button>
            )
        },
        [props.accountId],
    )

    function handleRowAction(
        key: string | number,
        idp: IdentityProviderDto,
        accountId: number,
        _domEvent: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
    ): void {
        if (key === 'archive') {
            archiveIdentityProvider(idp.id)
        }
    }

    function getCurrentValues(): Partial<OpenIdIdentityProviderModel> {
        const values: any = {}
        if (workingModel.getName() !== originalProvider?.getName()) {
            values.name = workingModel.getName()
        }

        if (!deepEqual(workingModel.getAssociatedEmailDomains(), originalProvider?.getAssociatedEmailDomains())) {
            values.associatedEmailDomains = workingModel.getAssociatedEmailDomains()
        }

        return values
    }

    const isValidIdp = React.useCallback((): boolean => {
        let validIdp = true

        if (!workingModel.getName()) {
            validIdp = false
            simpleNotification('error', 'Name is required')
        }

        if (!workingModel.getAssociatedEmailDomains() || workingModel?.getAssociatedEmailDomains()?.length === 0) {
            validIdp = false
            simpleNotification('error', 'Email Domains is required')
        }

        if (formMode === IdpFormMode.CREATE) {
            if (!workingModel.getDomain()) {
                validIdp = false
                simpleNotification('error', 'Okta Domain is required')
            }

            if (!workingModel.getClientId()) {
                validIdp = false
                simpleNotification('error', 'Client ID is required')
            }

            if (!workingModel.getClientSecret()) {
                validIdp = false
                simpleNotification('error', 'Client Secret is required')
            }
        }

        return validIdp
    }, [
        workingModel?.getName(),
        workingModel?.getDomain(),
        workingModel?.getClientId(),
        workingModel?.getClientSecret(),
        workingModel?.getAssociatedEmailDomains(),
    ])

    return (
        <div>
            <Well
                className={classnames(`${baseClassName}-list`, 'table-well', 'nested')}
                header={
                    <div className={classnames(`${baseClassName}-list-header`)}>
                        <div className={classnames(`${baseClassName}-list-title`)}>
                            <span>{'Single Sign-On / Identity Providers'}</span>
                        </div>

                        <div className={classnames(`${baseClassName}-list-actions`)}>
                            <Button size="middle" type="primary" onClick={handleNewProviderClick}>
                                <span>Add Provider</span>
                            </Button>
                        </div>
                    </div>
                }
                hideFooter={true}
            >
                <div className={classnames(`${baseClassName}-list-content`)}>
                    <Table
                        bordered={false}
                        className={classnames(`${baseClassName}-list-table`)}
                        dataSource={providers.data?.identityProviders}
                        loading={
                            providers.loading && {
                                indicator: <LoadingOutlined spin={true} />,
                            }
                        }
                        locale={{
                            emptyText: (
                                <AntdTableEmptyPlaceholder
                                    text="No identity providers have been added to this organization."
                                    icon={<InboxOutlined />}
                                />
                            ),
                        }}
                        pagination={false}
                        footer={(currentTableData) => <SwSimpleLinkPagination currentTableData={currentTableData} />}
                        rowKey={(idp) => idp.id}
                        size="small"
                    >
                        <Table.Column key="name" className="name" title="Name" dataIndex="name" />
                        <Table.Column
                            key="type"
                            className="type"
                            title="Type"
                            dataIndex={['identityProviderType', 'name']}
                        />
                        <Table.Column
                            align="right"
                            className="actions"
                            key="actions"
                            title="Actions"
                            render={renderActions}
                        />
                    </Table>
                </div>
            </Well>

            <Drawer
                visible={showProviderDrawer}
                className={getClassNames('add-provider')}
                title="Add SSO / Identity Provider"
                submitText={formMode === IdpFormMode.CREATE ? 'Add Provider' : 'Edit Provider'}
                onSubmit={save}
                onClose={handleDrawerClose}
                disableSubmit={formMode === IdpFormMode.EDIT && Object.keys(getCurrentValues()).length === 0}
            >
                <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT} className="hide-inline-errors" form={form}>
                    <div className={getClassNames('form-layout-row')}>
                        <Well title="Provider Type" mode="ghost" hideFooter={true} loading={loadingProvider}>
                            <Form.Item label="Type">
                                <Select<string>
                                    size="small"
                                    onChange={(v) => {
                                        const strategy = v
                                        const updated = workingModel.clone()
                                        updated.setStrategy(strategy as Auth0ConnectionStrategy)
                                        setWorkingModel(updated)
                                    }}
                                    value={
                                        providerTypes.data?.identityProviderTypes.find(
                                            (type) => type.auth0Strategy === workingModel.getStrategy(),
                                        )?.auth0Strategy
                                    }
                                    disabled={formMode === IdpFormMode.EDIT}
                                >
                                    (
                                    {providerTypes.data?.identityProviderTypes.map((type) => {
                                        return (
                                            <Select.Option key={type.name} value={type.auth0Strategy}>
                                                {type.name}
                                            </Select.Option>
                                        )
                                    })}
                                    );
                                </Select>
                            </Form.Item>
                        </Well>
                        {workingModel.getStrategy() === Auth0ConnectionStrategy.OKTA && (
                            <OktaProviderForm
                                workingModel={workingModel}
                                setWorkingModel={setWorkingModel}
                                loadingProvider={loadingProvider}
                                formMode={formMode}
                            />
                        )}
                        {workingModel.getStrategy() === Auth0ConnectionStrategy.GOOGLE && (
                            <GoogleWorkspaceProviderForm
                                workingModel={workingModel}
                                setWorkingModel={setWorkingModel}
                                loadingProvider={loadingProvider}
                                formMode={formMode}
                            />
                        )}
                        {workingModel.getStrategy() === Auth0ConnectionStrategy.AZUREAD && (
                            <MicrosoftAzureADProviderForm
                                workingModel={workingModel}
                                setWorkingModel={setWorkingModel}
                                loadingProvider={loadingProvider}
                                formMode={formMode}
                            />
                        )}
                    </div>
                </Form>
            </Drawer>
        </div>
    )
}
