import * as React from 'react'
import { AsyncButton } from '../async-button/async-button.component'
import { BetterComponent } from '../better-component/better-component'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc'
import { AccountService, AppService, DomainService } from '../../services'
import { KeywordManagerContext } from './keyword-manager-context'
import { KeywordManagerProps, KeywordManagerState } from './interfaces'
import { IServiceApiResponse } from '../../interfaces/service-api-response'
import { Drawer, Modal, Well } from '@pushly/aqe/lib/components'
import classnames from 'classnames'
import { KeywordManagerListHeader } from './keyword-manager-list-header'
import './keyword-manager.scss'
import { Input, Table } from 'antd'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { InboxOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { DomainKeyword } from '../../dtos/domain'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { getClassNames } from '../../_utils/classnames'
import { Form } from '@ant-design/compatible'
import * as randomstring from 'randomstring'
import { simpleNotification } from '../../_utils/utils'
import { BatchService } from '../../services/batch'
import * as deepEqual from 'react-fast-compare'
import * as clone from 'clone'
import { getTablePaginationConfig } from '../segment-list/helpers'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'

type FilterKey = keyof KeywordManagerState['filters']

type Props = KeywordManagerProps & {}

type State = KeywordManagerState & {}

export class KeywordManagerV2 extends BetterComponent<Props, State> {
    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService
    protected orgService: AccountService
    protected batchService: BatchService

    protected kwInputRef: any

    public constructor(props: Props) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.orgService = Container.get(AccountService)
        this.batchService = Container.get(BatchService)

        this.state = {
            level: this.props.level,
            levelDependenciesLoaded: false,
            org: null!,
            domain: null!,

            loading: false,
            keywords: [],
            dataSourceLoaded: false,
            dataSource: [],

            visibleKeywords: [],
            keywordsMeta: {},
            page: 1,
            pageSize: 20,
            sortedInfo: {
                columnKey: 'keyword.name',
                order: 'ascend',
            },
            selectedKeywords: [],

            filters: {
                search: '',
            },

            actions: {
                addKeywords: true,
                bulkKeywordActions: ['archive'],
                exportKeywords: true,
            },
        }
    }

    public async componentDidMount(): Promise<void> {
        await this.fetchOrganizationalDependencies()
        await this.fetchKeywords()
    }

    public render() {
        const { levelDependenciesLoaded, dataSourceLoaded, loading } = this.state

        const baseClassName = 'keyword-manager'
        const showTableLoadingAnimation = !levelDependenciesLoaded || !dataSourceLoaded || loading
        const currDataSource: DomainKeyword[] = this.state.dataSource

        const visibleIds = currDataSource?.map(this.getRowKey)
        const selectedVisibleIds = this.state.selectedKeywords
            .filter((kw) => visibleIds.includes(kw.id))
            .map((kw) => kw.id)

        return (
            <div className={`${baseClassName}`}>
                <KeywordManagerContext.Provider
                    value={{
                        ...this.state,
                        onFilterChange: this.onFilterChange,
                        onSearchClick: this.handleSearchClick,
                        onExportClick: this.handleExportClick,
                        onAddKeywordsClick: this.handleDrawerOpen,
                        onHandleBulkMenuClick: this.handleBulkMenuClick,
                    }}
                >
                    <Well
                        className={classnames(`${baseClassName}-list`, 'table-well', 'nested')}
                        header={<KeywordManagerListHeader hideSearch={false} />}
                        hideFooter={true}
                    >
                        <div className={classnames(`${baseClassName}-list-content`)}>
                            <Table
                                bordered={false}
                                className={classnames(`${baseClassName}-list-table`)}
                                dataSource={currDataSource}
                                loading={
                                    showTableLoadingAnimation && {
                                        indicator: <LoadingOutlined spin={true} />,
                                    }
                                }
                                locale={{
                                    emptyText: (
                                        <AntdTableEmptyPlaceholder text="No Keywords" icon={<InboxOutlined />} />
                                    ),
                                }}
                                pagination={false}
                                footer={(currentTableData) => (
                                    <SwSimpleLinkPagination
                                        currentTableData={currentTableData}
                                        links={this.state.paginationLinks}
                                        onPrev={async (_ev, _link, config) => {
                                            await this.setState({ page: config.current! })
                                            this.fetchKeywords()
                                        }}
                                        onNext={async (_ev, _link, config) => {
                                            await this.setState({ page: config.current! })
                                            this.fetchKeywords()
                                        }}
                                    />
                                )}
                                size="small"
                                rowKey={this.getRowKey}
                                rowSelection={
                                    currDataSource.length > 1
                                        ? {
                                              selectedRowKeys: selectedVisibleIds,
                                              onSelect: this.handleRowClick,
                                              hideSelectAll: false,
                                              onSelectAll: this.handleSelectedAll,
                                          }
                                        : undefined
                                }
                            >
                                <Table.Column
                                    key="keyword.name"
                                    className="keyword"
                                    title="Keyword"
                                    render={this.renderRowKeyword}
                                    sorter={true}
                                />
                                <Table.Column
                                    key="actions"
                                    className="actions"
                                    title="Actions"
                                    render={this.renderRowActions}
                                />
                            </Table>
                        </div>
                    </Well>
                </KeywordManagerContext.Provider>

                <Drawer
                    key={this.state.drawerKey}
                    visible={this.state.showKwDrawer}
                    className={getClassNames('add-kws')}
                    title="Add New Keywords"
                    submitText="Add Keywords"
                    onSubmit={this.handleAddNewKeywords}
                    onClose={this.handleDrawerClose}
                >
                    <div className={getClassNames('form-layout-row', 'add-kws-input-wrapper')}>
                        <Form.Item className={getClassNames('add-keywords-input')}>
                            <Input.TextArea ref={(el) => (this.kwInputRef = el)} name="kw_input" />
                            <div className={getClassNames('form-layout-row-sub')}>
                                <InfoCircleOutlined />
                                <span>Keywords must be entered one per line.</span>
                            </div>
                        </Form.Item>
                    </div>
                </Drawer>
            </div>
        )
    }

    protected renderRowKeyword(_: string, kw: DomainKeyword): React.ReactNode {
        return <TableRowEntityDisplay title={kw.name} />
    }

    protected renderRowActions = (_: any, kw: DomainKeyword): React.ReactNode => {
        return (
            <AsyncButton onClick={() => this.handleSingleArchive(kw)} size="small">
                <span>Archive</span>
            </AsyncButton>
        )
    }

    protected handleSearchClick = async (value: string) => {
        const prevFilter = this.state.filters.search

        await this.setState(({ filters }) => ({
            filters: {
                ...filters,
                search: value,
            },
        }))

        const emptyValues = !value.trim() && !prevFilter?.trim()
        if (!emptyValues) {
            this.fetchKeywords({
                page: 1,
            })
        }
    }

    protected onFilterChange = async <FilterValue extends KeywordManagerState['filters'][FilterKey]>(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void> => {
        const prevFilters = clone(this.state.filters)

        await this.setState(({ filters }) => ({
            filters: {
                ...filters,
                [key]: value,
            },
        }))

        if (!deepEqual(this.state.filters, prevFilters)) {
            this.fetchKeywords()
        }
    }

    protected handleRowClick = (kw: DomainKeyword) => {
        let currSelections = this.state.selectedKeywords
        if (currSelections.find((sel) => sel.id === kw.id)) {
            currSelections = currSelections.filter((keyword) => keyword.id !== kw.id)
        } else {
            currSelections.push(kw)
        }

        this.setState({ selectedKeywords: currSelections })
    }

    protected handleSelectedAll = (selected: boolean) => {
        const allCurr = this.state.dataSource
        const currSelections = this.state.selectedKeywords

        if (selected) {
            this.setState({ selectedKeywords: [...currSelections, ...allCurr] })
        } else {
            allCurr.map((kw) => {
                const idx = currSelections.findIndex((sel) => sel.id === kw.id)
                currSelections.splice(idx, 1)
            })

            this.setState({ selectedKeywords: [...currSelections] })
        }
    }

    protected get paginationConfig(): any {
        return getTablePaginationConfig({
            pageSize: this.state.pageSize,
            current: this.state.page,
            total: this.state.pageSize * this.state.page + 1,
            onChange: async (page: number) => {
                await this.setState({ page })
                await this.fetchKeywords()
            },
        })
    }

    protected handleDrawerOpen = () => {
        this.setState({
            showKwDrawer: true,
            drawerKey: randomstring.generate(),
        })
    }

    protected handleDrawerClose = () => {
        this.setState({
            showKwDrawer: false,
            drawerKey: undefined,
        })
    }

    protected handleAddNewKeywords = async () => {
        const val = this.kwInputRef?.resizableTextArea?.textArea?.value ?? ''
        let keywords = val
            .split(/\n/)
            .map((flag) => flag.toLowerCase().trim())
            .filter((kw) => !!kw && kw !== '')

        const domainId = this.appState.currentDomain!.id
        const res = await this.domainService.addKeywordsToDomain(domainId, keywords, {
            showLoadingScreen: true,
            cancellationKey: `add_domain_keywords_${domainId}`,
        })

        if (res.ok) {
            this.handleDrawerClose()
            simpleNotification('success', `Keywords successfully added.`)

            return this.fetchKeywords()
        }
    }

    protected handleSingleArchive = async (keyword: DomainKeyword) => {
        return this.handleBulkArchive([keyword])
    }

    protected handleBulkMenuClick = (e: any) => {
        if (e.key === 'action-archive') {
            this.confirmBulkArchive()
        }
    }

    protected confirmBulkArchive() {
        const selectedKeywords = clone(this.state.selectedKeywords ?? [])
        const totalSelected = selectedKeywords.length
        const itemText = totalSelected === 1 ? 'Keyword' : 'Keywords'

        Modal.confirm({
            title: 'Bulk Archive',
            okText: 'Yes, Archive',
            onOk: () => this.handleBulkArchive(selectedKeywords),
            content: (
                <div>
                    Are you sure you want to archive <b>{totalSelected}</b> {itemText}?
                    <p>
                        These keywords will no longer display as previously used keywords during notification creation.
                    </p>
                </div>
            ),
        })
    }

    protected handleBulkArchive = async (keywordsToArchive: any[]) => {
        await this.setState({ loading: true })

        const res = await this.domainService.archiveKeywords(
            this.appState.currentDomain!.id,
            keywordsToArchive.map((kw) => kw.id),
        )

        if (res.ok) {
            await this.fetchKeywords()
            return this.setState({ selectedKeywords: [], loading: false })
        }
    }

    protected handleExportClick = async () => {
        await this.domainService.fetchKeywordDoc(this.appState.currentDomain!.id, ``)
    }

    protected async fetchKeywords(opts?: any) {
        opts = opts ?? {}
        // start loading
        await this.setState({
            dataSourceLoaded: false,
            page: opts.page ?? this.state.page,
        })

        const state: any = {
            ...this.state,
            dataSourceLoaded: true,
        }

        let res: IServiceApiResponse<any[]> = { ok: false, data: [] }
        const options = {
            ...opts,
            showLoadingScreen: false,
            query: {
                page: this.state.page,
                limit: this.state.pageSize,
                search: this.state.filters.search,
            },

            rawOrder: true,
        }

        if (this.props.level === 'domain' && this.props.domainId) {
            res = await this.domainService.fetchKeywordsByDomainId(this.props.domainId, options)
        }

        if (res && res.ok) {
            state.dataSource = res.data
            state.paginationLinks = res.meta.links ?? state.paginationLinks
            state.keywordsMeta = res.meta
        }

        await this.setState(state)
    }

    protected getRowKey = (row: DomainKeyword) => {
        return row.id
    }

    protected async fetchOrganizationalDependencies() {
        let state: any = { levelDependenciesLoaded: false }
        await this.setState({ ...state })

        if (this.props.level === 'domain') {
            const { ok, data: domain } = await this.domainService.fetchById(this.props.domainId)
            if (ok && domain) {
                state.domain = domain
            }

            state.level = 'domain'
        } else {
            state.org = await this.orgService.fetchById(this.props.orgId)
        }

        state.levelDependenciesLoaded = true
        await this.setState(state)
    }
}
