import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { Input, Radio, Select, Skeleton } from 'antd'
import { MutableAppMessageConditions } from '@pushly/models/lib/structs/app-messages/app-message-conditions'
import { AppMessageConditionType } from '@pushly/models/lib/enums/app-messages/app-message-condition-type'
import { getClassNames } from '../../../_utils/classnames'
import titleCase from 'title-case'
import { RelativeDateDisplayMetric } from '@pushly/models/lib/enums/relative-date-display-metric'
import { PropertiesOf } from '@pushly/models/lib/types'
import { RelativeDate } from '@pushly/models/lib/structs/relative-date'
import { convertSeconds, convertToSeconds } from '../../../_utils/time'
import { LIFETIME_METRICS, SESSION_METRICS_FILTERED, SESSION_TRIGGER_TYPES } from '../constants'
import { ConditionDuration, SessionTriggerTypes } from '../types'
import './styles/app-message-conditions-builder.scss'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { useState } from 'react'

type AppMessageConditionsBuilderProps = {}

export const AppMessageConditionsBuilder: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<AppMessageConditionsBuilderProps>>
> = (props) => {
    const [context, dispatch] = useAppMessageBuilderContext()
    const { variants, selectedVariantIdx } = context

    const value = variants[selectedVariantIdx].schedules![0].conditions

    const localValue = value ?? new MutableAppMessageConditions()
    const [displayMetricOptions, setDisplayMetricOptions] = useState(SESSION_METRICS_FILTERED)
    const [plainTimeInAppSeconds, setPlainTimeInAppSeconds] = useState(
        !!localValue.timeInApp
            ? convertSeconds(localValue.timeInApp.intervalSeconds, localValue.timeInApp.displayMetric)
            : 60,
    )

    const handleConditionsChange = (change: Partial<MutableAppMessageConditions>) => {
        dispatch({ type: 'patch', entity: 'conditions', data: localValue.clone(change) })
    }

    const handleTimeInAppChange = (change: PropertiesOf<RelativeDate>) => {
        const timeInApp = RelativeDate.from(change as RelativeDate)
        handleConditionsChange({ timeInApp })
    }

    /**
     * Reset display options when type is updated, because discrepancy between options also default metric selected when not available
     */
    React.useEffect(() => {
        if (localValue.type === AppMessageConditionType.SESSION_TIME_IN_APP) {
            if (!SESSION_METRICS_FILTERED.includes(localValue.timeInApp!.displayMetric)) {
                handleTimeInAppChange({
                    ...localValue.timeInApp!,
                    displayMetric: RelativeDateDisplayMetric.MINUTES,
                })
            }
            setDisplayMetricOptions(SESSION_METRICS_FILTERED)
        } else if (localValue.type === AppMessageConditionType.LIFETIME_TIME_IN_APP) {
            setDisplayMetricOptions(LIFETIME_METRICS)
        }
    }, [value])

    return (
        <Well className={getClassNames('app-message-conditions-builder')} title="Conditions" hideFooter={true}>
            <Skeleton loading={context.loading} active={true} avatar={true} title={false}>
                <div className="conditions-builder-rows">
                    <div className="conditions-builder-row-trigger-types">
                        <span className="label-with-component-r">Display after</span>
                        <Radio.Group
                            buttonStyle="solid"
                            disabled={context.disabled}
                            onChange={(ev) => {
                                const triggerType = ev.target.value

                                let update: any = {}
                                switch (triggerType) {
                                    case SessionTriggerTypes.SESSION_START:
                                        update = {
                                            type: AppMessageConditionType.SESSION_START,
                                            timeInApp: {
                                                intervalSeconds: 60,
                                                displayMetric: RelativeDateDisplayMetric.SECONDS,
                                            },
                                        }
                                        break
                                    case SessionTriggerTypes.TIME_IN_APP:
                                        // Session type defaulted for time-in-app config below
                                        update = {
                                            type: AppMessageConditionType.SESSION_TIME_IN_APP,
                                            timeInApp: undefined,
                                        }

                                        break
                                    case SessionTriggerTypes.CUSTOM:
                                        update = {
                                            type: AppMessageConditionType.CUSTOM,
                                            timeInApp: undefined,
                                        }
                                        break
                                }

                                if (update.type === AppMessageConditionType.SESSION_TIME_IN_APP) {
                                    update.timeInApp = {
                                        intervalSeconds: 60,
                                        displayMetric: RelativeDateDisplayMetric.SECONDS,
                                    }
                                } else if (update.type !== AppMessageConditionType.SESSION_TIME_IN_APP) {
                                    update.timeInApp = undefined
                                }

                                handleConditionsChange(update)
                            }}
                            size="small"
                            defaultValue={
                                localValue.type === AppMessageConditionType.SESSION_TIME_IN_APP ||
                                localValue.type === AppMessageConditionType.LIFETIME_TIME_IN_APP
                                    ? 'TIME_IN_APP'
                                    : localValue.type ?? SESSION_TRIGGER_TYPES[0]
                            }
                        >
                            {Object.keys(SessionTriggerTypes).map((type) => (
                                <Radio.Button key={type} value={type}>
                                    {titleCase(type)}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>

                    {/*Config*/}
                    {(value?.type === AppMessageConditionType.SESSION_TIME_IN_APP ||
                        value?.type === AppMessageConditionType.LIFETIME_TIME_IN_APP) && (
                        <div className="conditions-builder-row-config time-in-app">
                            <span>When</span>
                            <Radio.Group
                                buttonStyle="solid"
                                disabled={context.disabled}
                                onChange={(ev) => {
                                    const sessionType: string = ev.target.value
                                    handleConditionsChange({
                                        type: AppMessageConditionType[sessionType + '_TIME_IN_APP'],
                                    })
                                }}
                                size="small"
                                defaultValue={localValue.type?.split('_', 1)[0] ?? 'SESSION'}
                            >
                                {Object.keys(ConditionDuration).map((type) => (
                                    <Radio.Button key={type} value={ConditionDuration[type]}>
                                        {titleCase(type)}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>

                            <span>duration has reached</span>
                            <Input
                                type="number"
                                addonAfter={
                                    <Select<RelativeDateDisplayMetric>
                                        defaultValue={RelativeDateDisplayMetric.SECONDS}
                                        value={localValue.timeInApp?.displayMetric ?? RelativeDateDisplayMetric.SECONDS}
                                        options={displayMetricOptions.map((opt) => ({
                                            value: opt,
                                            label: titleCase(opt),
                                        }))}
                                        disabled={context.disabled}
                                        onChange={(metric) => {
                                            handleTimeInAppChange({
                                                intervalSeconds: convertToSeconds(plainTimeInAppSeconds!, metric),
                                                displayMetric: metric,
                                            })
                                        }}
                                    />
                                }
                                value={
                                    localValue.timeInApp && localValue.timeInApp.intervalSeconds
                                        ? plainTimeInAppSeconds ?? 1
                                        : undefined
                                }
                                defaultValue={30}
                                min={1}
                                max={365}
                                disabled={context.disabled}
                                onChange={(ev) => {
                                    const val = parseInt(ev.target.value.trim(), 10)
                                    const intervalSeconds = convertToSeconds(
                                        val,
                                        localValue.timeInApp?.displayMetric ?? RelativeDateDisplayMetric.SECONDS,
                                    )
                                    setPlainTimeInAppSeconds(val)
                                    handleTimeInAppChange({
                                        intervalSeconds,
                                        displayMetric:
                                            localValue.timeInApp?.displayMetric ?? RelativeDateDisplayMetric.SECONDS,
                                    })
                                }}
                            />
                        </div>
                    )}

                    {value?.type === AppMessageConditionType.CUSTOM && (
                        <>
                            <div className="conditions-builder-row-config custom">
                                <Input.Group>
                                    <Input
                                        value={localValue.triggers?.key}
                                        addonBefore={<span>Key</span>}
                                        disabled={context.disabled}
                                        onChange={(ev) => {
                                            const key = ev.target.value.trim()
                                            handleConditionsChange({ triggers: { ...localValue.triggers, key } })
                                        }}
                                        placeholder="app_opened"
                                    />
                                    <Input
                                        value={localValue.triggers?.value}
                                        addonBefore={<span>Value</span>}
                                        disabled={context.disabled}
                                        onChange={(ev) => {
                                            const val = ev.target.value.trim()
                                            handleConditionsChange({ triggers: { ...localValue.triggers, value: val } })
                                        }}
                                        placeholder="5"
                                    />
                                </Input.Group>
                            </div>
                            <div className={getClassNames('form-layout-row')}>
                                <span className={getClassNames('form-layout-row-sub')}>
                                    Custom Triggers are represented with an evaluating key and the qualitative value of
                                    that key. Ex: Key: app_opened, Value: 5
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </Skeleton>
        </Well>
    )
}
