import React from 'react'

type NoTranslateComponent = React.FC<React.PropsWithChildren<React.PropsWithChildren<any>>> & {
    className: string
}

const NoTranslate: NoTranslateComponent = (props) => {
    return <span className="notranslate">{props.children}</span>
}

NoTranslate.className = 'notranslate'

export { NoTranslate }
