import * as React from 'react'
import { getHeader, getSubtitle } from './helpers'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { useJourneyContext } from '../../context'
import { JourneyStep } from '../../types/journey-nodes'

export const JourneyNodeLabel = ({ node, loading }: { node: JourneyStep; loading?: boolean }) => {
    const [state] = useJourneyContext()

    return (
        <div className="label">
            {state.journey && (
                <>
                    <span className="header">{getHeader(node)}</span>
                    <span className="subtitle">
                        {loading ? <Loading3QuartersOutlined spin={true} /> : getSubtitle(node, state.journey)}
                    </span>
                </>
            )}
        </div>
    )
}
