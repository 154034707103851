/**
 * extract is a shallow extraction utility
 *
 * single key:
 *  extract({ a: 1 }, 'a') -> <number> 1
 *  extract({ a: null }, 'a', 1) -> <number> 1
 *  extract({ a: 1 }, 'b') -> TypeError key does not exist
 *
 * mutli key:
 *  extract({ a: 1, b: '', c: true }, ['a', 'c']) -> <object> { a: 1, c: true }
 */
export function extract<T extends unknown, F extends keyof T>(source: T | undefined, field: F): T[F] | null
export function extract<T extends unknown, F extends keyof T, D extends T[F]>(
    source: T | undefined,
    field: F,
    defaultValue: D,
): D
export function extract<T extends unknown, F extends keyof T>(
    source: T | undefined,
    fields: F[],
): { [K in F]: T[F] | null }
export function extract<T extends unknown, F extends keyof T>(
    source: T | undefined,
    fields: F | F[],
    defaultValue?: T[F],
): T[F] | { [K in F]: T[F] | null } | null {
    const fieldsArr = Array.isArray(fields) ? fields : [fields]
    const response = fieldsArr
        .map((field) => [field, source?.[field] ?? null])
        .reduce((obj, [k, v]) => {
            obj[k] = v
            return obj
        }, {} as any) as { [K in F]: T[F] | null }

    return fieldsArr?.length === 1 ? response[fieldsArr[0]] ?? defaultValue ?? null : response
}

export function hasKey(obj: object, key: string, deep: boolean = false): boolean {
    let keyFound = false

    if (obj !== undefined && obj !== null && typeof obj === 'object') {
        const keys = Object.keys(obj)
        keyFound = keys.includes(key)

        if (!keyFound && deep) {
            for (const _key of keys) {
                const nested = obj[_key]
                if (typeof nested === 'object') {
                    keyFound = hasKey(nested, key, deep)
                    if (keyFound) {
                        break
                    }
                }
            }
        }
    }

    return keyFound
}

function _findKeyPath(obj: object, key: string, deep: boolean = false, _path: string = ''): string | undefined {
    let keyFound = false
    let path: string | undefined = _path

    if (obj !== undefined && obj !== null && typeof obj === 'object') {
        const keys = Object.keys(obj)
        keyFound = keys.includes(key)

        if (keyFound) {
            path = `${path}.${key}`
        } else if (deep) {
            for (const _key of keys) {
                const nested = obj[_key]
                if (typeof nested === 'object') {
                    const pathRgx = new RegExp(`\.${key}$`)
                    const nestedPath = _findKeyPath(nested, key, deep, `${path}.${_key}`)
                    if (!!nestedPath && pathRgx.test(nestedPath)) {
                        path = nestedPath
                        break
                    }
                }
            }
        }
    }

    return !path ? undefined : path.replace(/^\./, '')
}

export function findKeyPath(obj: object, key: string, deep: boolean = false) {
    return _findKeyPath(obj, key, deep)
}

export function isPromise(o: any): boolean {
    return typeof o === 'object' && typeof o.then === 'function'
}

// Polyfill for Object.fromEntries
export function objectFromEntries<T>(entries: Array<[string | number, T]>) {
    let obj: { [key: string]: T } = {}
    for (let [key, value] of entries) {
        obj[key] = value
    }
    return obj
}

/**
 * Niche use-case for mapping Object keys to an Array type.
 *
 * Currently, how the concept of Channels is handled throughout platform, swapping a stateful variable
 * for a data parameter.
 *
 * @param obj
 * @param negativeKeys
 */
export function extractBooleanKeys<T>(obj: T, negativeKeys: boolean = false): (keyof T)[] {
    /**
     *  Explicitly typing because Object.keys returns string[] - can interfere with typings across usages downstream.
     *
     *  Described in Typescript issue response:
     *  "This is intentional. Types in TS are open ended. So keysof will likely be less than all properties you would get at runtime."
     *  https://github.com/Microsoft/TypeScript/issues/12870
     */
    return (Object.keys(obj as any) as (keyof T)[]).filter((key) => (negativeKeys ? !obj[key] : obj[key]))
}

/**
 * Returns key-value object from source1 where the keys exist in source2
 *
 * @param source1
 * @param source2
 */
export function getObjectIntersection(source1: object, source2: object) {
    return objectFromEntries(
        Object.keys(source1)
            .filter((key) => key in source2)
            .map((key) => [key, source1[key]]),
    )
}

export function isJSONObjectType(obj: object): boolean {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}

/**
 * Simple ordering of object keys including nested object keys / arrays.
 * (Does not order objects within array).
 *
 * @param obj
 */
export const order = (obj: object) => {
    if (obj && typeof obj === 'object') {
        if (Array.isArray(obj)) {
            return obj.map(order)
        }

        const _o = {}
        for (const k of Object.keys(obj).sort()) {
            _o[k] = order(obj[k])
        }
        return _o
    }

    return obj
}
