import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { Handle, NodeProps, Position, useReactFlow } from '@xyflow/react'
import { ApiVersion } from '../../../../enums/api-version.enum'
import { useService } from '@pushly/aqe/lib/hooks'
import { NotificationService } from '../../../../services'
import { getEdgeId } from '../../helpers'
import { JourneyNodeIcon } from './journey-node-icon'
import { JourneyNodeLabel } from './journey-node-label'
import { JourneyBuilderMode, NodeType } from '../../enums'
import { getClassNames } from '../../../../_utils/classnames'
import { useJourneyContext } from '../../context'
import { parseLoadedVariant } from '../../../../components/notification-builder/helpers'
import { AppState } from '../../../../stores/app'
import { parseDomainNotificationBuildFromVariant } from '../../action-helpers'
import { JourneyNode } from '../../types/journey-nodes'

export const CustomNode = ({ id, data, targetPosition, sourcePosition }: NodeProps<JourneyNode>) => {
    const [state, _dispatch] = useJourneyContext()
    const { getNode } = useReactFlow<JourneyNode>()
    const appState = useService(AppState)
    const notifSvc = useService(NotificationService)
    const [loadingDetails, setLoadingDetails] = useState(false)

    const step = data.step

    // onComponentMount effect - fetch step details dependencies
    useEffect(() => {
        const getAction = async () => {
            setLoadingDetails(true)
            if (step.type === NodeType.ACTION) {
                const actionConfig = step.configuration
                const notificationId = actionConfig.params.notification_id

                if (notificationId) {
                    await notifSvc
                        .fetchNotificationById(state.domain.id, notificationId, {
                            query: { include_schedules: false },
                            version: ApiVersion.V4,
                        })
                        .then((notif) => {
                            if (notif) {
                                data.resetInitialState({
                                    ...step,
                                    configuration: {
                                        ...actionConfig,
                                        params: {
                                            title: notif?.template?.channels?.default?.title,
                                            notification: parseDomainNotificationBuildFromVariant(
                                                parseLoadedVariant(notif),
                                                appState.flags,
                                                state.domain,
                                            ),
                                            notification_id: notificationId,
                                        },
                                    },
                                })
                            }
                        })
                }
            }

            setLoadingDetails(false)
        }

        if (step.type === NodeType.ACTION) {
            getAction()
        }
    }, [])

    const onNodeClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
            const stepType = step.type
            if (
                state.mode === JourneyBuilderMode.READONLY ||
                stepType === NodeType.EXIT ||
                stepType === NodeType.TRIGGER ||
                stepType === NodeType.GLOBAL_TRIGGER
            ) {
                return
            }
            state.callbacks.onNodeClick(ev, getNode(id)!)
        },
        [state.mode, step.type],
    )

    return (
        <React.Fragment>
            {![NodeType.TRIGGER].includes(step.type) && (
                <Handle type="target" position={targetPosition ?? Position.Top} id={getEdgeId(id)} />
            )}
            <div
                className={getClassNames(null, 'custom-node', `type-${step.type}`, 'no-drag', {
                    interaction: state.mode !== JourneyBuilderMode.READONLY,
                })}
                onClick={onNodeClick}
            >
                <JourneyNodeIcon id={id} node={step} />
                <JourneyNodeLabel node={step} loading={loadingDetails} />
            </div>
            {step.type !== NodeType.EXIT && (
                <Handle type="source" position={sourcePosition ?? Position.Bottom} id={getEdgeId(id)} />
            )}
        </React.Fragment>
    )
}
