import * as React from 'react'
import { Modal } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { InfoCircleOutlined } from '@ant-design/icons'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { ShareReportPanel } from '../report-builder/report-builder'

interface ISRAMProps {
    action: string
    visible: boolean
    onCancel: () => void
    onOk: (report: any) => Promise<void>
    report: any
    getAppContainer?: any
    confirmLoading?: boolean
}

interface ISRAMState {}

export const SavedReportsActionsModal: React.FC<React.PropsWithChildren<ISRAMProps>> = ({ ...props }: ISRAMProps) => {
    const { action, visible, onCancel, onOk, report, getAppContainer, confirmLoading } = props

    return (
        <Modal
            visible={visible}
            className={getClassNames('saved-reports-actions-modal')}
            onCancel={onCancel}
            onOk={onOk}
            title={
                <span>
                    {action === 'Archive' && <InfoCircleOutlined style={{ color: '#faad14' }} />} {action} Report
                </span>
            }
            closable={true}
            getContainer={getAppContainer}
            confirmLoading={confirmLoading}
            footer={
                action === 'Archive' ? (
                    <>
                        <span>
                            <AsyncButton type="ghost" size="middle" onClick={onCancel}>
                                <span>Cancel</span>
                            </AsyncButton>
                        </span>
                        <span>
                            <AsyncButton size="middle" type="primary" onClick={() => onOk(report)}>
                                <span>Archive</span>
                            </AsyncButton>
                        </span>
                    </>
                ) : null
            }
        >
            <div>
                {action === 'Share' && (
                    <ShareReportPanel key={String(visible)} reportConfig={report.reportConfig} visible={visible} />
                )}
            </div>

            <div>
                {action === 'Archive' && (
                    <div className={getClassNames('archive-report-inner')}>
                        <div className={getClassNames('archive-display-upper')}>
                            <span>Are you sure you want to archive the following report?</span>
                            <span>
                                <b>{report.name}</b>
                            </span>
                            <span className="archive-display-text-lower">
                                This report will no longer be available in your saved reports list and will need to be
                                manually generated.
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    )
}
