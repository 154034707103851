import { Form, Input } from 'antd'
import React, { FC, ReactElement } from 'react'
import { PasswordPopover } from '../password-popover/password-popover'
import { PasswordValidators } from '../../_utils/password-validations'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'
import { UserDto } from 'dtos/user'
import { ValidationRule, WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'

type IPasswordFormProps = {
    user: UserDto
    form: WrappedFormUtils
    drawer?: boolean
}

const PasswordForm: FC<React.PropsWithChildren<React.PropsWithChildren<IPasswordFormProps>>> = ({
    user,
    form,
    drawer,
}): ReactElement => {
    const passwordRules: ValidationRule[] = user
        ? []
        : [
              {
                  required: true,
                  message: 'Password is required',
              },
          ]

    const passwordVerifyRules: ValidationRule[] = user
        ? []
        : [
              {
                  required: true,
                  message: 'Password verification is required',
              },
          ]

    const compareToFirstPassword = (rule: ValidationRule, value: string, callback: Function) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords do not match')
        } else {
            callback()
        }
    }

    return (
        <>
            <Form.Item label="Password" {...(drawer ? DEFAULT_DRAWER_FORM_ITEM_LAYOUT : null)}>
                <PasswordPopover password={form.getFieldValue('password')}>
                    {form.getFieldDecorator('password', {
                        rules: passwordRules.concat([
                            {
                                validator: new PasswordValidators(user).validate,
                            },
                        ]),
                    })(<Input.Password />)}
                </PasswordPopover>
            </Form.Item>
            <Form.Item label="Verify Password" {...(drawer ? DEFAULT_DRAWER_FORM_ITEM_LAYOUT : null)}>
                {form.getFieldDecorator('passwordVerify', {
                    rules: passwordVerifyRules.concat([{ validator: compareToFirstPassword }]),
                })(<Input.Password placeholder="Verify password" />)}
            </Form.Item>
        </>
    )
}

export { PasswordForm }
