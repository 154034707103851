import React, { useCallback, useEffect, useState } from 'react'
import {
    applyEdgeChanges,
    applyNodeChanges,
    BaseEdge,
    EdgeLabelRenderer,
    EdgeProps,
    getBezierPath,
    getSimpleBezierPath,
    getSmoothStepPath,
    useReactFlow,
} from '@xyflow/react'
import { ClockCircleOutlined, ShoppingCartOutlined, WifiOutlined } from '@ant-design/icons'
import './custom-edge.scss'
import { getClassNames } from '../../../../_utils/classnames'
import { Button } from 'antd'
import { getAddNodeMutations } from '../../helpers'
import { useJourneyContext } from '../../context'
import { AddNodeType, JourneyEdge, JourneyNode } from '../../types/journey-nodes'
import { ActionType, JourneyBuilderMode, NodeType, TriggerType } from '../../enums'
import { EdgeAction } from './edge-action'
import { InvalidConnectionLabel } from './invalid-connection-label'
import { safeEnumFromValue } from '../../../../_utils/enum'
import { DeliveryChannel } from '@pushly/models/lib/enums/delivery-channels.enum'
import { JourneyAction } from '../../types/journey-context'

export const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    source,
    target,
    data,
    ...props
}: EdgeProps<JourneyEdge>) => {
    // region Custom Edge Definitions

    const [state, dispatch] = useJourneyContext()
    const { getNode, getNodes, setNodes, setEdges } = useReactFlow<JourneyNode>()

    const [edgeActionOptions, setEdgeActionOptions] = useState<AddNodeType[]>([NodeType.DELAY])
    const [showEdgeActions, setShowEdgeActions] = useState(false)
    const [showInvalidConnection, setShowInvalidConnection] = useState(false)

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })

    const buildEdgeActionNodeButtons = () => {
        const actionNodes: React.ReactNode[] = []
        edgeActionOptions.forEach((actionNode) => {
            switch (actionNode) {
                case ActionType.SEND_CART_NOTIFICATION:
                    actionNodes.push(
                        <Button
                            size="small"
                            type="primary"
                            shape="circle"
                            className="journey-edge-action-send_cart_notification"
                            onClick={() => handleAddNode(ActionType.SEND_CART_NOTIFICATION)}
                        >
                            <ShoppingCartOutlined />
                        </Button>,
                    )
                    break
                case ActionType.SEND_NOTIFICATION:
                    actionNodes.push(
                        <Button
                            size="small"
                            type="primary"
                            shape="circle"
                            className="journey-edge-action-send_notification"
                            onClick={() => handleAddNode(ActionType.SEND_NOTIFICATION)}
                        >
                            <WifiOutlined />
                        </Button>,
                    )
                    break
                case NodeType.DELAY:
                    actionNodes.push(
                        <Button
                            size="small"
                            type="primary"
                            shape="circle"
                            className="journey-edge-action-delay"
                            onClick={() => handleAddNode(NodeType.DELAY)}
                        >
                            <ClockCircleOutlined />
                        </Button>,
                    )
                    break
                default:
                    break
            }
        })

        return actionNodes
    }

    // endregion
    // region Interaction Handlers

    const handleAddNode = useCallback(
        (nodeType: AddNodeType) => {
            const sourceNode = getNode(source)
            const targetNode = getNode(target)

            if (sourceNode && targetNode) {
                const [nodeChanges, edgeChanges, newNode] = getAddNodeMutations(
                    nodeType,
                    getNodes(),
                    getNode(source)!,
                    getNode(target)!,
                )

                setNodes((nodes) => applyNodeChanges(nodeChanges, nodes))
                setEdges((edges) => applyEdgeChanges(edgeChanges, edges))

                dispatch({ action: JourneyAction.ON_NODE_ADD, data: newNode! })
            }
        },
        [source, target],
    )

    // endregion

    // region onComponentMount effect
    useEffect(() => {
        const getAvailableActionNodeOptions = () => {
            const currOptions = Array.from(edgeActionOptions)

            const triggerType: TriggerType = safeEnumFromValue(
                TriggerType,
                state.journey?.configuration?.computed_trigger_type ?? TriggerType.SUBSCRIBED,
            )

            if (triggerType === TriggerType.SUBSCRIBED) {
                currOptions.push(ActionType.SEND_NOTIFICATION)
            }

            // currently Abandoned Cart/Browse only supported ecomm campaign triggers
            const ecommTriggers = [TriggerType.ABANDONED_CART, TriggerType.ABANDONED_BROWSE]
            if (ecommTriggers.includes(triggerType)) {
                currOptions.push(ActionType.SEND_CART_NOTIFICATION)
            }

            setEdgeActionOptions(currOptions)
        }

        getAvailableActionNodeOptions()
    }, [state.journey?.id])

    // watch for invalid connections and show UI warning when node->node incompatibility
    useEffect(() => {
        const sourceNode = getNode(source)
        const targetNode = getNode(target)
        if (sourceNode && targetNode) {
            const sourceType = sourceNode.data.step.type
            const targetType = targetNode.data.step.type

            const invalidDelayConnections = sourceType === NodeType.DELAY && targetType === NodeType.DELAY
            const invalidActionConnections = sourceType === NodeType.ACTION && targetType === NodeType.ACTION
            const invalidNodeType: NodeType | null = invalidDelayConnections
                ? NodeType.DELAY
                : invalidActionConnections
                ? NodeType.ACTION
                : null

            if (invalidNodeType) {
                setShowInvalidConnection(true)
            } else {
                if (showInvalidConnection) {
                    setShowInvalidConnection(false)
                }
            }
        }
    }, [source, target])

    // endregion

    return (
        <>
            <BaseEdge id={id} path={edgePath} markerEnd={props.markerEnd} markerStart={props.markerStart} />
            <EdgeLabelRenderer>
                {showInvalidConnection && (
                    <div
                        className="journey-edge-invalid-label"
                        style={{
                            transform: `translate(-225%, -50%) translate(${labelX + 20}px, ${labelY}px)`,
                        }}
                    >
                        <InvalidConnectionLabel
                            sourceType={getNodes().find((n) => n.id === source)?.data?.data?.type!}
                        />
                    </div>
                )}
                <div
                    className={getClassNames(null, 'journey-edge-custom', {
                        open: showEdgeActions,
                        interactive: state.mode !== JourneyBuilderMode.READONLY,
                    })}
                    style={{
                        transform: `translate(-50%, -50%) translate(${labelX + 20}px, ${labelY}px)`,
                    }}
                    onMouseLeave={() => setShowEdgeActions(false)}
                >
                    <div
                        className={getClassNames('journey-edge-actions', {
                            open: showEdgeActions,
                        })}
                    >
                        <EdgeAction visible={showEdgeActions} onClick={() => setShowEdgeActions(!showEdgeActions)} />
                        <div className="journey-edge-action-row">
                            <div className="journey-edge-action-options">{...buildEdgeActionNodeButtons()}</div>
                        </div>
                    </div>
                </div>
            </EdgeLabelRenderer>
        </>
    )
}
