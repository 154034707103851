import * as React from 'react'
import { Section } from './wrappers'
import { Popover, Select, Switch } from 'antd'
import { PropertiesOf } from '@pushly/models/lib/types'
import { DisplayAnimationConfig } from '@pushly/models/lib/structs/display-animation-configuration'
import { AnimationType } from '@pushly/models/lib/enums/app-messages/app-message-animation-type'
import { getEnumKeys } from '../../../_utils/antd'
import { useAppMessageBuilderContext } from '../../../features/app-messages/context'
import { DisplayAnimationBehavior } from '@pushly/models/lib/structs/animation-behavior'
import {
    ANIMATION_DISPLAY_NAME_MAP,
    DEFAULT_IAM_ANIMATION_DURATION_MS,
    DEFAULT_IAM_ENTER_ANIMATIONS,
    DEFAULT_IAM_EXIT_ANIMATIONS,
} from '../constants'
import { AnimationEffect } from '../types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getClassNames } from '../../../_utils/classnames'
import { testToTest } from '../reducer'

export function getAnimationTypeOptions(time: AnimationEffect) {
    return getEnumKeys(AnimationType, 'string').map((at) => ({
        value: AnimationType[at],
        label: ANIMATION_DISPLAY_NAME_MAP[time][AnimationType[at]],
    }))
}

// using an array does not guarantee order - initially all animations have FADE added, if only FADE present use as value
export function getAnimationValue(animations: AnimationType[] | undefined): undefined | AnimationType {
    if (!animations) return

    if (animations.length > 1) {
        return animations.filter((a) => a !== AnimationType.FADE)[0]
    }

    return animations[0]
}

export function AppMessageAnimationsConfigBuilder() {
    const [context, dispatch] = useAppMessageBuilderContext()
    const { variants, selectedVariantIdx } = context
    const value = variants[selectedVariantIdx]

    const behavior = value.schedules![0].behavior
    const displayAnimations = behavior.displayAnimations
    const { enter, exit } = displayAnimations

    const handleAnimationChange = (change: Partial<DisplayAnimationBehavior>) => {
        testToTest()
        dispatch({
            type: 'patch',
            entity: 'behavior',
            data: behavior.clone({ displayAnimations: displayAnimations.clone(change) }),
        })
    }

    const handleAnimationTypeChange = (type: AnimationType, effect: AnimationEffect) => {
        let animations = exit.animations
        if (animations?.length && type === AnimationType.FADE) {
            animations = [AnimationType.FADE]
        } else {
            // all animations default to ending with FADE to ensure a smooth visual
            animations = [type, AnimationType.FADE]
        }

        const updateEffect = effect === AnimationEffect.Enter ? enter : exit
        handleAnimationChange({ [effect]: updateEffect.clone({ animations }) })
    }

    return (
        <Section
            title={
                <>
                    <span className="label-with-component-r">Animation</span>
                    <Popover
                        overlayClassName={getClassNames('animation-config-builder-title-popover')}
                        content="Exit/Out animations are only shown when a user clicks a dismiss action, the dedicated
                    close button (if enabled),  or when the auto dismiss time is enabled and has expired"
                        children={<InfoCircleOutlined />}
                    />
                </>
            }
        >
            <div className="animation-enter">
                <Switch
                    className="animation-enter-switch"
                    size="small"
                    checked={enter.enabled}
                    disabled={context.disabled}
                    onChange={(enabled) => {
                        const change: Partial<PropertiesOf<DisplayAnimationConfig>> = { enabled }
                        // values not set in builder - start with defaults
                        if (enabled && !enter.animations?.length) {
                            change.animations = DEFAULT_IAM_EXIT_ANIMATIONS
                            change.durationMilliseconds = DEFAULT_IAM_ANIMATION_DURATION_MS
                        }
                        testToTest()

                        handleAnimationChange({ enter: enter.clone(change) })
                    }}
                />
                <span className="label-with-component-l-r">Animate entry using</span>
                <Select<AnimationType>
                    disabled={!enter.enabled}
                    defaultValue={AnimationType.FADE}
                    value={getAnimationValue(enter.animations!)}
                    options={getAnimationTypeOptions(AnimationEffect.Enter)}
                    onChange={(type) => handleAnimationTypeChange(type, AnimationEffect.Enter)}
                />
            </div>

            <div className="animation-exit">
                <Switch
                    size="small"
                    checked={exit.enabled}
                    disabled={context.disabled}
                    onChange={(enabled) => {
                        const change: Partial<PropertiesOf<DisplayAnimationConfig>> = { enabled }
                        // values not set in builder - start with defaults
                        if (enabled && !exit.animations?.length) {
                            change.animations = DEFAULT_IAM_ENTER_ANIMATIONS
                            change.durationMilliseconds = DEFAULT_IAM_ANIMATION_DURATION_MS
                        }

                        handleAnimationChange({ exit: exit.clone(change) })
                    }}
                />
                <span className="label-with-component-l-r">Animate exit using</span>
                <Select<AnimationType>
                    disabled={!exit.enabled}
                    defaultValue={AnimationType.FADE}
                    value={getAnimationValue(exit.animations!)}
                    options={getAnimationTypeOptions(AnimationEffect.Exit)}
                    onChange={(type) => handleAnimationTypeChange(type, AnimationEffect.Exit)}
                />
            </div>
        </Section>
    )
}
