import { NotificationVariantModel } from '../../models/notification/notification-variant.model'
import { DomainDto } from '../../dtos/domain'
import { NotificationSource } from '../../enums/notification-source'
import { FEAT_AUTO_KW_DISCOVERY } from '../../constants'
import { getDefaultNotificationActions, getEnabledDeliveryChannels } from '../../_utils/domain'
import { NotificationDeliverySpec } from '../notifications/dtos/notification-schedule-dto'
import { safeEnumFromValue } from '../../_utils/enum'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { IosBadgeBehaviorAction, NotificationBuilderSubmitType } from '../../components/notification-builder/enums'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationAudienceModel } from '../../models/notification/notification-audience.model'
import FlagList from '../../structs/flags-list'
import { BadgeBehaviorModel } from '../../models/notification/notification-native-ios-content.model'
import {
    NotificationAudiencePayload,
    NotificationPayload,
    NotificationPersonalPreviewPayload,
    NotificationTeamPreviewPayload,
    ResourceContext,
} from './types/notification-payload'

// region NotificationPayload helpers

export function buildNotificationAudiencePayload(
    audience: NotificationAudienceModel,
    submitType: NotificationBuilderSubmitType.TEAM_PREVIEW,
): NotificationTeamPreviewPayload
export function buildNotificationAudiencePayload(
    audience: NotificationAudienceModel,
    submitType: NotificationBuilderSubmitType.PERSONAL_PREVIEW,
): NotificationPersonalPreviewPayload
export function buildNotificationAudiencePayload(
    audience: NotificationAudienceModel,
    submitType: NotificationBuilderSubmitType.STANDARD,
    context: ResourceContext,
): NotificationAudiencePayload
export function buildNotificationAudiencePayload(
    audience: NotificationAudienceModel,
    submitType: NotificationBuilderSubmitType,
    context?: ResourceContext,
): NotificationAudiencePayload | NotificationPersonalPreviewPayload | NotificationTeamPreviewPayload {
    const payload: NotificationAudiencePayload = {}

    if (submitType === NotificationBuilderSubmitType.PERSONAL_PREVIEW) {
        return { personalPreview: true } satisfies NotificationPersonalPreviewPayload
    } else if (submitType === NotificationBuilderSubmitType.TEAM_PREVIEW) {
        return { teamPreview: true } satisfies NotificationTeamPreviewPayload
    } else {
        if (!context) {
            throw new Error('Domain|Account context must be provided to build a notification audience payload')
        }

        if (context?.level === 'org') {
            payload.domainIds = audience.getIsAll() ? context.account.domains.map((d) => d.id) : audience.getDomainIds()
            payload.segmentIds = audience.getSegmentIds()
        } else {
            payload.excludedSegmentIds = audience.getExcludedSegmentIds()
            payload.segmentIds = audience.getSegmentIds()
        }
    }

    return payload
}

export const buildNotificationActionsPayload = (variant: NotificationVariantModel, domain: DomainDto) => {
    const defaultContent = variant.getContent().getDefaultContent()
    let actions: any[] | undefined = defaultContent.getActions()?.map((action) => ({
        id: action.getId(),
        ordinal: action.getOrdinal(),
        type: action.getType(),
        label: action.getLabel(),
        landingUrl: action.getLandingUrl(),
        usePrimaryLandingUrl: action.getUsePrimaryLandingUrl(),
    }))

    if (defaultContent.getCustomActionsEnabled() && (actions?.length ?? 0) === 0) {
        actions = getDefaultNotificationActions(domain)
    }

    return actions
}

export const parseDomainNotificationBuildFromVariant = (
    variant: NotificationVariantModel,
    flags: FlagList,
    domain: DomainDto,
): NotificationPayload => {
    const audience = variant.getAudience()
    const content = variant.getContent()
    const defaultContent = content.getDefaultContent()
    const webContent = content.getWebContent()
    const nativeIosContent = content.getNativeIosContent()
    const nativeAndroidContent = content.getNativeAndroidContent()
    const exp = content.getDefaultContent().getExperienceOptions()
    const delivery = variant.getDelivery()

    const autoKwFlag = flags.findActive(FEAT_AUTO_KW_DISCOVERY)?.getKey()
    const hasAutoKwDiscoveryFlag = autoKwFlag && domain.flags?.includes(autoKwFlag)

    const channels = getEnabledDeliveryChannels(domain)
    const audiencePayload = buildNotificationAudiencePayload(audience, NotificationBuilderSubmitType.STANDARD, {
        level: 'domain',
        domain,
    })

    // Delivery
    const deliverySpec = NotificationDeliverySpec.fromSpec({
        type: safeEnumFromValue(NotificationDeliveryType, delivery.getType()),
        window: safeEnumFromValue(NotificationDeliveryWindow, delivery.getWindow()),
        sendDateUtc: new Date(delivery.getSendDate()!),
        timezone: delivery.getTimeZone(),
        ttlSeconds: exp.getTtlSeconds(),
        ttlMetric: exp.getTtlMetric(),
    })

    let actions: any[] | undefined = buildNotificationActionsPayload(variant, domain)

    // actions from UI are the same across applicable channels
    const templateActions = defaultContent.getCustomActionsEnabled() ? actions : undefined
    const actionsDisplayMeta = {
        override_default_actions: defaultContent.getCustomActionsEnabled(),
    }

    // build default template
    const template: NotificationPayload['template'] = {
        channels: {
            default: {
                channels,
                title: defaultContent.getTitle()!,
                body: defaultContent.getBody(),
                landingUrl:
                    defaultContent.getLandingUrl() === '' || defaultContent.getLandingUrl() === undefined
                        ? null
                        : defaultContent.getLandingUrl()!,
                imageUrl: defaultContent.getImageUrl(),
                iconUrl: defaultContent.getIconUrl(),
                contentWebhookUrl: defaultContent.getContentWebhookUrl(),
                overrideFallbackTemplate: defaultContent.getOverrideFallbackTemplate(),
                badgeUrl: defaultContent.getBadgeUrl(),
                isUsingDomainDefaultIcon: defaultContent.getIsUsingDomainDefaultIcon(),
                isUsingDomainDefaultBadge: defaultContent.getIsUsingDomainDefaultBadge(),
                overrideDefaultActions: defaultContent.getCustomActionsEnabled(),
                autoSuggestedFieldsResults: defaultContent.getAutoSuggestedFieldsResults(),
                actions: templateActions,
                isSilent: exp.getIsSilent() ?? false,
                requireInteraction: exp.getRequireInteraction() ?? false,
            },
        },
        keywords: defaultContent.getKeywords() ?? [],
        auto_keyword_discovery: hasAutoKwDiscoveryFlag ? true : undefined,
    }

    // build ios content
    if (channels.includes(DeliveryChannel.NATIVE_IOS)) {
        let badgeBehavior: BadgeBehaviorModel | null = nativeIosContent.getBadgeBehavior() ?? null
        if (!badgeBehavior || badgeBehavior.getAction() === IosBadgeBehaviorAction.DO_NOTHING) {
            badgeBehavior = null
        }

        template.channels.nativeIos = {
            landingUrl: defaultContent.getNativeAltUrl() ?? null,
            category: nativeIosContent.getCategory() ?? null,
            subtitle: nativeIosContent.getSubtitle() ?? null,
            sound: nativeIosContent.getSound() ?? null,
            interruptionLevel: nativeIosContent.getInterruptionLevel() ?? null,
            relevanceScore: nativeIosContent.getRelevance() ?? null,
            targetContentId: nativeIosContent.getTargetContentId() ?? null,
            title: nativeIosContent.getTitle() ?? null,
            body: nativeIosContent.getBody() ?? null,
            iconUrl: nativeIosContent.getIconUrl() ?? null,
            imageUrl: nativeIosContent.getImageUrl() ?? null,
            badgeBehavior,
        }
    }

    // build native android content
    if (channels.includes(DeliveryChannel.NATIVE_ANDROID)) {
        template.channels.nativeAndroid = {
            landingUrl: defaultContent.getNativeAltUrl() ?? null,
            title: nativeAndroidContent.getTitle() ?? null,
            body: nativeAndroidContent.getBody() ?? null,
            iconUrl: nativeAndroidContent.getIconUrl() ?? null,
            imageUrl: nativeAndroidContent.getImageUrl() ?? null,
            channelId: nativeAndroidContent.getChannel() ?? null,
            // actions from UI are built via default -- eventually will decouple
            actions: templateActions,
            displayMeta: {
                ...actionsDisplayMeta,
            },
        }
    }

    // build web content
    if (channels.includes(DeliveryChannel.WEB)) {
        template.channels.web = {
            // currently no separation between default and current
            landingUrl: defaultContent.getLandingUrl() ?? null,
            title: webContent.getTitle() || null,
            body: webContent.getBody()?.trim() || null,
            imageUrl: webContent.getImageUrl()?.trim() ?? null,
            iconUrl: webContent.getIconUrl()?.trim() ?? null,
            // actions from UI are built via default -- eventually will decouple
            actions: templateActions,
            displayMeta: {
                ...actionsDisplayMeta,
            },
        }
    }

    const payload: NotificationPayload = {
        source: NotificationSource.CAMPAIGN,
        meta: {
            origin_ui: 'new-notif-flow',
        },
        channels,
        audience: audiencePayload,
        deliverySpec,
        template,
    }

    return stripUndefined(payload)
}

// end region
