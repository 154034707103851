import * as React from 'react'
import { IRule } from '../../interfaces/rule'
import { DatePicker, Input, Select } from 'antd'
import { baseOperators, betterDateOperators } from '../../../rule-builder/operators'
import moment from 'moment'

type DateType = 'exact' | 'relative'
interface IBetterDateInputProps {
    rule: IRule
    onChange: (value: any) => any
}

export const BetterDateInput: React.FC<React.PropsWithChildren<React.PropsWithChildren<IBetterDateInputProps>>> = ({
    rule,
    onChange,
}: IBetterDateInputProps) => {
    let { operator, value, meta } = rule.rule

    const dateType = meta?.qualifier ? 'relative' : 'exact'
    if (!betterDateOperators.map((op) => op.value).includes(operator)) {
        operator = betterDateOperators[0].value
    }

    // classnames functions
    const defaultClassName = 'sw-v2-rb-date-input'
    const buildClassName = (className: string) => {
        return `${defaultClassName}-${className}`
    }

    const buildRootClassNames = (): string => {
        const classNames: string[] = [defaultClassName, `type-${dateType}`]

        return classNames.join(' ')
    }

    // handle changes
    const emitChanges = (changes: any) => {
        const newValue = {
            operator: changes.operator || operator,
            value: changes.value || value,
            meta: changes.meta ?? meta,
        }

        onChange(newValue)
    }

    const handleDateTypeChange = (type: DateType) => {
        emitChanges({
            value: type === 'relative' ? 1 : moment().format('YYYY-MM-DD'),
            operator: betterDateOperators[0].value,
            meta: type === 'relative' ? { qualifier: 'days' } : {},
        })
    }

    const handleOperatorChange = (opr: string) => {
        emitChanges({ operator: opr })
    }

    const handleValueChange = (val: any) => {
        if (val === undefined || String(val) === '') return
        if (typeof val === 'object') val = moment(val).format('YYYY-MM-DD')
        emitChanges({ value: val })
    }

    function render(): React.ReactElement {
        return (
            <div className={buildRootClassNames()}>
                <div className="type">
                    <span> using </span>

                    <Select
                        dropdownClassName={buildClassName('type-dropdown')}
                        placeholder="Select a type"
                        defaultValue={dateType}
                        onChange={handleDateTypeChange}
                    >
                        <Select.Option value="exact">exact dates</Select.Option>
                        <Select.Option value="relative">relative dates</Select.Option>
                    </Select>
                </div>

                <div className="operator">
                    <Select
                        key={`${dateType}-operator`}
                        dropdownClassName={buildClassName('operator-dropdown')}
                        value={operator}
                        onChange={handleOperatorChange}
                    >
                        {betterDateOperators.map((opr) => (
                            <Select.Option key={opr.value} value={opr.value}>
                                {opr.longDisplay}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className="value">
                    {dateType === 'exact' ? (
                        <DatePicker
                            // @ts-ignore
                            defaultValue={moment(value ?? undefined)}
                            onChange={handleValueChange}
                        />
                    ) : (
                        <div className="input">
                            <Input
                                type="number"
                                defaultValue={value}
                                onChange={(ev) => handleValueChange(ev.target.value)}
                            />
                        </div>
                    )}
                </div>

                {dateType !== 'exact' && <span className="metric"> days </span>}
            </div>
        )
    }

    return render()
}
