import * as React from 'react'
import {
    ClockCircleOutlined,
    LogoutOutlined,
    PlayCircleOutlined,
    ShoppingCartOutlined,
    WifiOutlined,
} from '@ant-design/icons'
import { convertSeconds } from '../../../../_utils/time'
import { RelativeDateDisplayMetric } from '@pushly/aqe/lib/enums'
import { ActionType, JourneyType, NodeType, TriggerType } from '../../enums'
import { safeEnumFromValue } from '../../../../_utils/enum'
import { JourneyStep } from '../../types/journey-nodes'
import { Journey } from '../../types/journey-api-response'

// region Node Content

export const getJourneyNodeIcon = (node: JourneyStep) => {
    let icon: React.ReactNode
    const configuration = node.configuration

    switch (node.type) {
        case NodeType.TRIGGER:
            icon = <PlayCircleOutlined />
            break
        case NodeType.DELAY:
            icon = <ClockCircleOutlined />
            break
        case NodeType.ACTION:
            const action_type = configuration.type
            if (action_type === ActionType.SEND_CART_NOTIFICATION) {
                icon = <ShoppingCartOutlined />
            } else if (action_type === ActionType.SEND_NOTIFICATION) {
                icon = <WifiOutlined />
            }
            break
        case NodeType.EXIT:
            icon = <LogoutOutlined />
            break
        default:
            icon = undefined
            break
    }

    return icon
}

export const getHeader = (node: JourneyStep) => {
    let header = 'test'
    switch (node.type) {
        case NodeType.TRIGGER:
            header = 'Campaign Entry'
            break
        case NodeType.DELAY:
            header = 'Delay'
            break
        case NodeType.ACTION:
            const action_type = node.configuration.type
            if (action_type === ActionType.SEND_CART_NOTIFICATION) {
                header = 'Send Item Notification'
            } else if (action_type === ActionType.SEND_NOTIFICATION) {
                header = 'Send Notification'
            }
            break
        case NodeType.EXIT:
            header = 'Exit Campaign'
            break
        default:
            header = 'test'
            break
    }

    return header
}

export const getSubtitle = (node: JourneyStep, journey?: Journey) => {
    if (!journey) {
        return null
    }

    let subtitle: React.ReactNode
    const nodeType = safeEnumFromValue(NodeType, node.type)

    switch (nodeType) {
        case NodeType.TRIGGER:
            const journeyType = safeEnumFromValue(JourneyType, journey.campaign_type)
            if (journeyType === JourneyType.TRIGGER) {
                const configType = safeEnumFromValue(TriggerType, node.configuration.type)
                switch (configType) {
                    case TriggerType.ABANDONED_CART:
                        subtitle = 'E-Commerce: Abandoned Cart'
                        break
                    case TriggerType.ADD_TO_CART:
                        subtitle = 'E-Commerce: Add To Cart'
                        break
                    case TriggerType.ABANDONED_BROWSE:
                        subtitle = 'E-Commerce: Abandoned Browse'
                        break
                    case TriggerType.NOTIFICATION_CLICK:
                        subtitle = 'User clicks a notification'
                        break
                    case TriggerType.SUBSCRIBED:
                        subtitle = 'User Subscribes and matches criteria'
                        break
                }
            } else {
                subtitle = 'Recurring'
            }
            break
        case NodeType.DELAY:
            const displayDelay = convertSeconds(
                node.configuration.params.delay_seconds,
                RelativeDateDisplayMetric[node.configuration.params.qualifier.toUpperCase()],
            )

            subtitle = `${displayDelay} ${node.configuration.params.qualifier}`
            break
        case NodeType.ACTION:
            subtitle = node.configuration.params.title ?? ''
            break
        default:
            subtitle = null
            break
    }

    return subtitle
}

// endregion
