import * as React from 'react'
import './frequency-with-window.scss'
import { Tooltip, Select, InputNumber as AntInputNumber, Input } from 'antd'
import { IRuleBuilderField } from '../../../rule-builder/rule-builder'
import { ChainSelect, IChainOption } from '../../../chain-select/chain-select-component'
import { getClassNames } from '../../../../_utils/classnames'
import { tryParseInt } from '../../../../_utils/try-parse'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'

type WindowScope = 'lifetime' | '1d' | '7d' | '14d' | '30d' | '60d'

const windowOptions: IChainOption[] = [
    {
        display: 'since subscribing',
        value: 'lifetime',
    },
    {
        display: 'within the last',
        value: 'prev',
        options: [
            {
                display: '1 Day',
                value: '1d',
            },
            {
                display: '7 Days',
                value: '7d',
            },
            {
                display: '14 Days',
                value: '14d',
            },
            {
                display: '30 Days',
                value: '30d',
            },
            {
                display: '60 Days',
                value: '60d',
            },
            {
                display: '90 Days',
                value: '90d',
            },
        ],
    },
]

const operatorOptions = [
    {
        display: 'is at least',
        value: 'gte',
    },
    {
        display: 'is exactly',
        value: 'eq',
    },
    {
        display: 'is at most',
        value: 'lte',
    },
]

interface IFrequencyWithWindow {
    mode: 'edit' | 'display'
    field: IRuleBuilderField
    value: any
    operator?: string
    onChange: any
    maxValue?: number
    valueLabel?: string | null
    valuePrefix?: string
    formatValueDisplay?: (value: number) => any
    tooltip?: React.ReactNode
    disableLifetime?: boolean
}

interface IState {
    field: string
    value: number
    operator: string
    meta: any
}

export class FrequencyWithWindow extends React.Component<IFrequencyWithWindow, IState> {
    private inputRef: any

    public constructor(props: IFrequencyWithWindow) {
        super(props)
    }

    public async UNSAFE_componentWillReceiveProps(prevProps: any) {
        if (this.props.mode === 'display') {
            if (
                prevProps.value.value !== this.state.value ||
                prevProps.operator !== this.state.operator ||
                prevProps.value.meta.scope !== this.state.meta.scope
            ) {
                await this.initializeStateWithProps()
            }
        }
    }

    public async UNSAFE_componentWillMount() {
        await this.initializeStateWithProps()
    }

    public componentDidMount(): void {
        this.autofocus()
    }

    public render() {
        const { value, meta } = this.state
        const { mode, operator, maxValue, valueLabel, valuePrefix, formatValueDisplay, tooltip } = this.props

        const label = valueLabel ?? 'time(s)'
        const valueInputAddOnProps: any = {}
        if (!!valuePrefix) {
            valueInputAddOnProps.addonBefore = valuePrefix
        }

        let options = Array.from(windowOptions)
        if (this.props.disableLifetime) {
            options = options.filter((opt) => opt.value !== 'lifetime')
        }

        return (
            <div className={getClassNames('freq-with-window-segment', `mode-${mode}`)}>
                {mode === 'edit' ? (
                    <>
                        <div className="operator-select-wrapper">
                            <Select
                                className="operator-select"
                                dropdownClassName="operator-select-dropdown"
                                onChange={this.onOperatorChange}
                                value={operator}
                            >
                                {this.renderOperatorOptions(operatorOptions)}
                            </Select>
                        </div>
                        <div className="freq-input-wrapper">
                            <Input
                                ref={(el) => (this.inputRef = el)}
                                type="number"
                                min={0}
                                max={maxValue}
                                value={value}
                                onChange={this.onValueChange}
                                {...valueInputAddOnProps}
                            />
                        </div>
                        {valueLabel !== null && <div className="label">{label}</div>}
                        <div className="window-chain-wrapper">
                            <ChainSelect
                                mode={mode}
                                value={meta?.scope}
                                options={options}
                                onChange={this.onScopeChange}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <span className="operator-display highlight">{this.getOperatorDisplay()}</span>
                        <span className="freq-display">
                            {' '}
                            {!!formatValueDisplay ? formatValueDisplay(value) : value}
                        </span>
                        {valueLabel !== null && <div className="label"> {label} </div>}
                        <span className="window-chain-wrapper highlight">
                            <ChainSelect
                                mode={mode}
                                value={meta?.scope}
                                options={options}
                                onChange={this.onScopeChange}
                            />
                        </span>
                    </>
                )}

                {tooltip}
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }

        if (this.state.value === undefined || this.state.value.toString().trim() === '') {
            response.ok = false
            response.error = 'Please provide a valid value.'
        }

        return response
    }

    protected renderOperatorOptions(options: any[]) {
        return options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
                {o.display}
            </Select.Option>
        ))
    }

    protected async initializeStateWithProps() {
        const propsValue = this.props.value ? this.props.value : ({} as any)

        await this.setState({
            operator: this.props.operator || 'gte',
            value: propsValue.value !== undefined ? propsValue.value : 0,
            meta: propsValue.meta || {},
        })
    }

    protected getOperatorDisplay(): string {
        if (this.state.operator) {
            return operatorOptions.filter((o) => o.value === this.state.operator)[0].display
        }
        return ''
    }

    protected autofocus(): void {
        if (!!this.inputRef) {
            this.inputRef.focus()
        }
    }

    protected onOperatorChange = async (operator: string) => {
        await this.setState({
            operator,
        })

        this.emitChangeEvent()
    }

    protected onScopeChange = async (value: WindowScope) => {
        await this.setState({
            meta: {
                scope: value,
            },
        })

        this.emitChangeEvent()
    }

    protected onValueChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = tryParseInt(ev.target.value)

        await this.setState({
            value,
        })

        this.emitChangeEvent()
    }

    protected emitChangeEvent() {
        this.props.onChange({
            field: this.props.field.property,
            operator: this.state.operator,
            value: this.state.value,
            meta: this.state.meta,
        })
    }
}
