import React from 'react'
import './style/sidebar-action-item.scss'
import classnames from 'classnames'
import { Avatar, List, Tooltip } from 'antd'
import { ListItemMetaProps } from 'antd/lib/list'
import LinkTag from '../html-element-wrappers/link-tag'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'

interface ISidebarActionItem extends ListItemMetaProps {
    icon?: React.ReactNode
    iconStyle?: 'default' | 'fill'
    onClick?: () => any
    href?: string
}

const PropsFragment = (props: any) => props.children

const SidebarActionItem = (_: ISidebarActionItem) => {
    const { className, title, description, icon, iconStyle, href, onClick, ...props } = _

    const { sidebar } = useService<AppState>(AppState)
    const LinkWrapper = !!href ? LinkTag : PropsFragment
    const TooltipWrapper = sidebar.collapsed ? Tooltip : PropsFragment

    return (
        <LinkWrapper href={href} target="_blank">
            <TooltipWrapper
                overlayClassName={classnames('sidebar-action-item-tooltip')}
                title={title}
                placement="right"
            >
                <List.Item
                    className={classnames('sidebar-action-item', className, `icon-style-${iconStyle ?? 'default'}`)}
                    onClick={onClick}
                >
                    <List.Item.Meta
                        title={title}
                        description={description}
                        avatar={!icon ? undefined : <Avatar size="small" icon={icon} gap={2} />}
                        {...props}
                    />
                </List.Item>
            </TooltipWrapper>
        </LinkWrapper>
    )
}

export default SidebarActionItem
