import '@babel/polyfill'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import 'antd/dist/antd.css'
import './styles/globals.scss'
import './_utils/utils'
import { setupAxios } from './config/axios-setup'
import { setupLocalization } from './config/locale-setup'
import { setupAqe } from './config/aqe-setup'
import { Maintenance503 } from './components/503/maintenance-503'
import { App } from './app'
import { PlatformIdentityProvider } from './providers/platform-identity-provider/platform-identity-provider'
import { UserProvider } from './providers/user-provider/user-provider'
import { BrowserRouter, BrowserRouterProps } from 'react-router-dom'
import { startVisibilityObserver } from './_utils/visibility-api'

setupAxios()
setupLocalization()
setupAqe()

startVisibilityObserver()

let AppComponent: any = App
if (process.env.MAINT_MODE === 'on') {
    AppComponent = Maintenance503
}

// @todo V18_Upgrade - might be fixed in v6? Revisit once upgraded
const CustomBrowserRouter = (props: React.PropsWithChildren<BrowserRouterProps>) => {
    return <BrowserRouter {...props} />
}

const container = document.getElementById('root') as HTMLElement
ReactDOM.createRoot(container).render(
    <CustomBrowserRouter>
        <PlatformIdentityProvider>
            <UserProvider>
                <AppComponent />
            </UserProvider>
        </PlatformIdentityProvider>
    </CustomBrowserRouter>,
)
