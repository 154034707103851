import clone from 'clone'
import * as deepEqual from 'react-fast-compare'
import { INotificationBuilderState } from './interfaces/notification-builder-state.interface'
import {
    isAllFeatTest,
    isSingleContent,
    isSingleDelivery,
    parseLoadedVariant,
    removeAllOtherVariants,
    resetVariantDistributionMap,
} from './helpers'
import {
    ActionDispatcher,
    NotificationDispatchActionPack,
    DispatchAfterEffect,
    OverrideBuilderStateActionPack,
    PatchAudienceActionPack,
    PatchAvailableSegmentsActionPack,
    PatchBuilderStateActionPack,
    PatchDeliveryChannelActionPack,
    PatchReachEstimateActionPack,
    PatchSelectedTemplateActionPack,
    PatchTemplateDetailsActionPack,
    PatchTestActionPack,
    PatchVariantActionPack,
    PatchAvailableDomainsActionPack,
} from './types'
import { NotificationDto } from '../../features/notifications'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { getInitialVariant } from './notification-builder'
import { NotificationAudienceModel } from '../../models/notification/notification-audience.model'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../services'
import { NotificationBuilderLevel } from './enums'
import { Container } from 'typescript-ioc'
import { NotificationContentModel } from '../../models/notification/notification-content.model'
import { NotificationContentBase } from '@pushly/models/lib/structs/notification-content-base'
import { NotificationWebTemplateDto } from '../../features/notifications/dtos/notification-web-template.dto'
import { NotificationNativeAndroidTemplateDto } from '../../features/notifications/dtos/notification-native-android-template.dto'

let reducerAfterEffectDebounceTimeout: any

export function getActionDispatcher(
    dispatcher: React.Dispatch<NotificationDispatchActionPack & { afterEffect: DispatchAfterEffect }>,
    afterEffect: ((builder: INotificationBuilderState) => any) | undefined,
): ActionDispatcher {
    return (action: NotificationDispatchActionPack) => {
        dispatcher({
            ...action,
            afterEffect: (state) => {
                afterEffect?.(state)
            },
        })
    }
}

export function getBuilderStateReducer(
    state: INotificationBuilderState,
    action: NotificationDispatchActionPack & { afterEffect: DispatchAfterEffect },
) {
    // ensure clone of current state is used to prevent
    // possible active-state mutations
    let currentState = clone(state)

    let update: INotificationBuilderState
    switch (action.entity) {
        case 'root':
            update = handleRootStateReducerRequest(currentState, action)
            break
        case 'audience':
            update = handleAudienceReducerRequest(currentState, action)
            break
        case 'variant':
            update = handleVariantReducerRequest(currentState, action)
            break
        case 'test':
            update = handleTestReducerRequest(currentState, action)
            break
        case 'reach-estimate':
            update = handleReachEstimateReducerRequest(currentState, action)
            break
        case 'available-domains':
            update = handleAvailableDomainsReducerRequest(currentState, action)
            break
        case 'available-segments':
            update = handleAvailableSegmentsReducerRequest(currentState, action)
            break
        case 'channel':
            update = handleDeliveryChannelsReducerRequest(currentState, action)
            break
        case 'detail':
            update = handleTemplateDetailsReducerRequest(currentState, action)
            break
        case 'selected-template':
            update = handleSelectedTemplateReducerRequest(currentState, action)
            break

        default:
            const err = new Error(`Encountered unknown dispatch action: ${action}`)
            console.error(err)
            throw err
    }

    // Schedule any possible after effects
    const { afterEffect } = action
    clearTimeout(reducerAfterEffectDebounceTimeout)
    reducerAfterEffectDebounceTimeout = setTimeout(() => {
        afterEffect(update)
    }, 10)

    return update
}

function handleRootStateReducerRequest(
    state: INotificationBuilderState,
    action: OverrideBuilderStateActionPack | PatchBuilderStateActionPack,
): INotificationBuilderState {
    switch (action.type) {
        case 'put':
            state = action.data
            break

        case 'patch':
            state = {
                ...state,
                ...action.data,
            }
            break
    }

    return state
}

function handleVariantReducerRequest(
    state: INotificationBuilderState,
    action: PatchVariantActionPack,
): INotificationBuilderState {
    const variantId = action.data.id
    const next = action.data.variant
    const curr = state.variants[variantId]
    const testTypes = state.test?.getTestTypes()

    if (isSingleContent(testTypes)) {
        state.variants.forEach((v) => v.setContent(next.getContent()))
    } else {
        curr.setContent(next.getContent())
    }

    if (isSingleDelivery(testTypes)) {
        state.variants.forEach((v) => v.setDelivery(next.getDelivery()))
    } else {
        curr.setDelivery(next.getDelivery())
    }

    if (curr.getId() !== next.getId()) {
        state.variants[variantId] = state.variants[variantId].clone(false)
    }

    return state
}

function handleAudienceReducerRequest(
    state: INotificationBuilderState,
    action: PatchAudienceActionPack,
): INotificationBuilderState {
    const domain = action.data.domain
    const next = action.data.audience

    for (const variant of state.variants) {
        variant.setAudience(next)

        const content = variant.getContent().getDefaultContent()
        const segmentIcon = next.getDefaultIconUrl()
        const isNotUsingIcon = !content.getIconUrl()
        const isUsingDomainIcon = content.getIconUrl() === domain.defaultIconUrl
        const isUsingSegmentIcon = !!content.getSegmentDefaultIconUrl()

        if (segmentIcon && (isNotUsingIcon || isUsingDomainIcon || isUsingSegmentIcon)) {
            content.setIconUrl(next.getDefaultIconUrl(), true)
        } else if (!segmentIcon && isUsingSegmentIcon) {
            content.setIconUrl(domain.defaultIconUrl)
        }
    }

    return state
}

function handleTestReducerRequest(
    state: INotificationBuilderState,
    action: PatchTestActionPack,
): INotificationBuilderState {
    const next = action.data

    const prevTestTypes = state.test?.getTestTypes() ?? []
    const nextTestTypes = next?.getTestTypes() ?? []

    const sourceVariant = state.variants[state.selectedVariantIdx]
    if (!next) {
        state = removeAllOtherVariants(state.variants, state)
    }

    state.test = next

    if (next) {
        if (
            !deepEqual(prevTestTypes, nextTestTypes) &&
            ((prevTestTypes.length > 1 && !isAllFeatTest(nextTestTypes)) ||
                isSingleContent(nextTestTypes) ||
                isSingleDelivery(nextTestTypes))
        ) {
            state.variants = [sourceVariant]
            resetVariantDistributionMap(state)
        }
    }

    if (!next || !isAllFeatTest(next.getTestTypes())) {
        state.selectedVariantIdx = 0
    }

    return state
}

function handleReachEstimateReducerRequest(
    state: INotificationBuilderState,
    action: PatchReachEstimateActionPack,
): INotificationBuilderState {
    state.reachEstimateLoaded = true
    state.reachEstimate = action.data
    return state
}

function handleAvailableDomainsReducerRequest(
    state: INotificationBuilderState,
    action: PatchAvailableDomainsActionPack,
): INotificationBuilderState {
    state.availableDomainsLoaded = true
    state.availableDomains = action.data
    return state
}

function handleAvailableSegmentsReducerRequest(
    state: INotificationBuilderState,
    action: PatchAvailableSegmentsActionPack,
): INotificationBuilderState {
    state.availableSegmentsLoaded = true
    state.availableSegments = action.data
    return state
}

function handleDeliveryChannelsReducerRequest(
    state: INotificationBuilderState,
    action: PatchDeliveryChannelActionPack,
): INotificationBuilderState {
    state.channels = action.data
    return state
}

function handleTemplateDetailsReducerRequest(
    state: INotificationBuilderState,
    action: PatchTemplateDetailsActionPack,
): INotificationBuilderState {
    state.details = action.data
    return state
}

function handleSelectedTemplateReducerRequest(
    state: INotificationBuilderState,
    action: PatchSelectedTemplateActionPack,
): INotificationBuilderState {
    state.usingTemplate = action.data.usingTemplate

    // short circuit if usingTemplate hasn't been set yet (maintain form state until a template overrides)
    if (!action.data.usingTemplate && state.selectedTemplate === undefined) {
        return state
    }

    state.selectedTemplate = action.data.id
    const template = state.availableTemplates.find((t) => action.data.id === t.id)
    let notificationVariant
    if (template) {
        const { name, description, templateContent, templateSegmentIds, templateExcludedSegmentIds, templateTtlSpec } =
            template

        notificationVariant = NotificationDto.parse({
            deliverySpec: {
                type: NotificationDeliveryType.IMMEDIATE,
                window: NotificationDeliveryWindow.STANDARD,
                ...templateTtlSpec,
            } as any,
            audience: {
                segmentIds: templateSegmentIds?.length ? Array.from(templateSegmentIds) : [],
                excludedSegmentIds: templateExcludedSegmentIds?.length
                    ? Array.from(templateExcludedSegmentIds)
                    : undefined,
            },
            template: {
                channels: {
                    default: {
                        ...templateContent.default,
                        actions:
                            templateContent.default.overrideDefaultActions === false
                                ? undefined
                                : templateContent.default.actions,
                        autoSuggestedFieldsResults: undefined,
                    } as any,
                    web: templateContent.web satisfies NotificationWebTemplateDto | undefined,
                    nativeIos: templateContent.nativeIos,
                    nativeAndroid: templateContent.nativeAndroid satisfies
                        | NotificationNativeAndroidTemplateDto
                        | undefined,
                },
                keywords: template.keywords?.length ? Array.from(template.keywords) : [],
                name,
                description,
            },
        })

        const currVariant = state.variants[state.selectedVariantIdx]
        // keep variant ID to maintain clean notification form render process - only changes are content
        notificationVariant.id = currVariant.getId()
        const nextVariant = parseLoadedVariant(notificationVariant)

        state.variants.splice(state.selectedVariantIdx, 1, nextVariant)
        state.channels = templateContent.default.channels.length ? Array.from(templateContent.default.channels) : []
        state.usingTemplate = action.data.usingTemplate
    } else if (!action.data.usingTemplate) {
        const appSvc = Container.get(AppService)
        const initialVariant = getInitialVariant(action.data.domain, undefined, state)

        // reset controlled fields via properties
        initialVariant.setContent({
            ...initialVariant.getContent().serialize(),
            defaultContent: {
                ...initialVariant.getContent().getDefaultContent(),
                _custom_actions_enabled: false,
            },
        })

        if (state.allPushSegment) {
            initialVariant.setAudience(NotificationAudienceModel.build({ segmentIds: [state.allPushSegment.id] }))
        }
        state.variants = [initialVariant]
        state.usingTemplate = action.data.usingTemplate
        // return channel state to user preference set
        const level = state.level === NotificationBuilderLevel.DOMAIN ? 'domain' : 'org'
        const entity = level === 'domain' ? state.domain : state.org
        state.channels = appSvc.getUserNotifChannelState(level, entity?.id!)
    }

    return state
}
